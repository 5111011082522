import React, {Component} from 'react';
import axios from "axios";
import * as _ from '../Utils';
import swal from 'sweetalert';

export default class Class11PupilSurveyView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            survey: {
                studyType: {
                    id: 0
                },
                institutionType: {
                    id: 0
                },
                institutionTendency: '',
                institutionAdminType: {
                    id: 0
                },
                choiceType: {
                    id: 0
                },
                workType: {
                    id: 0
                },
                goal: {
                    id: 0
                },
                customWork: '',
                customScience: '',
                infoType: {
                    id: 0
                },
                factor: {
                    id: 0
                },
                interestedSciences: []
            },
            pupil: props.pupil,
            sciences: ['Ona tili','Adabiyot','Rus tili','Chet tili','Tarix','Davlat va huquq','Matematika(Algebra)',
            'Informatika va AT','Kimyo','Fizika(Astronomiya)','Biologiya','Geografiya','Musiqa','Tasviriy san`at','ChQBT',
            'Jismoniy tarbiya','Boshqa fan']
        }
    }

    componentDidMount() {
        axios.get(_.apiURL + '/pupil/survey/item?surveyId=' + this.props.survey.id)
            .then(result => {
                if (result.data.statusCode === 200) {
                    this.setState({
                        survey: result.data.data
                    })
                }
            });
    }

    propertyChange(e) {
        let name = e.target.id;
        let value = name.includes('sc_') ? e.target.checked : e.target.value;
        if (name.includes('sc_')) {
            let sel = 0;
            let interestedSciences = this.state.survey.interestedSciences;
            if (!value) {
                let interestedSciences2 = [];
                interestedSciences.map(val => {
                    if (val !== name) {
                        interestedSciences2.push(val);
                        this.setState({
                            survey: {
                                ...this.state.survey,
                                interestedSciences: interestedSciences2
                            }
                        });
                    } else {
                        sel += 1;
                    }
                });
                interestedSciences = interestedSciences2;
            }
            if (interestedSciences.length < 4) {
                if (sel === 0) {
                    interestedSciences.push(name);
                    this.setState({
                        survey: {
                            ...this.state.survey,
                            interestedSciences: interestedSciences
                        }
                    });
                }
            } else {
                document.getElementById(name).checked = false;
            }
        } else {
            if (name === 'institutionTendency' || name === 'customWork' || name === 'customScience') {
                this.setState({
                    survey: {
                        ...this.state.survey,
                        [name]: value
                    }
                });
            } else {
                this.setState({
                    survey: {
                        ...this.state.survey,
                        [name]: {
                            id: value
                        }
                    }
                });
            }
        }
    }

    save11Survey() {
        let errors = 0;

        let studyType = this.state.survey.studyType.id;
        errors = _.validateSelect('studyType', errors);

        let institutionTendency = '';
        if (Number(studyType) === 1) {
            institutionTendency = this.state.survey.institutionTendency;
            errors = _.validateField('institutionTendency', institutionTendency, errors);
        }

        let institutionType = this.state.survey.institutionType.id;
        errors = _.validateSelect('institutionType', errors);

        let institutionAdminType = this.state.survey.institutionAdminType.id;
        errors = _.validateSelect('institutionAdminType', errors);

        let choiceType = this.state.survey.choiceType.id;
        errors = _.validateSelect('choiceType', errors);

        let workType = this.state.survey.workType.id;
        errors = _.validateSelect('workType', errors);

        let customWork = this.state.survey.customWork;
        errors = _.validateField('workType', workType, errors);

        let goal = this.state.survey.goal.id;
        errors = _.validateSelect('goal', errors);

        if (this.state.survey.interestedSciences.length === 0) {
            document.getElementById('scienceWidgets').style.boxShadow = "0px 0px 5px 5px red";
            errors++;
        }

        let customScience = this.state.survey.customScience;
        if (document.getElementById('sc_17').checked) {
            errors = _.validateField('customScience', customScience, errors);
        }

        let infoType = this.state.survey.infoType.id;
        errors = _.validateSelect('infoType', errors);

        let factor = this.state.survey.factor.id;
        errors = _.validateSelect('factor', errors);

        if (errors === 0) {
            let formData = new FormData();
            formData.append("klass", 11);
            formData.append("pupilId", this.state.pupil.id);
            formData.append("studyType", studyType);
            formData.append("institutionTendency", institutionTendency);
            formData.append("institutionType", institutionType);
            formData.append("institutionAdminType", institutionAdminType);
            formData.append("choiceType", choiceType);
            formData.append("workType", workType);
            formData.append("customWork", customWork);
            formData.append("goal", goal);
            formData.append("interestedSciences", this.state.survey.interestedSciences);
            formData.append("customScience", customScience);
            formData.append("infoType", infoType);
            formData.append("factor", factor);

            formData.append("vocationalSchoolTendency", '');
            formData.append("class10ChooseReason", 0);
            formData.append("lyceumTendency", 0);
            axios.post(_.apiURL + '/pupil/saveSurvey', formData)
                .then(result => {
                    if (result.data.statusCode === 200) {
                        this.props.reloadList();
                        swal(result.data.message, '', 'success');
                        this.props.onClose();
                    } else {
                        swal(result.data.message, '', 'error')
                    }
                })
        } else {
            swal("Hamma ma'lumotlarni kiriting", '', 'error');
        }
    }

    render() {
        let scienceWidgets = this.state.sciences.map((el, index)=>{
            index++;
            let sc_selected = false;
            if (this.state.survey !== null && this.state.survey.interestedSciences !== null) {
                for (let i = 0; i < this.state.survey.interestedSciences.length; i++) {
                    if (this.state.survey.interestedSciences[i] === "sc_" + index) {
                        sc_selected = true;
                    }
                }
            }
            return <div className="col-md-3" key={"div_sc_" + index}>
                <input type="checkbox" id={"sc_" + index} key={"sc_" + index} name={"sc_" + index}
                       checked={sc_selected}
                       onChange={this.propertyChange.bind(this)}/>
                <label className="m-lg-1" htmlFor={"sc_" + index}>{el}</label>
            </div>
        });
        return (
            <div style={{padding: "2rem"}}>
                <div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h4><b>O`quvchi: {this.state.pupil.name}</b></h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <label htmlFor="studyType"><b>Siz maktabning 11-sinfini tamomlagandan so‘ng qayerda o‘qishni davom ettirishni istaysiz?</b></label>
                            <select id="studyType" className="form-control" onChange={this.propertyChange.bind(this)}
                                    value={this.state.survey.studyType !== null ? this.state.survey.studyType.id : 0}>
                                <option value="0">Tanlang</option>
                                <option value="1">Oliy ta’lim muassasasiga o‘qishga kirmoqchiman</option>
                                <option value="2">Texnikumga o‘qishga kirmoqchiman</option>
                                <option value="3">Kollejga o‘qishga kirmoqchiman</option>
                                <option value="4">Kasbga tayyorlash markazlarida o‘qib, tadbirkorlik bilan shug‘ullanish</option>
                                <option value="5">Javob berishga qiynalaman</option>
                            </select>
                        </div>
                    </div>
                    {Number(this.state.survey.studyType.id) === 1 &&
                        <div className="row">
                            <div className="col-md-12">
                                <label htmlFor="institutionTendency">Qaysi yo‘nalishida o‘qimoqchisiz?</label>
                                <select id="institutionTendency" className="form-control" value={this.state.survey.institutionTendency}
                                onChange={this.propertyChange.bind(this)}>
                                    <option value="0">Tanlang</option>
                                    <option value="1">Ta’lim</option>
                                    <option value="2">San’at va gumanitar fanlar</option>
                                    <option value="3">Ijtimoiy fanlar, jurnalistika va axborot</option>
                                    <option value="4">Biznes, boshqaruv va huquq</option>
                                    <option value="5">Tabiiy fanlar, matematika va statistika</option>
                                    <option value="6">Axborot-kommunikatsiya texnologiyalari</option>
                                    <option value="7">Muhandislik, ishlov berish va qurilish sohalari</option>
                                    <option value="8">Qishloq, o‘rmon, baliqchilik xo‘jaligi va veterinariya</option>
                                    <option value="9">Sog‘liqni saqlash va ijtimoiy ta’minot</option>
                                    <option value="10">Xizmatlar</option>
                                </select>
                            </div>
                        </div>
                    }
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <label htmlFor="institutionType"><b>Qayerdagi OTMga kirmoqchisiz?</b></label>
                        <select id="institutionType" className="form-control" onChange={this.propertyChange.bind(this)}
                                value={this.state.survey.institutionType !== null ? this.state.survey.institutionType.id : 0}>
                            <option value="0">Tanlang</option>
                            <option value="1">Xorijdagi</option>
                            <option value="2">Xorijiy OTMlarning respublikamizdagi filiallariga</option>
                            <option value="3">Respublikadagi (Markaziy) OTMlarga</option>
                            <option value="4">Hududdagi OTMlarga</option>
                        </select>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <label htmlFor="institutionAdminType"><b>Qaysi idoraviy bo‘ysinuvi va mulkchlik shaklidagi OTMda o‘qimoqchisiz?</b></label>
                        <select id="institutionAdminType" className="form-control" onChange={this.propertyChange.bind(this)}
                                value={this.state.survey.institutionAdminType !== null ? this.state.survey.institutionAdminType.id : 0}>
                            <option value="0">Tanlang</option>
                            <option value="1">Davlat</option>
                            <option value="2">Nodavlat</option>
                        </select>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <label htmlFor="choiceType"><b>Kelgusidagi tanlagan ta’lim muassasasangiz yoki faoliyat yo‘nalishingiz bo‘yicha kim yordam berdi yoki nima ta’sir ko‘rsatdi?</b></label>
                        <select id="choiceType" className="form-control" onChange={this.propertyChange.bind(this)}
                                value={this.state.survey.choiceType !== null ? this.state.survey.choiceType.id : 0}>
                            <option value="0">Tanlang</option>
                            <option value="1">O‘zim xohladim</option>
                            <option value="2">Ota-onam va yaqinlarimning maslahati</option>
                            <option value="3">Do‘stlarimning maslahati</option>
                            <option value="4">O‘qituvchimning maslahati</option>
                            <option value="5">Boshqa ijobiy sabablar</option>
                        </select>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <label htmlFor="workType">Kelajakda qaysi sohada ishlashni istaysiz?</label>
                        <select id="workType" className="form-control" onChange={this.propertyChange.bind(this)}
                                value={this.state.survey.workType !== null ? this.state.survey.workType.id : 0}>
                            <option value="0">Tanlang</option>
                            <option value="1">Sanoat</option>
                            <option value="2">Texnika</option>
                            <option value="3">Qurilish</option>
                            <option value="4">Qishloq xo‘jaligi</option>
                            <option value="5">Ishlab chiqarish</option>
                            <option value="6">Xizmat ko‘rsatish</option>
                            <option value="7">San’at</option>
                            <option value="8">Ta’lim</option>
                            <option value="9">AKT</option>
                            <option value="10">Sog‘liqni saqlash</option>
                            <option value="11">Harbiy</option>
                            <option value="12">Moliya</option>
                            <option value="13">Boshqa</option>
                        </select>
                    </div>
                </div>
                {Number(this.state.survey.workType) === 13 &&
                <div className="row">
                    <div className="col-md-12">
                        <label htmlFor="customWork">Boshqa yo‘nalish</label>
                        <input id="customWork" className="form-control" value={this.state.survey.customWork}/>
                    </div>
                </div>
                }
                <div className="row mt-3">
                    <div className="col-md-12">
                        <label htmlFor="goal"><b>Kelajak kasbingizdan tanlashning asosiy sabablari?</b></label>
                        <select id="goal" className="form-control" onChange={this.propertyChange.bind(this)}
                                value={this.state.survey.goal !== null ? this.state.survey.goal.id : 0}>
                            <option value="0">Tanlang</option>
                            <option value="1">Ko‘proq daromad topish uchun</option>
                            <option value="2">Yaxshi lavozimda ishlash va karyera qilish</option>
                            <option value="3">Hurmatli va obro‘li bo‘lish uchun</option>
                            <option value="4">Jamiyatga foyda keltirish uchun</option>
                            <option value="5">Qiziqqan kasbni chuqurroq o‘rganish uchun</option>
                        </select>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12">
                        <span><b>Qaysi fanlar sizni qiziqtiradi? (4 tagacha tanlash mumkin)</b></span>
                    </div>
                </div>
                <div className="row" id="scienceWidgets">
                    {scienceWidgets}
                </div>
                {Number(this.state.survey.studyType.id) === 1 &&
                <div className="row">
                    <div className="col-md-12">
                        <label htmlFor="customScience"><b>Boshqa fan(Vergul bilan ajratib yozing)</b></label>
                        <input id="customScience" className="form-control" value={this.state.survey.customScience}/>
                    </div>
                </div>
                }

                <div className="row mt-3">
                    <div className="col-md-12">
                        <label htmlFor="infoType"><b>Tanlagan kasbingiz to‘g‘risida qaysi manbalardan ma’lumotga ega bo‘lasiz?</b></label>
                        <select id="infoType" className="form-control" onChange={this.propertyChange.bind(this)}
                                value={this.state.survey.infoType !== null ? this.state.survey.infoType.id : 0}>
                            <option value="0">Tanlang</option>
                            <option value="1">Maktablarda o‘tkazilgan tadbirlarda</option>
                            <option value="2">Ijtimoiy tarmoqlardan</option>
                            <option value="3">Ommaviy axborot vositalaridan</option>
                            <option value="4">Kasb egalaridan</option>
                            <option value="5">Oila a’zolarimdan</option>
                        </select>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12">
                        <label htmlFor="factor"><b>Kasb tanlashdagi muhim omillar?</b></label>
                        <select id="factor" className="form-control" onChange={this.propertyChange.bind(this)}
                                value={this.state.survey.factor !== null ? this.state.survey.factor.id : 0}>
                            <option value="0">Tanlang</option>
                            <option value="1">Oiladagi yoshi kattalarning qarashlari va maslahati</option>
                            <option value="2">Do‘st(o‘rtoq)larning qarashlari va maslahati</option>
                            <option value="3">Maktab jamoasi va o‘qituvchlarning qarashlari hamda maslahati</option>
                            <option value="4">Shaxsiy kasbiy rejalarning mavjudligi</option>
                            <option value="5">Ma’lum bir kasbga qobiliyat</option>
                            <option value="6">Jamiyatda o‘z o‘rnini topishi</option>
                            <option value="7">Kasblar to‘g‘risida axborotga ega bo‘lish</option>
                            <option value="8">Ma’lum bir kasbga moyillik</option>
                        </select>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-12 text-center">
                        <button className="btn btn-success" onClick={this.save11Survey.bind(this)}>Saqlash</button>
                    </div>
                </div>
            </div>
        )
    }
}