import React, {Component} from 'react';
import axios from 'axios';
import {apiURL, strings} from "./Utils";
import {withRouter} from "react-router-dom";
import 'react-responsive-modal/styles.css';
import Modal from "react-responsive-modal";

class WorldOfProfessionsView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			professions: [],
			professionId: '',
			modalOpen: false
		}
	}

    openProfessionPDF(professionId) {
		this.setState({
			professionId: professionId,
			modalOpen: true
		})
	}

	closeModal() {
		this.setState({
			modalOpen: false
		})
	}

	loadProfessions(profType) {
		let lang = this.props.language === 'ru' ? 2 : 1;
		axios.get(apiURL + '/profession/list?typeId=' + profType + '&language=' + lang).then(result=>{
			if (result.status === 200) {
				let data = result.data;
				let professions = data.map((p, index)=>{
                    return <div key={'wop' + index} className="col-md-3">
							<span onClick={this.openProfessionPDF.bind(this, p.id)} className="box link-style">
							<img src={"images/professions/" + p.image} alt=""/>
							<h4>{p.name}</h4>
						</span>
					</div>
				});
				this.setState({
					professions: professions
				})
			}
		})
	}

	getWidth() {
		return (window.innerWidth - 250) + 'px';
	}

	downloadPdf(professionId) {
		window.open(apiURL + '/files/professions/p' + professionId + '.pdf', "_blank");
	}

	render() {
		let swidth = this.getWidth();
		return(
			<section className="services-sec">
				<div className="container">
					<div className="sec-title text-center mb-0">
						{this.props.language === 'uz' &&
						<div>
							<h2>
								{strings.profession}
							</h2>
							<p>{strings.uznay}</p>
						</div>
						}
						{this.props.language === 'ru' &&
						<div>
							<p>{strings.uznay}</p>
							<h2>
								<span>{strings.profession}</span>
							</h2>

						</div>
						}
						<span className="decor"><span className="inner"/></span>
					</div>

					<div className="text-center">
						<ul className="nav nav-pills mb-3 row text-center d-flex justify-content-center" id="pills-tab"
						    role="tablist">
							<li className="nav-item box col-md-3 no-padding mb-3" role="presentation" onClick={this.loadProfessions.bind(this, 1)}>
								<div style={{backgroundImage: "url(images/type1.jpg)", backgroundSize: "cover", height: "120px"}} onClick={this.loadProfessions.bind(this, 1)} />
								<a className="nav-link text-uppercase" id="pills-home-tab" data-toggle="pill"
								   href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">
									<b>{strings.profType1}</b>
								</a>
							</li>
							<li className="nav-item box col-md-3 no-padding mb-3" role="presentation" onClick={this.loadProfessions.bind(this, 2)}>
								<div style={{backgroundImage: "url(images/type2.jpg)", backgroundSize: "cover", height: "120px"}} onClick={this.loadProfessions.bind(this, 2)} />
								<a className="nav-link text-uppercase" id="pills-profile-tab" data-toggle="pill"
								   href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">
									<b>{strings.profType2}</b>
								</a>
							</li>
							<li className="nav-item box col-md-3 no-padding mb-3" role="presentation" onClick={this.loadProfessions.bind(this, 3)}>
								<div style={{backgroundImage: "url(images/type3.jpg)", backgroundSize: "cover", height: "120px"}} onClick={this.loadProfessions.bind(this, 3)}/>
								<a className="nav-link text-uppercase" id="pills-contact-tab" data-toggle="pill"
								   href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">
									<b>{strings.profType3}</b>
								</a>
							</li>
							<li className="nav-item box col-md-3 no-padding mb-3" role="presentation" onClick={this.loadProfessions.bind(this, 4)}>
								<div style={{backgroundImage: "url(images/type4.jpg)", backgroundSize: "cover", height: "120px"}} onClick={this.loadProfessions.bind(this, 4)} />
								<a className="nav-link text-uppercase" id="pills-four-tab" data-toggle="pill"
								   href="#pills-four" role="tab" aria-controls="pills-four" aria-selected="false">
									<b>{strings.profType4}</b>
								</a>
							</li>
							<li className="nav-item box col-md-3 no-padding mb-3" role="presentation" onClick={this.loadProfessions.bind(this, 5)}>
								<div style={{backgroundImage: "url(images/type5.jpg)", backgroundSize: "cover", height: "120px"}} onClick={this.loadProfessions.bind(this, 5)} />
								<a className="nav-link text-uppercase" id="pills-five-tab" data-toggle="pill"
								   href="#pills-five" role="tab" aria-controls="pills-five" aria-selected="false">
									<b>{strings.profType5}</b>
								</a>
							</li>
							<li className="nav-item box col-md-3 no-padding mb-3" role="presentation" onClick={this.loadProfessions.bind(this, 6)}>
								<div style={{backgroundImage: "url(images/type6.jpg)", backgroundSize: "cover", height: "120px"}} onClick={this.loadProfessions.bind(this, 6)} />
								<a className="nav-link text-uppercase" id="pills-six-tab" data-toggle="pill"
								   href="#pills-six" role="tab" aria-controls="pills-six" aria-selected="false">
									<b>{strings.profType6}</b>
								</a>
							</li>
						</ul>
						<div className="tab-content" id="pills-tabContent">
							<div className="fade show" key="pills-home" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
								<div className="row text-center">
									{this.state.professions}
								</div>
							</div>
							{/*<div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
								<div className="row text-center justify-content-center">
									{this.state.professions}
								</div>
							</div>
							<div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
								<div className="row text-center justify-content-center">
									{this.state.professions}
								</div>
							</div>
							<div className="tab-pane fade" id="pills-four" role="tabpanel" aria-labelledby="pills-four-tab">
								<div className="row text-center justify-content-center">
									{this.state.professions}
								</div>
							</div>
							<div className="tab-pane fade" id="pills-five" role="tabpanel" aria-labelledby="pills-five-tab">
								<div className="row text-center justify-content-center">
									{this.state.professions}
								</div>
							</div>
							<div className="tab-pane fade" id="pills-six" role="tabpanel" aria-labelledby="pills-six-tab">
								<div className="row text-center justify-content-center">
									{this.state.professions}
								</div>
							</div>*/}
						</div>
					</div>
				</div>
				<Modal open={this.state.modalOpen} onClose={this.closeModal.bind(this)} center classNames={{
					overlay: 'customOverlay',
					modal: 'customModal',
				}}>
					<div className="form-row">
						<div className="col-md-12 pt-4">
							<img src={apiURL + '/images/professions/p' + this.state.professionId + '.jpg'} width="100%" alt={""}/>
							<button className="btn btn-block download-btn mt-4" onClick={this.downloadPdf.bind(this, this.state.professionId)}>
								<i className="fa fa-download"/>
							</button>
						</div>
					</div>
				</Modal>
			</section>
		)
	}
}

export default withRouter(WorldOfProfessionsView);