import React, {Component} from 'react';
import {Tab, Tabs} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import JoditEditor from "jodit-react";
import {apiURL} from "./Utils";
import axios from 'axios';
import swal from 'sweetalert';
import PsychHelpListView from "./PsychHelpListView";

const config = {
	readonly: false // all options from https://xdsoft.net/jodit/doc/
};

class SectionHomeView extends Component {

	constructor() {
		super();
		this.state = {
			tabListKey: 'profs',
			name: '',
			presentation: '',
			category: 0,
			language: 0,
			professionType: '',
			content: '',
			terms: '',
			requirements: '',
			individualRequirements: '',
			medicalBarrier: '',
			waysToGetJob: '',
			applicationArea: '',
			relatedProfessions: '',
			growthProspects: '',
			imageUrl: '',
			mname: '',
			maslahat: '',
			language2: 1
		};
		this.editor = React.createRef();
		this.presentationEditor = React.createRef();
		this.professionTypeEditor = React.createRef();
		this.contentEditor = React.createRef();
		this.termsEditor = React.createRef();
		this.requirementsEditor = React.createRef();
		this.individualRequirementsEditor = React.createRef();
		this.medicalBarrierEditor = React.createRef();
		this.waysToGetJobEditor = React.createRef();
		this.applicationAreaEditor = React.createRef();
		this.relatedProfessionsEditor = React.createRef();
		this.growthProspectsEditor = React.createRef();
	}

	setContent(name) {
		let content = document.getElementById(name).value;
		this.setState({
			[name]: content
		})
	}

	save() {
		let files = document.getElementById('image').files;
		let data = new FormData();
		data.append('name', this.state.name);
		data.append('presentation', this.state.presentation);
		data.append('category', this.state.category);
		data.append('language', this.state.language);
		data.append('professionType', this.state.professionType);
		data.append('content', this.state.content);
		data.append('terms', this.state.terms);
		data.append('requirements', this.state.requirements);
		data.append('individualRequirements', this.state.individualRequirements);
		data.append('medicalBarrier', this.state.medicalBarrier);
		data.append('waysToGetJob', this.state.waysToGetJob);
		data.append('applicationArea', this.state.applicationArea);
		data.append('relatedProfessions', this.state.relatedProfessions);
		data.append('growthProspects', this.state.growthProspects);
		data.append('image', files[0]);
		axios.post(apiURL + '/profession/add', data,{
			headers: {'Content-type': 'multipart/form-data'},
		})
			.then(result=>{
				if (result.data.statusCode === 200) {
					swal('Kasb ma`lumotini saqlandi', '', 'success');
				} else {
					swal('Kasb ma`lumotini saqlashda xatolik', '', 'error');
				}
			})
	}

	render() {
		return(
			<div className="container card card-body my-4">
				<Tabs id="controlled-tab-example" activeKey={this.state.tabListKey} onSelect={key => this.setState({tabListKey: key})}>
					<Tab eventKey="profs" title="Kasblar">
						<div className="form-row mt-5">
							<div className="col-md-12 mb-3 text-center">
								<h3>Kasb ma'lumotlarini kiritish</h3>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-6">
								<label htmlFor="name">Kasb nomi</label>
								<input id="name" className="form-control" onChange={this.setContent.bind(this, 'name')}/>
							</div>

							<div className="col-md-3">
								<label htmlFor="category">Kasb turi</label>
								<select id="category" className="form-control" onChange={this.setContent.bind(this, 'category')}>
									<option value="1">Odam - Odam</option>
									<option value="2">Odam - Tabiat</option>
									<option value="3">Odam - Texnika</option>
									<option value="4">Odam - Belgilar tizimi</option>
									<option value="5">Odam - Badiiy obraz</option>
									<option value="6">Zamonaviy kasblar</option>
								</select>
							</div>

							<div className="col-md-3">
								<label htmlFor="language">Kasb turi</label>
								<select id="language" className="form-control" onChange={this.setContent.bind(this, 'language')}>
									<option value="1">O`zbek</option>
									<option value="2">Rus</option>
								</select>
							</div>
						</div>
						<div className="form-row mt-4">
							<label htmlFor="presentation">Kasbning taqdimoti</label>
							<div className="col-md-12">
								<JoditEditor id="presentation"
									ref={this.presentationEditor}
									value={this.state.presentation}
									config={config}
									tabIndex={1}
							        onChange={this.setContent.bind(this, 'presentation')}/>
							</div>
						</div>
						<div className="form-row mt-4">
							<label htmlFor="professionType">Kasb turi</label>
							<div className="col-md-12">
								<JoditEditor id="professionType"
									ref={this.professionTypeEditor}
									value={this.state.professionType}
									config={config}
									tabIndex={2}
							         onChange={this.setContent.bind(this, 'professionType')}/>
							</div>
						</div>
						<div className="form-row mt-4">
							<label htmlFor="content">Ish faoliyatining mazmuni</label>
							<div className="col-md-12">
								<JoditEditor id="content"
									ref={this.contentEditor}
									value={this.state.content}
									config={config}
									tabIndex={3}
							         onChange={this.setContent.bind(this, 'content')}/>
							</div>
						</div>
						<div className="form-row mt-4">
							<label htmlFor="terms">Ish sharti</label>
							<div className="col-md-12">
								<JoditEditor id="terms"
									ref={this.termsEditor}
									value={this.state.terms}
									config={config}
									tabIndex={4}
							         onChange={this.setContent.bind(this, 'terms')}/>
							</div>
						</div>
						<div className="form-row mt-4">
							<label htmlFor="requirements">Mutaxassisning bilim va ko`nikmalariga qo`yiladigan talablar</label>
							<div className="col-md-12">
								<JoditEditor id="requirements"
									ref={this.requirementsEditor}
									value={this.state.requirements}
									config={config}
									tabIndex={5}
							         onChange={this.setContent.bind(this, 'requirements')}/>
							</div>
						</div>
						<div className="form-row mt-4">
							<label htmlFor="individualRequirements">Mutaxassisning individual xususiyatlariga qo`yiladigan talablar</label>
							<div className="col-md-12">
								<JoditEditor id="individualRequirements"
									ref={this.individualRequirementsEditor}
									value={this.state.individualRequirements}
									config={config}
									tabIndex={6}
							         onChange={this.setContent.bind(this, 'individualRequirements')}/>
							</div>
						</div>
						<div className="form-row mt-4">
							<label htmlFor="medicalBarrier">Tibbiy moneliklar</label>
							<div className="col-md-12">
								<JoditEditor id="medicalBarrier"
									ref={this.medicalBarrierEditor}
									value={this.state.medicalBarrier}
									config={config}
									tabIndex={7}
							         onChange={this.setContent.bind(this, 'medicalBarrier')}/>
							</div>
						</div>
						<div className="form-row mt-4">
							<label htmlFor="waysToGetJob">Kasb egallash yo`llari</label>
							<div className="col-md-12">
								<JoditEditor id="waysToGetJob"
									ref={this.waysToGetJobEditor}
									value={this.state.waysToGetJob}
									config={config}
									tabIndex={8}
							         onChange={this.setContent.bind(this, 'waysToGetJob')}/>
							</div>
						</div>
						<div className="form-row mt-4">
							<label htmlFor="applicationArea">Kasbning qo`llanilish sohasi</label>
							<div className="col-md-12">
								<JoditEditor id="applicationArea"
									ref={this.applicationAreaEditor}
									value={this.state.applicationArea}
									config={config}
									tabIndex={9}
							         onChange={this.setContent.bind(this, 'applicationArea')}/>
							</div>
						</div>
						<div className="form-row mt-4">
							<label htmlFor="relatedProfessions">Turdosh kasblar</label>
							<div className="col-md-12">
								<JoditEditor id="relatedProfessions"
									ref={this.relatedProfessionsEditor}
									value={this.state.relatedProfessions}
									config={config}
									tabIndex={10}
							         onChange={this.setContent.bind(this, 'relatedProfessions')}/>
							</div>
						</div>
						<div className="form-row mt-4">
							<label htmlFor="growthProspects">Kasbiy o`sish istiqbollari></label>
							<div className="col-md-12">
								<JoditEditor id="growthProspects"
									ref={this.growthProspectsEditor}
									value={this.state.growthProspects}
									config={config}
									tabIndex={11}
							         onChange={this.setContent.bind(this, 'growthProspects')}/>
							</div>
						</div>
						<div className="form-row mt-4">
							<label htmlFor="image">Rasmi</label>
							<div className="col-md-12">
								<input type="file" id="image"/>
							</div>
						</div>
						<div className="form-row mt-4">
							<div className="col-md-12 text-center">
								<button className="btn btn-info" onClick={this.save.bind(this)}>Saqlash</button>
							</div>
						</div>
					</Tab>
					<Tab eventKey="advice" title="Psixologik maslahat">
						<PsychHelpListView/>
					</Tab>
				</Tabs>
			</div>
		)
	}
}

export default withRouter(SectionHomeView);