import React, {Component} from 'react';
import {NavLink, withRouter} from "react-router-dom";
import {apiURL, strings} from "./Utils";
import axios from 'axios';

class EffectiveView extends Component {

    constructor() {
        super();
        this.state = {
            tableRows: []
        }
    }

	componentDidMount() {
        axios.get(apiURL + '/videos/effectivePeople/list').then(result=>{
            if(result.data.statusCode === 200) {
                let data = result.data.data.map(row=>{
                    return <div className="col-12 col-sm-8 col-md-6 col-lg-4">
						<div className="card card-margin">
							<img className="card-img-top" src={"images/ep/ep" + row.id + ".jpg"} alt={row.name}/>
							<div className="card-body text-center">
								<h4 className="card-title">{row.name}</h4>
                                {this.props.language === 'uz' &&
                                <p><b>{row.descriptionUz}</b></p>
                                }
                                {this.props.language === 'ru' &&
                                <p><b>{row.descriptionRu}</b></p>
                                }
								<NavLink to={"/media?src=ep" + row.id} className="btn">{strings.playVideo}</NavLink>
							</div>
						</div>
					</div>
                });
                this.setState({
                    tableRows: data
                })
            }
        })
	}

	openMedia(src) {
		this.props.history.push('/media?src=' + src);
	}

	render() {
		return(
			<section className="about-area inner-content-wrapper">
				<div className="container">
					<div className="col-lg-7 col-md-12">
						<div className="full-sec-content">
							<div className="sec-title style-two">
								<div className="ml-edu">
									<h4>{strings.effectiveMens}</h4>
								</div>
								<span className="decor"><span className="inner"/></span>
							</div>
						</div>
					</div>

                    <div className="container">
                        <div className="row">
                            {this.state.tableRows}
                        </div>
                    </div>

					{/*<div className="container">
						<div className="row">
							{this.props.language === 'uz' &&
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/jekma.jpg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Jek Ma</h4>
										<p><b>Tadbirkor: "Alibaba Group" asoschisi</b></p>
										<NavLink to="/media?src=jekmauz" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							}
							{this.props.language === 'ru' &&
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/jekma.jpg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Jek Ma</h4>
										<p><b>Предприниматель: Основатель "Alibaba Group"</b></p>
										<NavLink to="/media?src=jekma" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							}
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/murodnazarov.jpg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Murod Nazarov</h4>
										{this.props.language === 'uz' &&
										<p><b>Tadbirkor: "Murad Building" asoschisi</b></p>
										}
										{this.props.language === 'ru' &&
										<p><b>Предприниматель: Основатель "Murad Building"</b></p>
										}
										<NavLink to="/media?src=murodnazarov" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/jekichan.jpg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Jeki Chan</h4>
										{this.props.language === 'uz' &&
										<p><b>Aktyor</b></p>
										}
										{this.props.language === 'ru' &&
										<p><b>Актёр</b></p>
										}
										<NavLink to="/media?src=jekichan" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/ronaldo.jpg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Krishtiano Ronaldo</h4>
										{this.props.language === 'uz' &&
										<p><b>Sportchi: Futbolchi</b></p>
										}
										{this.props.language === 'ru' &&
										<p><b>Спортсмен: футболист</b></p>
										}
										<NavLink to="/media?src=ronaldo" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/zafarhoshimov.jpg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Zafar Hoshimov</h4>
										{this.props.language === 'uz' &&
										<p><b>Tadbirkor: "Korzinka.uz" asoschisi</b></p>
										}
										{this.props.language === 'ru' &&
										<p><b>Предприниматель: Основатель "Korzinka.uz"</b></p>
										}
										<NavLink to="/media?src=zafarhoshimov" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							{this.props.language === 'uz' &&
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/stivjobs.jpg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Stiv Jobs</h4>
										<p><b>Tadbirkor: "Apple" asoschisi</b></p>
										<NavLink to="/media?src=stivjobsuz" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							}
							{this.props.language === 'ru' &&
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/stivjobs.jpg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Stiv Jobs</h4>
										<p><b>Предприниматель: Основатель "Apple"</b></p>
										<NavLink to="/media?src=stivjobs" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							}
							{this.props.language === 'ru' &&
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/brayan.jpg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Брайан Трейси</h4>
										<p><b>Эксперт по психологии успеха, писатель</b></p>
										<NavLink to="/media?src=brayan" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							}
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/dassler.jpg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Adolf Dassler</h4>
										{this.props.language === 'uz' &&
										<p><b>Tadbirkor: "Adidas" asoschisi</b></p>
										}
										{this.props.language === 'ru' &&
										<p><b>Предприниматель: Основатель "Adidas"</b></p>
										}
										<NavLink to="/media?src=dassler" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							{this.props.language === 'uz' &&
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/arnold.jpg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Arnold Shvartsnegger</h4>
										<p><b>Aktyor</b></p>
										<NavLink to="/media?src=arnolduz" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							}
							{this.props.language === 'ru' &&
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/arnold.jpg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Arnold Shvartsnegger</h4>
										<p><b>Актёр</b></p>
										<NavLink to="/media?src=arnold" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							}
							{this.props.language === 'ru' &&
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/margulan.jpeg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Margulan Seysembayev</h4>
										<p><b>Бизнесмен</b></p>
										<NavLink to="/media?src=margulan" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							}
							{this.props.language === 'ru' &&
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/denpenya.jpg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Дэн Пенья</h4>
										<p><b>Бизнесмен</b></p>
										<NavLink to="/media?src=denpenya" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							}
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/kiyosaki.jpg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Robert Kiyosaki</h4>
										{this.props.language === 'uz' &&
										<p><b>Tadbirkor</b></p>
										}
										{this.props.language === 'ru' &&
										<p><b>Предприниматель</b></p>
										}
										<NavLink to="/media?src=kiyosaki" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/muratazamov.jpg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Murat Azamov</h4>
										{this.props.language === 'uz' &&
										<p><b>Tadbirkor</b></p>
										}
										{this.props.language === 'ru' &&
										<p><b>Предприниматель</b></p>
										}
										<NavLink to="/media?src=muratazamov" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/aabdurahimov.png" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Abdusattor Abdurahimov</h4>
										{this.props.language === 'uz' &&
										<p><b>Tadbirkor</b></p>
										}
										{this.props.language === 'ru' &&
										<p><b>Предприниматель</b></p>
										}
										<NavLink to="/media?src=aabdurahimov" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							{this.props.language === 'uz' &&
							<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
								<div className="card">
									<img className="card-img-top" src="images/gulmira.jpg" alt=""/>
									<div className="card-body">
										<h4 className="card-title">Gulmira Qodirovar</h4>
										<p><b>Tadbirkor</b></p>
										<NavLink to="/media?src=gulmira" className="btn">{strings.playVideo}</NavLink>
									</div>
								</div>
							</div>
							}
						</div>
					</div>*/}
				</div>
			</section>
		)
	}
}

export default withRouter(EffectiveView);