import React, {Component} from 'react';
import {NavLink, Redirect, withRouter} from "react-router-dom";
import {apiURL, getRegions, strings} from "./Utils";
import axios from 'axios';

class QuizStartView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			openTestPage: false,
			districts: [],
			schools: []
		}
	}
	savePupil() {
		const schools = document.getElementById('schools');
		const klassName = document.getElementById('klassName');
		const lastName = document.getElementById('lastName');
		const firstName = document.getElementById('firstName');

		axios.post(apiURL + "/pupil/add2?schoolId=" + schools.value + "&klass=" + klassName.value + "&lastName=" + lastName.value + "&firstName=" + firstName.value)
			.then(result=>{
				if (result.status === 200) {
					window.localStorage.setItem('pupilId', result.data.id);
					window.localStorage.setItem('klass', klassName.value);
					this.setState({
						openTestPage: true
					})
				}
			})
	}

	renderRedirect = () => {
		if (this.state.openTestPage) {
			return <Redirect to='/testing' />
		}
	};

	loadDistricts() {
		let regions = document.getElementById('regions');
		axios.get(apiURL + "/pupil/districts?regionId=" + regions.value + '&language=' + (this.props.language === 'uz' ? 1 : 2))
			.then(result=>{
				if (result.data.statusCode === 200) {
					let districts = result.data.data.map(data=> {
						return <option value={data.id}>{data.name}</option>;
					});
					districts.unshift(<option value="">{strings.pleaseSelect}</option>);
					this.setState({
						districts: districts
					})
				}
			});
	}

	loadSchools() {
		let districts = document.getElementById('districts');
		axios.get(apiURL + "/pupil/schools?districtId=" + districts.value + '&language=' + (this.props.language === 'uz' ? 1 : 2))
			.then(result=>{
				if (result.data.statusCode === 200) {
					let schools = result.data.data.map(data=> {
						return <option value={data.id}>{data.name}</option>;
					});
					schools.unshift(<option value="">{strings.pleaseSelect}</option>);
					this.setState({
						schools: schools
					})
				}
			});
	}

	render() {
		let klass = localStorage.getItem('klass');
		let regions = getRegions(this.props.language);
		return(
			<div>
				{this.renderRedirect()}
				<section className="about-area inner-content-wrapper p-4">
					<div className="container">
						<div className="row">
							<div className="col-md-2">
								<div className="full-sec-content">
									<div className="card card-profile mt--300 shadow ml-box">
										<div className="px-4">
											<div className="mt-2 py-3 border-top text-center">
												<div className="row justify-content-center">
													<div className="col-lg-12">
														<div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">

															<NavLink className="nav-link text-uppercase" to={"/quiz"}>
																<i className="fa fa-school fa-2x"/>
																<br/>
																{strings.klass5}
															</NavLink>
															<NavLink className="nav-link text-uppercase" to={"/quiz"}>
																<i className="fa fa-school fa-2x"/>
																<br/>
																{strings.klass6}
															</NavLink>
															<NavLink className="nav-link text-uppercase" to={"/quiz"}>
																<i className="fa fa-school fa-2x"/>
																<br/>
																{strings.klass7}
															</NavLink>
															<hr/>
															<NavLink className="nav-link text-uppercase" to={"/quiz"}>
																<i className="fa fa-school fa-2x"/>
																<br/>
																{strings.klass8}</NavLink>
															<hr/>
															<NavLink className="nav-link text-uppercase" to={"/quiz"}>
																<i className="fa fa-school fa-2x"/>
																<br/>
																{strings.klass9}
															</NavLink>
															<hr/>
															<NavLink className="nav-link text-uppercase" to={"/quiz"}>
																<i className="fa fa-school fa-2x"/>
																<br/>
																{strings.klass10}
															</NavLink>
															<hr/>
															<NavLink className="nav-link text-uppercase" to={"/quiz"}>
																<i className="fa fa-school fa-2x"/>
																<br/>
																{strings.klass11}
															</NavLink>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-10">
								<div className="full-sec-content">
									<div className="card card-profile mt--300 shadow ml-box">
										<div className="px-4">
											<div className="mt-2 py-3 border-top text-center">
												<div className="row justify-content-center">
													<div className="col-lg-12">
														<div className="quiz-container tx">
															<div className="title">{strings.registration}</div>
															<div className="row mb-1">
																<div className="col s5">
																	<label htmlFor="regions">{strings.region}</label>
																</div>
																<div className="col s7">
																	<select id="regions" className="form-control-lg" onChange={this.loadDistricts.bind(this)}>
																		{regions}
																	</select>
																</div>
															</div>
															<div className="row mb-1">
																<div className="col s5">
																	<label htmlFor="districts">{strings.districtName}</label>
																</div>
																<div className="col s7">
																	<select id="districts" className="form-control-lg" onChange={this.loadSchools.bind(this)}>
																		{this.state.districts}
																	</select>
																</div>
															</div>
															<div className="row mb-1">
																<div className="col s5">
																	<label htmlFor="schools">{strings.schoolName}</label>
																</div>
																<div className="col s7">
																	<select id="schools" className="form-control-lg">
																		{this.state.schools}
																	</select>
																</div>
															</div>
															<div className="row mb-1">
																<div className="col s5">
																	<label htmlFor="klassName">{strings.klass}</label>
																</div>
																<div className="col s7">
																	<select id="klassName" className="form-control-lg" defaultValue={klass}>
																		<option value="5">{strings.klass5}</option>
																		<option value="6">{strings.klass6}</option>
																		<option value="7">{strings.klass7}</option>
																		<option value="8">{strings.klass8}</option>
																		<option value="9">{strings.klass9}</option>
																		<option value="10">{strings.klass10}</option>
																		<option value="11">{strings.klass11}</option>
																	</select>
																</div>
															</div>
															<div className="row mb-1">
																<div className="col s5">
																	<label htmlFor="lastName">{strings.lastName}</label>
																</div>
																<div className="col s7">
																	<input id="lastName" className="form-control-lg"/>
																</div>
															</div>
															<div className="row mb-1">
																<div className="col s5">
																	<label htmlFor="firstName">{strings.firstName}</label>
																</div>
																<div className="col s7">
																	<input id="firstName" className="form-control-lg"/>
																</div>
															</div>

															<div className="controls text-center">
																<button id="savePupilBtn" className="btn btn-info" onClick={this.savePupil.bind(this)}>
																	{strings.save}
																</button>
															</div>
														</div>

														<div className="result"/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}

export default withRouter(QuizStartView);