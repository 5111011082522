import React, {Component} from "react";
import {Tab, Tabs} from "react-bootstrap";
import Survey9SummaryReportView from "./Survey9SummaryReportView";
import Survey11SummaryReportView from "./Survey11SummaryReportView";

export default class AdminSurveyReportView extends Component {

    constructor() {
        super();
        this.state = {
            tabListKey: 'surveyReport9'
        }
    }

    render() {
        return <div className="container-fluid pt-2">
            <Tabs activeKey={this.state.tabListKey} animation="true" onSelect={key => this.setState({tabListKey: key})}>
                <Tab eventKey="surveyReport9" title="9-sinf" mountOnEnter={true} unmountOnExit={true}>
                    <Survey9SummaryReportView/>
                </Tab>
                <Tab eventKey="surveyReport11" title="11-sinf" mountOnEnter={true} unmountOnExit={true}>
                    <Survey11SummaryReportView/>
                </Tab>
            </Tabs>
        </div>
    }
}