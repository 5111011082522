import React, {Component} from "react";
import axios from "axios";
import {apiURL} from "../Utils";

export default class Survey11SummaryReportView extends Component {

    constructor() {
        super();
        this.state = {
            tableRows: []
        }
    }

    componentDidMount() {
        axios.get(apiURL + '/report/surveyReport?klass=11').then(result=>{
            if (result.data.statusCode === 200) {
                console.log(result.data.data);
                let data = result.data.data.map(row=>{
                    return <tr>
                        <td>{row.region.id}</td>
                        <td>{row.region.name}</td>
                        <td>{row.pupilCount}</td>
                        <td>{row.guys}</td>
                        <td>{row.girls}</td>

                        <td>{row.otm}</td>
                        <td>{row.texnikum}</td>
                        <td>{row.kollej}</td>
                        <td>{row.kasbTM}</td>
                        <td>{row.javobBQ}</td>

                        <td>{row.otm_talim}</td>
                        <td>{row.otm_sanat}</td>
                        <td>{row.otm_ijtFan}</td>
                        <td>{row.otm_biznes}</td>
                        <td>{row.otm_tabiiyFan}</td>
                        <td>{row.otm_akt}</td>
                        <td>{row.otm_muhandis}</td>
                        <td>{row.otm_qishXuj}</td>
                        <td>{row.otm_sogSaqlash}</td>
                        <td>{row.otm_xizmat}</td>

                        <td>{row.otm_t_xorijiy}</td>
                        <td>{row.otm_t_xorijiyFil}</td>
                        <td>{row.otm_t_resp}</td>
                        <td>{row.otm_t_hududiy}</td>

                        <td>{row.davlat}</td>
                        <td>{row.nodavlat}</td>

                        <td>{row.uzXohishim}</td>
                        <td>{row.otaOna}</td>
                        <td>{row.dust}</td>
                        <td>{row.uqituvchi}</td>
                        <td>{row.boshqaSabab}</td>

                        <td>{row.sanoat}</td>
                        <td>{row.texnika}</td>
                        <td>{row.qurilish}</td>
                        <td>{row.qishXuj}</td>
                        <td>{row.ishlabChiqarish}</td>
                        <td>{row.xizmatKurs}</td>
                        <td>{row.sanatSoha}</td>
                        <td>{row.talim}</td>
                        <td>{row.akt}</td>
                        <td>{row.sogSaqlash}</td>
                        <td>{row.harbiy}</td>
                        <td>{row.moliya}</td>
                        <td>{row.boshqaSoha}</td>

                        <td>{row.daromad}</td>
                        <td>{row.lavozim}</td>
                        <td>{row.hurmatli}</td>
                        <td>{row.jamFoyda}</td>
                        <td>{row.kasbChuqUrg}</td>

                        <td>{row.onaTili}</td>
                        <td>{row.adabiyot}</td>
                        <td>{row.rusTili}</td>
                        <td>{row.chetTili}</td>
                        <td>{row.tarix}</td>
                        <td>{row.davlatHuquq}</td>
                        <td>{row.matematika}</td>
                        <td>{row.informatika}</td>
                        <td>{row.kimyo}</td>
                        <td>{row.fizika}</td>
                        <td>{row.biologiya}</td>
                        <td>{row.geografiya}</td>
                        <td>{row.musiqa}</td>
                        <td>{row.tasviriySanat}</td>
                        <td>{row.chQBT}</td>
                        <td>{row.jTarbiya}</td>
                        <td>{row.boshqaFan}</td>

                        <td>{row.maktabTadbir}</td>
                        <td>{row.ijtimoiyTarmoq}</td>
                        <td>{row.oav}</td>
                        <td>{row.kasbEga}</td>
                        <td>{row.oilaAzo}</td>

                        <td>{row.yoshiKatta}</td>
                        <td>{row.dustQarash}</td>
                        <td>{row.maktabJamoa}</td>
                        <td>{row.kasbiyReja}</td>
                        <td>{row.kasbiyQobiliyat}</td>
                        <td>{row.jamiyatUrni}</td>
                        <td>{row.kasbAxborot}</td>
                        <td>{row.kasbMoyillik}</td>
                    </tr>
                });
                this.setState({
                    tableRows: data
                })
            }
        })
    }

    render() {
        return <div className="container-fluid">
            <div className="content">
                <div className="row">
                    <div className="col-12 text-center p-3">
                        <h4>So`rovnoma hisoboti</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th rowSpan={2}>№</th>
                                    <th rowSpan={2}>Tuman (shahar) nomi</th>
                                    <th rowSpan={2}>Jami o‘quvchilar soni</th>
                                    <th colSpan={2}>Shundan</th>
                                    <th colSpan={5}>Siz maktabning 11-sinfini tamomlagandan so‘ng qayerda o‘qishni davom ettirishni istaysiz?</th>
                                    <th colSpan={10}>Agar Siz OTMni tanlagan bo‘lsangiz qaysi yo‘nalishida o‘qimoqchisiz?</th>
                                    <th colSpan={4}>Qayerdagi OTMga kirmoqchisiz?</th>
                                    <th colSpan={2}>Qaysi idoraviy bo‘ysinuvi va mulkchlik shaklidagi OTMda o‘qimoqchisiz?</th>
                                    <th colSpan={5}>Kelgusidagi tanlagan ta’lim muassasasangiz yoki faoliyat yo‘nalishingiz bo‘yicha kim yordam berdi yoki nima ta’sir ko‘rsatdi?</th>
                                    <th colSpan={13}>Kelajakda qaysi sohada ishlashni istaysiz?</th>
                                    <th colSpan={5}>Kelajak kasbingizdan tanlashning asosiy sabablari?</th>
                                    <th colSpan={17}>Qaysi fanlar sizni qiziqtiradi</th>
                                    <th colSpan={5}>Tanlagan kasbingiz to‘g‘risida qaysi manbalardan ma’lumotga ega bo‘lasiz?</th>
                                    <th colSpan={8}>Kasb tanlashdagi muhim omillar?</th>
                                </tr>
                                <tr>
                                    <th>o‘g‘il</th>
                                    <th>qiz</th>
                                    <th>OTMga o‘qishga kirmoqchiman</th>
                                    <th>Texnikumga o‘qishga kirmoqchiman</th>
                                    <th>Kollejga o‘qishga kirmoqchiman</th>
                                    <th>Kasbga tayyorlash markazlarida o‘qib, tadbirkorlik bilan shug‘ullanish</th>
                                    <th>Javob berishga qiynalaman</th>

                                    <th>Ta’lim</th>
                                    <th>Gumanitar fanlar va san’at</th>
                                    <th>Ijtimoiy fanlar, jurnalistika va axborot</th>
                                    <th>Biznes, boshqaruv va huquq</th>
                                    <th>Tabiiy fanlar, matematika va statistika</th>
                                    <th>Axborot-kommunikatsiya texnologiyalari</th>
                                    <th>Muhandislik, ishlov berish va qurilish sohalari</th>
                                    <th>Qishloq, o‘rmon, baliqchilik xo‘jaligi va veterinariya</th>
                                    <th>Sog‘liqni saqlash va ijtimoiy ta’minot</th>
                                    <th>Xizmatlar</th>

                                    <th>Xorijdagi</th>
                                    <th>Xorijiy OTMlarning respublikamizdagi filiallariga</th>
                                    <th>Respublikadagi (Markaziy) OTMlarga</th>
                                    <th>Hududdagi OTMlarga</th>

                                    <th>Davlat</th>
                                    <th>Nodavlat</th>

                                    <th>O‘zim hoxladim</th>
                                    <th>Ota-onam va yaqinlarimning maslahati</th>
                                    <th>Do‘stlarimning maslahati</th>
                                    <th>O‘qituvchimning maslahati</th>
                                    <th>Boshqa ijobiy sabablar</th>

                                    <th>Sanoat</th>
                                    <th>Texnika</th>
                                    <th>Qurilish</th>
                                    <th>Qishloq xo'jaligi</th>
                                    <th>Ishlab chiqarish</th>
                                    <th>Xizmat ko'rsatish</th>
                                    <th>San'at</th>
                                    <th>Ta'lim</th>
                                    <th>AKT</th>
                                    <th>Sog'liqni saqlash</th>
                                    <th>Harbiy</th>
                                    <th>Moliya</th>
                                    <th>Boshqa soha</th>

                                    <th>Ko‘proq daromad topish uchun</th>
                                    <th>Yaxshi lavozimda ishlash va karyera qilish</th>
                                    <th>Hurmatli va obro‘li bo‘lish uchun</th>
                                    <th>Jamiyatga foyda keltirish uchun</th>
                                    <th>Qiziqqan kasbni chuqurroq o‘rganish uchun</th>

                                    <th>Ona tili</th>
                                    <th>Adabiyot</th>
                                    <th>Rus tili</th>
                                    <th>Chet tili</th>
                                    <th>Tarix</th>
                                    <th>Davlat va huquq</th>
                                    <th>Matematika (Algebra)</th>
                                    <th>Informatika va AT</th>
                                    <th>Kimyo</th>
                                    <th>Fizika (astronomiya)</th>
                                    <th>Biologiya</th>
                                    <th>Geografiya</th>
                                    <th>Musiqa</th>
                                    <th>Tasviriy san'at</th>
                                    <th>CHQBT</th>
                                    <th>Jismoniy tarbiya</th>
                                    <th>Boshqa fan</th>

                                    <th>Maktablarda o‘tkazilgan tadbirlarda</th>
                                    <th>Ijtimoiy tarmoqlardan</th>
                                    <th>Ommaviy axborot vositalaridan</th>
                                    <th>Kasb egalaridan</th>
                                    <th>Oila a’zolarimdan</th>

                                    <th>Oiladagi yoshi kattalarning qarashlari va maslahati</th>
                                    <th>Do‘stlarning qarashlari va maslahati</th>
                                    <th>Maktab jamoasi va o‘qituvchlarning qarashlari hamda maslahati</th>
                                    <th>Shaxsiy kasbiy rejalarning mavjudligi</th>
                                    <th>Ma’lum bir kasbga qobiliyat</th>
                                    <th>Jamiyatda o‘z o‘rnini topishi</th>
                                    <th>Kasblar to‘g‘risida axborotga ega bo‘lish</th>
                                    <th>Ma’lum bir kasbga moyillik</th>
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.tableRows}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    }
}
