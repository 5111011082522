import React, {Component} from "react";
import Header from './Header';
import HomePage from "./HomePage";
import Footer from "./Footer";
import {Route, Switch, withRouter} from "react-router-dom";
import AboutProgram from "./AboutProgram";
import QuizView from "./QuizView";
import QuizStartView from "./QuizStartView";
import QuizSummaryView from "./QuizSummaryView";
import WorldOfProfessionsView from "./WorldOfProfessionsView";
import ProfessionView from "./ProfessionView";
import OccupationView from "./OccupationView";
import EffectiveView from "./EffectiveView";
import Effective2View from "./Effective2View";
import MediaView from "./MediaView";
import PsychoogicalAdviceView from "./PsychoogicalAdviceView";
import ContactView from "./ContactView";

import {strings} from "./Utils";
import OccupationMonthView from "./OccupationMonthView";
import OccupationMonthVideoView from "./OccupationMonthVideoView";
import LoginPage from "./LoginPage";
import SectionHomeView from "./SectionHomeView";
import PsychHelpListView from "./PsychHelpListView";
import AddPsychHelpView from "./AddPsychHelpView";
import OfficialDocumentsView from "./OfficialDocumentsView";
import AdminHomeView from "./AdminHomeView";
import SchoolHomePage from "./school/SchoolHomePage";
import DistrictHomeView from "./district/DistrictHomeView";
import RegionHomeView from "./region/RegionHomeView";
import MinistryHomeView from "./MinistryHomeView";
import EntrepreneurshipView from "./EntrepreneurshipView";
import IQTestView from "./IQTestView";

class App extends Component {

	constructor() {
		super();
		this.state = {
			language: 'uz'
		};
		strings.setLanguage(this.state.language);
        let href = window.location.href;
        if (!(href.endsWith('/school') || href.endsWith('/district') || href.endsWith('/region') || href.endsWith('/reports'))) {
            localStorage.setItem('sf', 1);
        } else {
            localStorage.setItem('sf', 0);
        }
	}

	changeLanguage(lang) {
		this.setState({
			language: lang
		});
		strings.setLanguage(lang);
	}

	render() {
	    let item = localStorage.getItem('sf');
		return (
			<div className="App">
				{(+item === 1) &&
					<Header language={this.state.language} changeLanguage={this.changeLanguage.bind(this)}/>
				}
				<Switch>
					<Route path="/" exact>
						<HomePage language={this.state.language}/>
					</Route>
					<Route path="/about">
						<AboutProgram/>
					</Route>
					<Route path="/quiz">
						<QuizView language={this.state.language}/>
					</Route>
					<Route path="/quizStart">
						<QuizStartView language={this.state.language}/>
					</Route>
					<Route path="/testing">
						<QuizSummaryView language={this.state.language}/>
					</Route>
					<Route path="/professions">
						<WorldOfProfessionsView language={this.state.language}/>
					</Route>
					<Route path="/professionView">
						<ProfessionView/>
					</Route>
					<Route path="/occupation">
						<OccupationView language={this.state.language}/>
					</Route>
					<Route path="/effective">
						<EffectiveView language={this.state.language}/>
					</Route>
					<Route path="/effective2">
						<Effective2View/>
					</Route>
					<Route path="/media">
						<MediaView/>
					</Route>
					<Route path="/psychologicalAdvice">
						<PsychoogicalAdviceView language={this.state.language}/>
					</Route>
					<Route path="/contact">
						<ContactView/>
					</Route>
					<Route path="/oсcMonth">
						<OccupationMonthView language={this.state.language}/>
					</Route>
					<Route path="/oсcMonthVideo">
						<OccupationMonthVideoView/>
					</Route>
					<Route path="/login" component={LoginPage} />
					<Route path="/section">
						<SectionHomeView/>
					</Route>
					<Route path="/helpList">
						<PsychHelpListView/>
					</Route>
					<Route path="/addHelp">
						<AddPsychHelpView/>
					</Route>

					<Route path="/documentsView">
						<OfficialDocumentsView/>
					</Route>
					<Route path="/ministry">
						<MinistryHomeView/>
					</Route>
					<Route path="/reports">
						<AdminHomeView/>
					</Route>
					<Route path="/region">
						<RegionHomeView/>
					</Route>
					<Route path="/district">
						<DistrictHomeView/>
					</Route>
					<Route path="/school">
						<SchoolHomePage/>
					</Route>
					<Route path="/ent">
						<EntrepreneurshipView/>
					</Route>
					<Route path="/iqTest">
						<IQTestView/>
					</Route>
				</Switch>
                {+item === 1 &&
                <Footer changeLanguage={this.changeLanguage.bind(this)}/>
                }
			</div>
		);
	}
}

export default withRouter(App);
