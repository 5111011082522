import React, {Component} from 'react';
import axios from 'axios';
import {apiURL, strings} from "./Utils";
import {NavLink, withRouter} from "react-router-dom";

class Effective2View extends Component {

	constructor() {
		super();
		this.state = {
			tableRows: []
		}
	}

	componentDidMount() {
		axios.get(apiURL + '/videos/effectivePupil/list').then(result=>{
			if(result.data.statusCode === 200) {
				let data = result.data.data.map(row=>{
					return <div className="col-12 col-sm-8 col-md-6 col-lg-4">
						<div className="card card-margin">
							<img className="card-img-top" src={"images/b" + row.id + ".jpg"} alt={row.name}/>
							<div className="card-body text-center">
								<h4 className="card-title">{row.name}</h4>
								<NavLink to={"/media?src=b" + row.id} className="btn">{strings.playVideo}</NavLink>
							</div>
						</div>
					</div>
				});
				this.setState({
					tableRows: data
				})
			}
		})
	}

	render() {
		return(
			<div>
				<section className="page-banner">
					<div className="image-layer" style={{backgroundImage: "url(images/bread-bg.jpg)"}}/>
					<div className="auto-container">
						<div className="breadcrumb-box"/>
						<h1>{strings.effectiveMens2}</h1>
					</div>
				</section>

				<section className="about-area inner-content-wrapper">
					<div className="container">
						<div className="col-lg-7 col-md-12">
							<div className="full-sec-content">
								<div className="sec-title style-two">
									<div className="ml-edu">
										<h4>{strings.effectivePupils}</h4>
									</div>
									<span className="decor"><span className="inner"/></span>
								</div>
							</div>
						</div>

						<div className="container">
							<div className="row">
								{this.state.tableRows}
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}

export default withRouter(Effective2View);