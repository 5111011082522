import React, {Component} from 'react';
import JoditEditor from "jodit-react";
import {apiURL} from "./Utils";
import swal from 'sweetalert';
import axios from 'axios';

const config = {
	readonly: false
};

class AddPsychHelpView extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: null,
			title: '',
			language: 1,
			maslahat: ''
		};
		this.maslahatEditor = React.createRef();

	}

	componentDidMount() {
		let queryString = window.location.search;
		let urlParams = new URLSearchParams(queryString);
		let id = urlParams.get('id');
		if (id !== undefined && id !== null && Number(id) > 0) {
			axios.get(apiURL + '/profession/help/item?id=' + id)
				.then(result=>{
					if (result.status === 200) {
						this.setState({
							id: result.data.id,
							title: result.data.name,
							language: result.data.language,
							maslahat: result.data.description
						})
					}
				})
		}
	}

	setContent(name) {
		let content = document.getElementById(name).value;
		this.setState({
			[name]: content
		})
	}

	saveAdvice() {
		let data = new FormData();
		data.append('name', this.state.title);
		data.append('content', this.state.maslahat);
		data.append('language', this.state.language);
		let url = '/profession/help/add';
		if (this.state.id !== null) {
			url = '/profession/help/edit';
			data.append('id', this.state.id);
		}
		axios.post(apiURL + url, data)
			.then(result=>{
				if (result.data.statusCode === 200) {
					swal(result.data.message, '', 'success');
				} else {
					swal(result.data.message, '', 'error');
				}
			})
	}

	render() {
		return(
			<div className="container card card-body my-4">
				<div className="form-row mt-5">
					<div className="col-md-12 mb-3 text-center">
						<h3>Psixologik maslahatlarni kiritish</h3>
					</div>
				</div>
				<div className="form-row">
					<div className="col-md-10">
						<label htmlFor="title">Kasb nomi</label>
						<input id="title" className="form-control" onChange={this.setContent.bind(this, 'title')} defaultValue={this.state.title}/>
					</div>
					<div className="col-md-2">
						<label htmlFor="language">Qaysi tilda</label>
						<select id="language" className="form-control" onChange={this.setContent.bind(this, 'language')} defaultValue={this.state.language}>
							<option value="1">O`zbek</option>
							<option value="2">Rus</option>
						</select>
					</div>
				</div>
				<div className="form-row mt-4">
					<label htmlFor="maslahat">Mazmuni</label>
					<div className="col-md-12">
						<JoditEditor id="maslahat"
						             ref={this.maslahatEditor}
						             value={this.state.maslahat}
						             config={config}
						             tabIndex={11}
						             onChange={this.setContent.bind(this, 'maslahat')}/>
					</div>
				</div>
				<div className="form-row mt-4">
					<div className="col-md-12 text-center">
						<button className="btn btn-info" onClick={this.saveAdvice.bind(this)}>Saqlash</button>
					</div>
				</div>
			</div>
		)
	}
}

export default AddPsychHelpView;