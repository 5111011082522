import React, {Component} from "react";
import {Tab, Tabs} from "react-bootstrap";
import EffectivePupilListView from "./EffectivePupilListView";
import EffectivePeopleListView from "./EffectivePeopleListView";

export default class EffectivesView extends Component {

    constructor() {
        super();
        this.state = {
            tabListKey: 'effectivePupils'
        }
    }

    render() {
        return <div className="container-fluid pt-2">
            <Tabs activeKey={this.state.tabListKey} animation="true" onSelect={key => this.setState({tabListKey: key})}>
                <Tab eventKey="effectivePupils" title="O`quvchilar" mountOnEnter={true} unmountOnExit={true}>
                    <EffectivePupilListView/>
                </Tab>
                <Tab eventKey="effectivePeoples" title="Insonlar" mountOnEnter={true} unmountOnExit={true}>
                    <EffectivePeopleListView/>
                </Tab>
            </Tabs>
        </div>
    }
}