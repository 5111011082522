import React, {Component} from 'react';
import axios from "axios";
import {apiURL, strings} from "./Utils";
import {NavLink, withRouter} from "react-router-dom";

class OccupationView extends Component {

	constructor(props) {
		super(props);
		this.state={
			collegesRow: [],
			regions: this.props.language === 'uz' ? ['Qoraqalpog`iston Respublikasi','Andijon viloyati','Buxoro viloyati','Qashqadaryo viloyati','Jizzax viloyati',
				'Navoiy viloyati','Namangan viloyati','Samarqand viloyati','Sirdaryo viloyati','Surxondaryo viloyati','Toshkent viloyati','Farg`ona viloyati','Xorazm viloyati','Toshkent shahri'] :
			['Республика Каракалпакстан','Андижанская область', 'Бухарская область', 'Кашкадарьинская область', 'Джизакская область', 'Навоийская область', 'Наманганская область', 'Самаркандская область',
				'Сырдарьинская область', 'Сурхандарьинская область', 'Ташкентская область', 'Ферганская область', 'Хорезмская область', 'город Ташкент']
		}
	}
	getColleges(regionId, type) {
		axios.get(apiURL + "/colleges/list2?regionId=" + regionId + "&collegeType=" + type)
			.then(result=> {
			if (result.status === 200) {
				let professionData = result.data;
				let index = 0;
				let tbody = null;
				if (regionId === 1) {
					tbody = document.getElementById('qqr_colleges' + type);
				} else if (regionId === 2) {
					tbody = document.getElementById('andijon_colleges' + type);
				} else if (regionId === 3) {
					tbody = document.getElementById('buxoro_colleges' + type);
				} else if (regionId === 4) {
					tbody = document.getElementById('qashqadaryo_colleges' + type);
				} else if (regionId === 5) {
					tbody = document.getElementById('jizzax_colleges' + type);
				} else if (regionId === 6) {
					tbody = document.getElementById('navoiy_colleges' + type);
				} else if (regionId === 7) {
					tbody = document.getElementById('namangan_colleges' + type);
				} else if (regionId === 8) {
					tbody = document.getElementById('samarqand_colleges' + type);
				} else if (regionId === 9) {
					tbody = document.getElementById('sirdaryo_colleges' + type);
				} else if (regionId === 10) {
					tbody = document.getElementById('surxondaryo_colleges' + type);
				} else if (regionId === 11) {
					tbody = document.getElementById('toshkentv_colleges' + type);
				} else if (regionId === 12) {
					tbody = document.getElementById('fargona_colleges' + type);
				} else if (regionId === 13) {
					tbody = document.getElementById('xorazm_colleges' + type);
				} else if (regionId === 14) {
					tbody = document.getElementById('toshkentsh_colleges' + type);
				}

				if (tbody !== null) {
					let datas = professionData.map(item=> {
						index++;
						return type===1 ? <tr><td>{index}</td><td className="link-style"><NavLink to={'/media?src=v' + item.id} className="btn">{item.name}</NavLink></td></tr>
						: (type === 3 || type === 5) ? <tr><td className="border-right">{index}</td><td className="border-right" style={{width: "30%"}}>{item.name}</td><td className="border-right" style={{width: "15%"}}>{item.website}</td><td className="border-right">{item.address}</td><td style={{width: "15%"}}>{item.phoneNumber}</td></tr> : <tr><td>{index}</td><td>{item.name}</td></tr>;
					});
					this.setState({
						collegesRow: datas
					})
				}
			}
		});
	}

	render() {
		return(
			<div>
				<section className="page-banner">
					<div className="image-layer" style={{backgroundImage: "url(images/bread-bg.jpg)"}}/>
					<div className="auto-container">
						<div className="breadcrumb-box"/>
						<h1>{strings.getProfession}</h1>
					</div>
				</section>

				<section className="about-area inner-content-wrapper">
					<div className="container">
						<div className="sec-title text-center mt-0 mb-0">
							<h2>
								{strings.profession1} <span>{strings.profession2}</span>
							</h2>
							<span className="decor"><span className="inner"/></span>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="full-sec-content">
									<div className="card card-profile mt--300 shadow ml-box">
										<div className="px-4">
											<div className="mt-2 py-3 border-top text-center">
												<div className="row justify-content-center">
													<div className="col-lg-12">
														<div className="nav d-flex nav-pills justify-content-between"
														     id="v-pills-tab" role="tablist"
														     aria-orientation="horizontal">

															<a className="nav-link active text-uppercase"
															   id="v-pills-home-tab" data-toggle="pill"
															   href="#v-pills-one" role="tab"
															   aria-controls="v-pills-home"
															   aria-selected="true">
																<i className="fa fa-school fa-4x p-2" aria-hidden="true"/>
																<br/>
																{strings.litsey}
															</a>
															<hr/>
															<a className="nav-link text-uppercase" id="v-pills-two-tab"
															   data-toggle="pill"
															   href="#v-pills-two" role="tab"
															   aria-controls="v-pills-profile"
															   aria-selected="false">
																<i className="fa fa-chalkboard fa-4x p-2" aria-hidden="true"/>
																<br/>
																{strings.khm}
															</a>
															<hr/>
															<a className="nav-link text-uppercase"
															   id="v-pills-three-tab" data-toggle="pill"
															   href="#v-pills-three" role="tab"
															   aria-controls="v-pills-three"
															   aria-selected="false">
																<i className="fa fa-book-reader fa-4x p-2" aria-hidden="true"/>
																<br/>
																{strings.khk}
															</a>
															<hr/>
															<a className="nav-link text-uppercase" id="v-pills-four-tab"
															   data-toggle="pill"
															   href="#v-pills-four" role="tab"
															   aria-controls="v-pills-settings"
															   aria-selected="false">
																<i className="fa fa-landmark fa-4x p-2" aria-hidden="true"/>
																<br/>{strings.texnikum}</a>
															<hr/>
															<a className="nav-link text-uppercase" id="v-pills-five-tab"
															   data-toggle="pill"
															   href="#v-pills-five" role="tab"
															   aria-controls="v-pills-five" aria-selected="false">
																<i className="fa fa-graduation-cap fa-4x p-2" aria-hidden="true"/>
																<br/>
																{strings.otm}
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-12">
								<div className="full-sec-content">
									<div className="card card-profile mt--300 shadow ml-box">
										<div className="px-4">
											<div className="mt-2 py-3 border-top text-center">
												<div className="row justify-content-center">
													<div className="col-lg-12">
														<div className="tab-content" id="v-pills-tabContent">
															<div className="tab-pane fade show active"
															     id="v-pills-one"
															     role="tabpanel"
															     aria-labelledby="v-pills-home-tab">

																<div id="accordion">
																	<div className="card border-0 mb-2">
																		<div className="card-header row"
																		     id="headingOne">
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#collapseone"
																						aria-expanded="false"
																						aria-controls="collapseone"
																						onClick={this.getColleges.bind(this, 1, 1)}>
																						{this.state.regions[0]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#collapsetwo"
																						aria-expanded="false"
																						aria-controls="collapsetwo"
																						onClick={this.getColleges.bind(this, 2, 1)}>
																						{this.state.regions[1]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#collapsethree"
																						aria-expanded="false"
																						aria-controls="collapsethree"
																						onClick={this.getColleges.bind(this, 3, 1)}>
																						{this.state.regions[2]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#collapsefour"
																						aria-expanded="false"
																						aria-controls="collapsefour"
																						onClick={this.getColleges.bind(this, 4, 1)}>
																						{this.state.regions[3]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#collapsefive"
																						aria-expanded="false"
																						aria-controls="collapsefive"
																						onClick={this.getColleges.bind(this, 5, 1)}>
																						{this.state.regions[4]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#collapsesix"
																						aria-expanded="false"
																						aria-controls="collapsesix"
																						onClick={this.getColleges.bind(this, 6, 1)}>
																						{this.state.regions[5]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#collapseseven"
																						aria-expanded="false"
																						aria-controls="collapseseven"
																						onClick={this.getColleges.bind(this, 7, 1)}>
																						{this.state.regions[6]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#collapseeight"
																						aria-expanded="false"
																						aria-controls="collapseeight"
																						onClick={this.getColleges.bind(this, 8, 1)}>
																						{this.state.regions[7]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#collapsenine"
																						aria-expanded="false"
																						aria-controls="collapsenine"
																						onClick={this.getColleges.bind(this, 9, 1)}>
																						{this.state.regions[8]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#collapseten"
																						aria-expanded="false"
																						aria-controls="collapseten"
																						onClick={this.getColleges.bind(this, 10, 1)}>
																						{this.state.regions[9]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#collapseeleven"
																						aria-expanded="false"
																						aria-controls="collapseeleven"
																						onClick={this.getColleges.bind(this, 11, 1)}>
																						{this.state.regions[10]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#collapsetwelve"
																						aria-expanded="false"
																						aria-controls="collapsetwelve"
																						onClick={this.getColleges.bind(this, 12, 1)}>
																						{this.state.regions[11]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#collapsethirten"
																						aria-expanded="false"
																						aria-controls="collapsethirten"
																						onClick={this.getColleges.bind(this, 13, 1)}>
																						{this.state.regions[12]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#collapsefourten"
																						aria-expanded="false"
																						aria-controls="collapsefourten"
																						onClick={this.getColleges.bind(this, 14, 1)}>
																						{this.state.regions[13]}
																					</button>
																				</h5>
																			</div>
																		</div>

																		<div id="collapseone" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<tbody id="qqr_colleges1">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="collapsetwo" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.litsey}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="andijon_colleges1">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="collapsethree" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.litsey}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="buxoro_colleges1">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="collapsefour" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<tbody id="qashqadaryo_colleges1">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="collapsefive" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.litsey}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="jizzax_colleges1">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="collapsesix" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.litsey}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="navoiy_colleges1">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="collapseseven" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.litsey}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="namangan_colleges1">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="collapseeight" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.litsey}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="samarqand_colleges1">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="collapsenine" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.litsey}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="sirdaryo_colleges1">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="collapseten" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.litsey}
																							</th>
																						</tr>
																						</thead>
																						<tbody
																							id="surxondaryo_colleges1">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="collapseeleven" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.litsey}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="toshkentv_colleges1">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="collapsetwelve" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.litsey}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="fargona_colleges1">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="collapsethirten" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.litsey}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="xorazm_colleges1">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="collapsefourten" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.litsey}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="toshkentsh_colleges1">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="tab-pane fade"
															     id="v-pills-two"
															     role="tabpanel"
															     aria-labelledby="v-pills-two-tab">

																<div id="accordion2">
																	<div className="card border-0 mb-2">
																		<div className="card-header row"
																		     id="headingTwo">
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h2collapseone"
																						aria-expanded="false"
																						aria-controls="h2collapseone"
																						onClick={this.getColleges.bind(this, 1, 3)}>
																						{this.state.regions[0]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h2collapsetwo"
																						aria-expanded="false"
																						aria-controls="h2collapsetwo"
																						onClick={this.getColleges.bind(this, 2, 3)}>
																						{this.state.regions[1]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h2collapsethree"
																						aria-expanded="false"
																						aria-controls="h2collapsethree"
																						onClick={this.getColleges.bind(this, 3, 3)}>
																						{this.state.regions[2]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h2collapsefour"
																						aria-expanded="false"
																						aria-controls="h2collapsefour"
																						onClick={this.getColleges.bind(this, 4, 3)}>
																						{this.state.regions[3]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h2collapsefive"
																						aria-expanded="false"
																						aria-controls="h2collapsefive"
																						onClick={this.getColleges.bind(this, 5, 3)}>
																						{this.state.regions[4]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h2collapsesix"
																						aria-expanded="false"
																						aria-controls="h2collapsesix"
																						onClick={this.getColleges.bind(this, 6, 3)}>
																						{this.state.regions[5]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h2collapseseven"
																						aria-expanded="false"
																						aria-controls="h2collapseseven"
																						onClick={this.getColleges.bind(this, 7, 3)}>
																						{this.state.regions[6]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h2collapseeight"
																						aria-expanded="false"
																						aria-controls="h2collapseeight"
																						onClick={this.getColleges.bind(this, 8, 3)}>
																						{this.state.regions[7]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h2collapsenine"
																						aria-expanded="false"
																						aria-controls="h2collapsenine"
																						onClick={this.getColleges.bind(this, 9, 3)}>
																						{this.state.regions[8]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h2collapseten"
																						aria-expanded="false"
																						aria-controls="h2collapseten"
																						onClick={this.getColleges.bind(this, 10, 3)}>
																						{this.state.regions[9]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h2collapseeleven"
																						aria-expanded="false"
																						aria-controls="h2collapseeleven"
																						onClick={this.getColleges.bind(this, 11, 3)}>
																						{this.state.regions[10]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h2collapsetwelve"
																						aria-expanded="false"
																						aria-controls="h2collapsetwelve"
																						onClick={this.getColleges.bind(this, 12, 3)}>
																						{this.state.regions[11]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h2collapsethirten"
																						aria-expanded="false"
																						aria-controls="h2collapsethirten"
																						onClick={this.getColleges.bind(this, 13, 3)}>
																						{this.state.regions[12]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h2collapsefourten"
																						aria-expanded="false"
																						aria-controls="h2collapsefourten"
																						onClick={this.getColleges.bind(this, 14, 3)}>
																						{this.state.regions[13]}
																					</button>
																				</h5>
																			</div>
																		</div>

																		<div id="h2collapseone" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion2">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.khm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="qqr_colleges3">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h2collapsetwo" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion2">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.khm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="andijon_colleges3">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h2collapsethree" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion2">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.khm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="buxoro_colleges3">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h2collapsefour" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion2">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.khm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody
																							id="qashqadaryo_colleges3">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h2collapsefive" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion2">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.khm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="jizzax_colleges3">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h2collapsesix" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion2">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.khm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="navoiy_colleges3">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h2collapseseven" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion2">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.khm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="namangan_colleges3">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h2collapseeight" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion2">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.khm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="samarqand_colleges3">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h2collapsenine" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion2">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.khm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="sirdaryo_colleges3">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h2collapseten" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion2">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.khm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="surxondaryo_colleges3">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h2collapseeleven" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion2">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.khm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="toshkentv_colleges3">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h2collapsetwelve" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion2">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.khm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="fargona_colleges3">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h2collapsethirten" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion2">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.khm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="xorazm_colleges3">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h2collapsefourten" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion2">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.khm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody
																							id="toshkentsh_colleges3">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="tab-pane fade"
															     id="v-pills-three"
															     role="tabpanel"
															     aria-labelledby="v-pills-three-tab">

																<div id="accordion3">
																	<div className="card border-0 mb-2">
																		<div className="card-header row"
																		     id="headingThree">
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h3collapseone"
																						aria-expanded="false"
																						aria-controls="h3collapseone"
																						onClick={this.getColleges.bind(this, 1, 2)}>
																						{this.state.regions[0]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h3collapsetwo"
																						aria-expanded="false"
																						aria-controls="h3collapsetwo"
																						onClick={this.getColleges.bind(this, 2, 2)}>
																						{this.state.regions[1]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h3collapsethree"
																						aria-expanded="false"
																						aria-controls="h3collapsethree"
																						onClick={this.getColleges.bind(this, 3, 2)}>
																						{this.state.regions[2]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h3collapsefour"
																						aria-expanded="false"
																						aria-controls="h3collapsefour"
																						onClick={this.getColleges.bind(this, 4, 2)}>
																						{this.state.regions[3]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h3collapsefive"
																						aria-expanded="false"
																						aria-controls="h3collapsefive"
																						onClick={this.getColleges.bind(this, 5, 2)}>
																						{this.state.regions[4]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h3collapsesix"
																						aria-expanded="false"
																						aria-controls="h3collapsesix"
																						onClick={this.getColleges.bind(this, 6, 2)}>
																						{this.state.regions[5]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h3collapseseven"
																						aria-expanded="false"
																						aria-controls="h3collapseseven"
																						onClick={this.getColleges.bind(this, 7, 2)}>
																						{this.state.regions[6]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h3collapseeight"
																						aria-expanded="false"
																						aria-controls="h3collapseeight"
																						onClick={this.getColleges.bind(this, 8, 2)}>
																						{this.state.regions[7]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h3collapsenine"
																						aria-expanded="false"
																						aria-controls="h3collapsenine"
																						onClick={this.getColleges.bind(this, 9, 2)}>
																						{this.state.regions[8]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h3collapseten"
																						aria-expanded="false"
																						aria-controls="h3collapseten"
																						onClick={this.getColleges.bind(this, 10, 2)}>
																						{this.state.regions[9]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h3collapseeleven"
																						aria-expanded="false"
																						aria-controls="h3collapseeleven"
																						onClick={this.getColleges.bind(this, 11, 2)}>
																						{this.state.regions[10]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h3collapsetwelve"
																						aria-expanded="false"
																						aria-controls="h3collapsetwelve"
																						onClick={this.getColleges.bind(this, 12, 2)}>
																						{this.state.regions[11]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h3collapsethirten"
																						aria-expanded="false"
																						aria-controls="h3collapsethirten"
																						onClick={this.getColleges.bind(this, 13, 2)}>
																						{this.state.regions[12]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h3collapsefourten"
																						aria-expanded="false"
																						aria-controls="h3collapsefourten"
																						onClick={this.getColleges.bind(this, 14, 2)}>
																						{this.state.regions[13]}
																					</button>
																				</h5>
																			</div>
																		</div>

																		<div id="h3collapseone" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion3">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.khk}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="qqr_colleges2">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h3collapsetwo" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion3">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.khk}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="andijon_colleges2">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h3collapsethree" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion3">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.khk}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="buxoro_colleges2">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h3collapsefour" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion3">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.khk}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="qashqadaryo_colleges2">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h3collapsefive" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion3">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.khk}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="jizzax_colleges2">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h3collapsesix" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion3">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.khk}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="navoiy_colleges2">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h3collapseseven" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion3">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.khk}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="namangan_colleges2">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h3collapseeight" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion3">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.khk}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="samarqand_colleges2">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h3collapsenine" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion3">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.khk}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="sirdaryo_colleges2">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h3collapseten" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion3">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.khk}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="surxondaryo_colleges2">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h3collapseeleven" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion3">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.khk}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="toshkentv_colleges2">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h3collapsetwelve" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion3">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.khk}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="fargona_colleges2">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h3collapsethirten" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion3">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.khk}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="xorazm_colleges2">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h3collapsefourten" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion3">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.khk}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="toshkentsh_colleges2">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="tab-pane fade"
															     id="v-pills-four"
															     role="tabpanel"
															     aria-labelledby="v-pills-four-tab">

																<div id="accordion4">
																	<div className="card border-0 mb-2">
																		<div className="card-header row"
																		     id="headingFour">
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h4collapseone"
																						aria-expanded="false"
																						aria-controls="h4collapseone"
																						onClick={this.getColleges.bind(this, 1, 4)}>
																						{this.state.regions[0]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h4collapsetwo"
																						aria-expanded="false"
																						aria-controls="h4collapsetwo"
																						onClick={this.getColleges.bind(this, 2, 4)}>
																						{this.state.regions[1]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h4collapsethree"
																						aria-expanded="false"
																						aria-controls="h4collapsethree"
																						onClick={this.getColleges.bind(this, 3, 4)}>
																						{this.state.regions[2]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h4collapsefour"
																						aria-expanded="false"
																						aria-controls="h4collapsefour"
																						onClick={this.getColleges.bind(this, 4, 4)}>
																						{this.state.regions[3]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h4collapsefive"
																						aria-expanded="false"
																						aria-controls="h4collapsefive"
																						onClick={this.getColleges.bind(this, 5, 4)}>
																						{this.state.regions[4]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h4collapsesix"
																						aria-expanded="false"
																						aria-controls="h4collapsesix"
																						onClick={this.getColleges.bind(this, 6, 4)}>
																						{this.state.regions[5]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h4collapseseven"
																						aria-expanded="false"
																						aria-controls="h4collapseseven"
																						onClick={this.getColleges.bind(this, 7, 4)}>
																						{this.state.regions[6]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h4collapseeight"
																						aria-expanded="false"
																						aria-controls="h4collapseeight"
																						onClick={this.getColleges.bind(this, 8, 4)}>
																						{this.state.regions[7]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h4collapsenine"
																						aria-expanded="false"
																						aria-controls="h4collapsenine"
																						onClick={this.getColleges.bind(this, 9, 4)}>
																						{this.state.regions[8]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h4collapseten"
																						aria-expanded="false"
																						aria-controls="h4collapseten"
																						onClick={this.getColleges.bind(this, 10, 4)}>
																						{this.state.regions[9]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h4collapseeleven"
																						aria-expanded="false"
																						aria-controls="h4collapseeleven"
																						onClick={this.getColleges.bind(this, 11, 4)}>
																						{this.state.regions[10]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h4collapsetwelve"
																						aria-expanded="false"
																						aria-controls="h4collapsetwelve"
																						onClick={this.getColleges.bind(this, 12, 4)}>
																						{this.state.regions[11]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h4collapsethirten"
																						aria-expanded="false"
																						aria-controls="h4collapsethirten"
																						onClick={this.getColleges.bind(this, 13, 4)}>
																						{this.state.regions[12]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h4collapsefourten"
																						aria-expanded="false"
																						aria-controls="h4collapsefourten"
																						onClick={this.getColleges.bind(this, 14, 4)}>
																						{this.state.regions[13]}
																					</button>
																				</h5>
																			</div>
																		</div>

																		<div id="h4collapseone" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion4">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.texnikum}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="qqr_colleges4">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h4collapsetwo" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion4">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.texnikum}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="andijon_colleges4">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h4collapsethree" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion4">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.texnikum}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="buxoro_colleges4">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h4collapsefour" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion4">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.texnikum}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="qashqadaryo_colleges4">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h4collapsefive" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion4">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.texnikum}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="jizzax_colleges4">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h4collapsesix" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion4">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.texnikum}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="navoiy_colleges4">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h4collapseseven" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion4">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.texnikum}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="namangan_colleges4">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h4collapseeight" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion4">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.texnikum}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="samarqand_colleges4">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h4collapsenine" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion4">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.texnikum}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="sirdaryo_colleges4">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h4collapseten" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion4">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.texnikum}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="surxondaryo_colleges4">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h4collapseeleven" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion4">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.texnikum}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="toshkentv_colleges4">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h4collapsetwelve" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion4">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.texnikum}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="fargona_colleges4">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h4collapsethirten" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion4">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.texnikum}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="xorazm_colleges4">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h4collapsefourten" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion4">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">
																								{strings.texnikum}
																							</th>
																						</tr>
																						</thead>
																						<tbody id="toshkentsh_colleges4">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="tab-pane fade"
															     id="v-pills-five"
															     role="tabpanel"
															     aria-labelledby="v-pills-five-tab">

																<div id="accordion5">
																	<div className="card border-0 mb-2">
																		<div className="card-header row"
																		     id="headingFive">
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h5collapseone"
																						aria-expanded="false"
																						aria-controls="h5collapseone"
																						onClick={this.getColleges.bind(this, 1, 5)}>
																						{this.state.regions[0]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h5collapsetwo"
																						aria-expanded="false"
																						aria-controls="h5collapsetwo"
																						onClick={this.getColleges.bind(this, 2, 5)}>
																						{this.state.regions[1]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h5collapsethree"
																						aria-expanded="false"
																						aria-controls="h5collapsethree"
																						onClick={this.getColleges.bind(this, 3, 5)}>
																						{this.state.regions[2]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h5collapsefour"
																						aria-expanded="false"
																						aria-controls="h5collapsefour"
																						onClick={this.getColleges.bind(this, 4, 5)}>
																						{this.state.regions[3]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h5collapsefive"
																						aria-expanded="false"
																						aria-controls="h5collapsefive"
																						onClick={this.getColleges.bind(this, 5, 5)}>
																						{this.state.regions[4]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h5collapsesix"
																						aria-expanded="false"
																						aria-controls="h5collapsesix"
																						onClick={this.getColleges.bind(this, 6, 5)}>
																						{this.state.regions[5]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h5collapseseven"
																						aria-expanded="false"
																						aria-controls="h5collapseseven"
																						onClick={this.getColleges.bind(this, 7, 5)}>
																						{this.state.regions[6]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h5collapseeight"
																						aria-expanded="false"
																						aria-controls="h5collapseeight"
																						onClick={this.getColleges.bind(this, 8, 5)}>
																						{this.state.regions[7]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h5collapsenine"
																						aria-expanded="false"
																						aria-controls="h5collapsenine"
																						onClick={this.getColleges.bind(this, 9, 5)}>
																						{this.state.regions[8]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h5collapseten"
																						aria-expanded="false"
																						aria-controls="h5collapseten"
																						onClick={this.getColleges.bind(this, 10, 5)}>
																						{this.state.regions[9]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h5collapseeleven"
																						aria-expanded="false"
																						aria-controls="h5collapseeleven"
																						onClick={this.getColleges.bind(this, 11, 5)}>
																						{this.state.regions[10]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h5collapsetwelve"
																						aria-expanded="false"
																						aria-controls="h5collapsetwelve"
																						onClick={this.getColleges.bind(this, 12, 5)}>
																						{this.state.regions[11]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h5collapsethirten"
																						aria-expanded="false"
																						aria-controls="h5collapsethirten"
																						onClick={this.getColleges.bind(this, 13, 5)}>
																						{this.state.regions[12]}
																					</button>
																				</h5>
																			</div>
																			<div className="col-md-2">
																				<h5 className="mb-0 d-flex">
																					<button
																						className="btn btn-link btnsze collapsed"
																						data-toggle="collapse"
																						data-target="#h5collapsefourten"
																						aria-expanded="false"
																						aria-controls="h5collapsefourten"
																						onClick={this.getColleges.bind(this, 14, 5)}>
																						{this.state.regions[13]}
																					</button>
																				</h5>
																			</div>
																		</div>

																		<div id="h5collapseone" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion5">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.otm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="qqr_colleges5">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h5collapsetwo" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion5">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.otm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="andijon_colleges5">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h5collapsethree" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion5">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.otm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="buxoro_colleges5">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h5collapsefour" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion5">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.otm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="qashqadaryo_colleges5">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h5collapsefive" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion5">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.otm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="jizzax_colleges5">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h5collapsesix" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion5">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.otm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="navoiy_colleges5">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h5collapseseven" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion5">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.otm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="namangan_colleges5">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h5collapseeight" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion5">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.otm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="samarqand_colleges5">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h5collapsenine" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion5">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.otm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="sirdaryo_colleges5">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h5collapseten" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion5">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.otm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="surxondaryo_colleges5">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h5collapseeleven" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion5">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.otm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="toshkentv_colleges5">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h5collapsetwelve" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion5">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.otm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="fargona_colleges5">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h5collapsethirten" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion5">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.otm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="xorazm_colleges5">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																		<div id="h5collapsefourten" className="collapse"
																		     aria-labelledby="headingone"
																		     data-parent="#accordion5">
																			<div className="card-body">
																				<div className="d-flex text-justify">
																					<table
																						className="table table-striped">
																						<thead>
																						<tr>
																							<th scope="col">#</th>
																							<th scope="col">{strings.otm}</th>
																							<th scope="col">{strings.website}</th>
																							<th scope="col">{strings.address}</th>
																							<th scope="col">{strings.phoneNumber}</th>
																						</tr>
																						</thead>
																						<tbody id="toshkentsh_colleges5">
																						{this.state.collegesRow}
																						</tbody>
																					</table>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}

export default withRouter(OccupationView);