import React, {Component} from 'react';
import {apiURL, strings} from "./Utils";
import {Link} from "react-router-dom";
import logoHeader from './images/logo.png';
import axios from 'axios';

export default class Footer extends Component {

	constructor() {
		super();
		this.state = {
            testResultCount: 0
		}
	}

    moneyFormat(price) {
        const pieces = parseFloat(price).toFixed(0).split('');
        let ii = pieces.length;
        while ((ii-=3) > 0) {
            pieces.splice(ii, 0, ' ')
        }
        return pieces.join('')
    }

	componentDidMount() {
		axios.get(apiURL + '/report/testResultCount').then(result=>{
			if(result.data.statusCode === 200) {
				this.setState({
                    testResultCount: result.data.data
				})
			}
		})
	}

	setLanguage(lang) {
		this.props.changeLanguage(lang);
	}

	render() {
		return (
			<div>
				<div className="achivement-area">
					<div className="container">
						<div className="fixed-thumb"><img src="images/map.png" alt=""/></div>
						<div className="row">
							<div className="col-lg-4 single-item">
								<div className="fun-fact">
									<div className="counter">
										<div className="count">
											<div className="timer" data-to="450" data-speed="5000"/>
										</div>
									</div>
									<div className="info">
										<h5>{strings.allResources}</h5>
										<p>{strings.allResourcesStat}</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 single-item">
								<div className="fun-fact">
									<div className="counter">
										<div className="count">
											<div className="timer" data-to="17" data-speed="5000"/>
										</div>
									</div>
									<div className="info">
										<h5>{strings.testCount}</h5>
										<p>{strings.testCountAboutProf}</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 single-item">
								<div className="fun-fact">
									<div className="counter">
										<div className="count">
											<span>{this.moneyFormat(this.state.testResultCount)}</span>
										</div>
									</div>
									<div className="info">
										<h5>{strings.participants}</h5>
										<p>{strings.testedParticipants}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="brand-wrapper">
					<div className="container">
						<div className="sec-title text-center mb-0">
							<h2><span>{strings.usefulResources}</span></h2>
							<p>{strings.govSites}</p>
							<span className="decor"><span className="inner"/></span>
						</div>
						<div className="row justify-content-center">
							<div className="col-lg-10">
								<div className="owl-carousel owl-theme brand-slider">
									<div className="item">
										<a target="_blank" rel="noreferrer" href="https://www.uzedu.uz/">
											<img src="images/xtv.jpg" alt={strings.uzedu} title={strings.uzedu}/>
										</a>
									</div>
									<div className="item">
										<a target="_blank" rel="noreferrer" href="https://www.edu.uz">
											<img src="images/oliy-talim.jpg" alt={strings.edu} title={strings.edu}/>
										</a>
									</div>
									<div className="item">
										<a target="_blank" rel="noreferrer" href="http://www.taqvim.uz/">
											<img src="images/kutubxona.jpg" alt={strings.taqvim} title={strings.taqvim}/>
										</a>
									</div>
									<div className="item">
										<a target="_blank" rel="noreferrer" href="http://constitution.uz/oz/clause/index">
											<img src="images/konstitutsiya.jpg" alt={strings.constitution} title={strings.constitution}/>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<footer className="footer">
					<div className="footer-wrapper">
						<div className="container">
							<div className="row">
								<div className="container">
									<div className="row">
										<div className="col-xl-4 col-lg-6 col-md-6">
											<div className="wrapper">
												<div className="footer-item-left">
													<div className="logo">
														<Link to="/">
															<img src={logoHeader} alt=""/>
														</Link>
														<p className="ml-2">{strings.rtm3}</p>
													</div>
													<div className="footer-social">
														<ul className="inline-block">
															<li>
																<a href="https://facebook.com/tashxismarkazi/"
																   target="_blank" rel="noreferrer">
																	<i className="fab fa-facebook-f"/>
																</a>
															</li>
															<li>
																<a href="https://t.me/tashxismarkazi" target="_blank" rel="noreferrer">
																	<i className="fab fa-telegram"/>
																</a>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-2 col-lg-6 col-md-6">
											<div className="wrapper">
												<div className="footer-item-center">
													<h6>{strings.home}</h6>
													<ul>
														<li><Link to="/about">{strings.aboutProgram}</Link></li>
														<li><Link to="/quiz">{strings.tests}</Link></li>
														<li><Link to="/professions">{strings.professionWorld}</Link></li>
														<li><Link to="/psychologicalAdvice">{strings.psychCounseling}</Link></li>
														<li><Link to="/contact">{strings.contact}</Link></li>
													</ul>
												</div>
											</div>
										</div>
										<div className="col-xl-2 col-lg-6 col-md-6">
											<div className="wrapper">
												<div className="footer-item-center">
													<h6>{strings.help}</h6>
													<ul>
														<li><Link to="/register">{strings.registration}</Link></li>

														<li><Link to="/effective">{strings.videos}</Link></li>
														<li>
															<Link to="/professions">{strings.profTypes}</Link>
														</li>
														<li className="cursor-pointer" onClick={this.setLanguage.bind(this, 'uz')}>
															<span style={{color: "#FFFFFF"}}>
																<img className="lang-flag" src="/images/uz.jpg" alt=""/>
																O`zbekcha
															</span>
														</li>
														<li className="cursor-pointer" onClick={this.setLanguage.bind(this, 'ru')}>
															<span style={{color: "#FFFFFF"}}>
																<img className="lang-flag" src="/images/ru.jpg" alt=""/>
																Русский
															</span>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-6 col-md-6">
											<div className="wrapper">
												<div className="footer-item-right">
													<h6>{strings.contact}</h6>
													<form>
														<div className="row">
															<div className="col">
																<input className="form-control" placeholder={strings.phoneNumber + " (+99871-246-98-04)"} />
															</div>
														</div>
														<div className="row">
															<div className="col">
																<div className="btn-wrapper">
																	<button type="button" className="btn">
																		{strings.sendMessage}
																	</button>
																</div>
															</div>
														</div>
													</form>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</div>
		)
	}
}