import React, {Component} from 'react';
import axios from "axios";
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import * as _ from '../Utils';
import Modal from "react-responsive-modal";
import Class11PupilSurveyView from "./Class11PupilSurveyView";
import Class9PupilSurveyView from "./Class9PupilSurveyView";
import AddPupilView from "./AddPupilView";

class SchoolPupilSurveyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            districts: [],
            schools: [],
            survey: null,
            pupil: null,
            pupils: [],
            surveyModal: false,
            openAddPupilModal: false,
        };
        this.getSurveyList = this.getSurveyList.bind(this);
    }

    componentDidMount() {
        this.getSurveyList();
    }

    getSurveyList() {
        let schoolId = this.props.user !== undefined && this.props.user !== null ? this.props.user.stateId : null;
        let klass = this.props.klass;
        let klassLetter = document.getElementById('klassLetter').value;
        axios.get(_.apiURL + '/pupil/survey/list?schoolId=' + schoolId + '&klass=' + klass + '&klassLetter=' + klassLetter)
            .then(result => {
                let rows = [];
                if (result.data.statusCode === 200) {
                    let resultData = result.data.data !== undefined && result.data.data !== null ? result.data.data : [];
                    rows = resultData.map((row, index)=>{
                        return <tr key={'sl_' + index}>
                            <td>{index + 1}</td>
                            <td>{row.pupil.name}</td>
                            <td className="text-center">
                                <button className="btn btn-success" title="So`rovnoma" onClick={this.openPupilSurveyForm.bind(this, row)}>
                                    {row.id !== undefined && row.id !== null ? "Tahrirlash" : "Kiritish"}</button>
                            </td>
                            {!this.props.user.testUser &&
                                <td className="text-center">
                                    <button className="btn btn-danger mx-lg-1" title="O`chirish"
                                            onClick={this.removePupil.bind(this, row)}><i className="fa fa-remove"/>
                                    </button>
                                </td>
                            }
                        </tr>;
                    });
                }
                this.setState({
                    pupils: rows
                });
            })
    }

    openPupilSurveyForm(survey) {
        this.setState({
            survey: survey,
            pupil: survey.pupil,
            surveyModal: true
        })
    }

    closePupilSurveyForm() {
        this.setState({
            survey: null,
            pupil: null,
            surveyModal: false
        })
    }

    closeAddPupilModal() {
        this.setState({
            openAddPupilModal: false
        })
    }

    addPupil() {
        this.setState({
            openAddPupilModal: true
        })
    }

    removePupil(row) {
        Swal.fire({
            title: "O`quvchi ma'lumotini o`chirish",
            text: 'Siz haqiqatdan ham "' + row.pupil.name + '" ma`lumotini o`chirmoqchimisiz?',
            type: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ha',
            cancelButtonText: 'Yo`q'
        }).then((result) => {
            if (result.value) {
                axios.post(_.apiURL + '/pupil/delete?pupilId=' + row.pupil.id).then(result => {
                    if (result.data.statusCode === 200) {
                        swal(result.data.message, '', 'success');
                        this.getSurveyList();
                    } else {
                        swal(result.data.message, '', 'error');
                    }
                });
            }
        })
    }

    render() {
        return (
            <div className="content">
                {this.props.user !== undefined && this.props.user !== null &&
                <div className="card card-body my-4 shadow-sm">
                    <div className="row mb-2">
                        <div className="col-md-2">
                            <select id="klass" className="form-control" onChange={this.getSurveyList.bind(this)}>
                                <option value={this.props.klass}>{this.props.klass}</option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <select id="klassLetter" className="form-control" onChange={this.getSurveyList.bind(this)}>
                                <option value=""> sinf</option>
                                <option value="A">A-sinf</option>
                                <option value="B">B-sinf</option>
                                <option value="V">V-sinf</option>
                                <option value="G">G-sinf</option>
                                <option value="D">D-sinf</option>
                                <option value="E">E-sinf</option>
                                <option value="Yo">Yo-sinf</option>
                                <option value="J">J-sinf</option>
                                <option value="Z">Z-sinf</option>
                                <option value="I">I-sinf</option>
                                <option value="Y">Y-sinf</option>
                                <option value="K">K-sinf</option>
                                <option value="L">L-sinf</option>
                                <option value="M">M-sinf</option>
                                <option value="N">N-sinf</option>
                                <option value="O">O-sinf</option>
                                <option value="P">P-sinf</option>
                                <option value="Q">Q-sinf</option>
                                <option value="R">R-sinf</option>
                                <option value="S">S-sinf</option>
                                <option value="T">T-sinf</option>
                                <option value="U">U-sinf</option>
                                <option value="F">F-sinf</option>
                                <option value="X">X-sinf</option>
                            </select>
                        </div>
                        <div className="col-md-8 text-md-end">
                            {!this.props.user.testUser &&
                                <button className="btn btn-info" onClick={this.addPupil.bind(this)}><i
                                    className="fa fa-plus-square"/> O`quvchi qo`shish</button>
                            }
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-12">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>№</th>
                                        <th>{_.strings.pupilName}</th>
                                        <th>So`rovnoma</th>
                                        {!this.props.user.testUser &&
                                            <th>{_.strings.action}</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.pupils}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Modal open={this.state.surveyModal} center closeOnEsc={true} onClose={this.closePupilSurveyForm.bind(this)}>
                        {Number(this.props.klass) === 11 ?
                            <Class11PupilSurveyView pupil={this.state.pupil} survey={this.state.survey}
                                                    reloadList={this.getSurveyList.bind(this)}
                                                    onClose={this.closePupilSurveyForm.bind(this)}/> :
                            <Class9PupilSurveyView pupil={this.state.pupil} survey={this.state.survey}
                                                    reloadList={this.getSurveyList.bind(this)}
                                                    onClose={this.closePupilSurveyForm.bind(this)}/>
                        }
                    </Modal>
                    <Modal open={this.state.openAddPupilModal} center closeOnEsc={true} onClose={this.closeAddPupilModal.bind(this)}>
                        <AddPupilView schoolId={this.props.user.stateId} reloadList={this.getSurveyList}/>
                    </Modal>
                </div>
                }
            </div>
        )
    }
}

export default SchoolPupilSurveyList;