import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {strings} from "./Utils";

class OfficialDocumentsView extends Component {

	render() {
		let queryString = window.location.search;
		let urlParams = new URLSearchParams(queryString);
		let type = urlParams.get('type');
		let language = urlParams.get('lang');

		let title = '';
		if (type !== undefined && type !== null) {
			type = Number(type);
			if (type === 1) {
				title = strings.documentsType1;
			} else if (type === 2) {
				title = strings.documentsType2;
			} else if (type === 3) {
				title = strings.documentsType3;
			} else if (type === 4) {
				title = strings.documentsType4;
			} else if (type === 5) {
				title = strings.documentsType5;
			}
		}
		return(
			<div className="container card card-body my-4">
				{type === 1 &&
				<div className="row">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-12 text-center">
								<h3>{title}</h3>
							</div>
						</div>
						<hr/>
						{language === 'uz' &&
						<div className="row">
							<div className="col-md-12 text-center mt-3">
								<div className="docCont">
									<div className="docType" style={{backgroundImage: "url(images/documentType2.jpg)"}}>
										<div className="docDate">19.05.2020</div>
									</div>
									<a href="/documents/TTQ.pdf" target="_blank">
										<div className="docTitle">TA'LIM TO`G`RISIDAGI O`ZBEKISTON RESPUBLIKASI QONUNI
										</div>
									</a>
								</div>
							</div>
						</div>
						}
						{language === 'ru' &&
						<div className="row">
							<div className="col-md-12 text-center mt-3">
								<div className="docCont">
									<div className="docType" style={{backgroundImage: "url(images/documentType2.jpg)"}}>
										<div className="docDate">19.05.2020</div>
									</div>
									<a href="/documents/MNO.pdf" target="_blank">
										<div className="docTitle">ЗАКОН ОБ ОБРАЗОВАНИЯ РЕСПУБЛИКИ УЗБЕКИСТАН</div>
									</a>
								</div>
							</div>
						</div>
						}
					</div>
				</div>
				}

				{type === 2 &&
				<div className="row">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-12 text-center">
								<h3>{title}</h3>
							</div>
						</div>
						<hr/>
						<div className="row">
							{language === 'uz' &&
							<div className="col-md-4 mt-3">
								<div className="docCont">
									<div className="docType" style={{backgroundImage: "url(images/documentType2.jpg)"}}>
										<div className="docDate">06.09.2019</div>
									</div>
									<a href="/documents/PF-5812.pdf" target="_blank">
										<div className="docTitle">PROFESSIONAL TA’LIM TIZIMINI YANADA
											TAKOMILLASHTIRISHGA DOIR
											QO‘SHIMCHA CHORA-TADBIRLAR TO‘G‘RISIDA O‘ZBEKISTON RESPUBLIKASI
											PREZIDENTINING FARMONI
										</div>
									</a>
								</div>
							</div>
							}
							{language === 'ru' &&
							<div className="col-md-4 mt-3">
								<div className="docCont">
									<div className="docType" style={{backgroundImage: "url(images/documentType2.jpg)"}}>
										<div className="docDate">06.09.2019</div>
									</div>
									<a href="/documents/UP-5812.pdf" target="_blank">
										<div className="docTitle">УКАЗ ПРЕЗИДЕНТА РЕСПУБЛИКИ УЗБЕКИСТАН О ДОПОЛНИТЕЛЬНЫХ МЕРАХ ПО ДАЛЬНЕЙШЕМУ СОВЕРШЕНСТВОВАНИЮ СИСТЕМЫ ПРОФЕССИОНАЛЬНОГО ОБРАЗОВАНИЯ</div>
									</a>
								</div>
							</div>
							}
							{language === 'uz' &&
							<div className="col-md-4 mt-3">
								<div className="docCont">
									<div className="docType" style={{backgroundImage: "url(images/documentType2.jpg)"}}>
										<div className="docDate">06.11.2020</div>
									</div>
									<a href="/documents/PF-6108.pdf" target="_blank">
										<div className="docTitle">O‘ZBEKISTONNING YANGI TARAQQIYOT DAVRIDA
											TA’LIM-TARBIYA VA
											ILM-FAN SOHALARINI RIVOJLANTIRISH CHORA-TADBIRLARI TO‘G‘RISIDA
											O‘ZBEKISTON RESPUBLIKASI PREZIDENTINING FARMONI
										</div>
									</a>
								</div>
							</div>
							}
							{language === 'ru' &&
							<div className="col-md-4 mt-3">
								<div className="docCont">
									<div className="docType" style={{backgroundImage: "url(images/documentType2.jpg)"}}>
										<div className="docDate">06.11.2020</div>
									</div>
									<a href="/documents/UP-6108.pdf" target="_blank">
										<div className="docTitle">УКАЗ ПРЕЗИДЕНТА РЕСПУБЛИКИ УЗБЕКИСТАН О МЕРАХ ПО РАЗВИТИЮ СФЕР ОБРАЗОВАНИЯ И ВОСПИТАНИЯ, И НАУКИ В НОВЫЙ ПЕРИОД РАЗВИТИЯ УЗБЕКИСТАНА</div>
									</a>
								</div>
							</div>
							}
							{language === 'uz' &&
							<div className="col-md-4 mt-3">
								<div className="docCont">
									<div className="docType" style={{backgroundImage: "url(images/documentType2.jpg)"}}>
										<div className="docDate">06.11.2020</div>
									</div>
									<a href="/documents/PQ-4884.pdf" target="_blank">
										<div className="docTitle">TA’LIM-TARBIYA TIZIMINI YANADA TAKOMILLASHTIRISHGA OID
											QO‘SHIMCHA
											CHORA-TADBIRLAR TO‘G‘RISIDA O‘ZBEKISTON RESPUBLIKASI PREZIDENTINING QARORI
										</div>
									</a>
								</div>
							</div>
							}
							{language === 'ru' &&
							<div className="col-md-4 mt-3">
								<div className="docCont">
									<div className="docType" style={{backgroundImage: "url(images/documentType2.jpg)"}}>
										<div className="docDate">06.11.2020</div>
									</div>
									<a href="/documents/PP-4884.pdf" target="_blank">
										<div className="docTitle">УКАЗ ПРЕЗИДЕНТА РЕСПУБЛИКИ УЗБЕКИСТАН О ДОПОЛНИТЕЛЬНЫХ МЕРАХ ПО ДАЛЬНЕЙШЕМУ СОВЕРШЕНСТВОВАНИЮ СИСТЕМЫ ОБРАЗОВАНИЯ И ВОСПИТАНИЯ</div>
									</a>
								</div>
							</div>
							}
						</div>
					</div>
				</div>
				}

				{type === 3 &&
				<div className="row">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-12 text-center">
								<h3>{title}</h3>
							</div>
						</div>
						<hr/>
						<div className="row">
							{language === 'uz' &&
							<div className="col-md-6 mt-3">
								<div className="docCont">
									<div className="docType" style={{backgroundImage: "url(images/documentType2.jpg)"}}>
										<div className="docDate">23.03.2018</div>
									</div>
									<a href="/documents/VMQ 212.pdf" target="_blank">
										<div className="docTitle">AKADEMIK LITSEYLARGA UMUMIY O‘RTA TA’LIM MAKTABLARI
											O‘QUVCHILARINI TANLAB OLISH, O‘QISHGA QABUL QILISH, ULARNING
											O‘QISHINI KO‘CHIRISH VA O‘QISHDAN CHETLATISH TARTIBI TO‘G‘RISIDAGI
											NIZOMNI TASDIQLASH HAQIDA O‘ZBEKISTON RESPUBLIKASI VAZIRLAR MAHKAMASINING
											QARORI
										</div>
									</a>
								</div>
							</div>
							}
							{language === 'uz' &&
							<div className="col-md-6 mt-3">
								<div className="docCont">
									<div className="docType" style={{backgroundImage: "url(images/documentType2.jpg)"}}>
										<div className="docDate">12.07.2019</div>
									</div>
									<a href="/documents/VMQ 577.pdf" target="_blank">
										<div className="docTitle">O‘QUVCHILARNI PSIXOLOGIK-PEDAGOGIK QO‘LLAB-QUVVATLASH
											ISHLARINI
											YANADA TAKOMILLASHTIRISH TO‘G‘RISIDA O‘ZBEKISTON RESPUBLIKASI VAZIRLAR
											MAHKAMASINING
											QARORI
										</div>
									</a>
								</div>
							</div>
							}
						</div>
					</div>
				</div>
				}

				{type === 4 &&
				<div className="row">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-12 text-center">
								<h3>{title}</h3>
							</div>
						</div>
						<hr/>
						{language === 'uz' &&
						<div className="row">
							<div className="col-md-12 mt-3">
								<div className="docCont">
									<div className="docType" style={{backgroundImage: "url(images/documentType4.jpg)"}}>
										<div className="docDate">25.02.2020</div>
									</div>
									<a href="/documents/XTV52.pdf" target="_blank">
										<div className="docTitle">“UMUMIY OʻRTA TAʼLIM MAKTABLARI UCHUN 2020-2021-OʻQUV
											YILIGA MOʻLJALLANGAN TAYANCH OʻQUV REJANI TASDIQLASH TOʻGʻRISIDA” XALQ TAʼLIMI
											VAZIRINING 25-FEVRAL 2020-YILDAGI 52-SONLI BUYRUGʻI
										</div>
									</a>
								</div>
							</div>
						</div>
						}
					</div>
				</div>
				}

				{type === 5 &&
				<div className="row">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-12 text-center">
								<h3>{title}</h3>
							</div>
						</div>
						<hr/>
						{language === 'uz' &&
						<div className="row">
							<div className="col-md-12 mt-3">
								<div className="docCont">
									<div className="docType" style={{backgroundImage: "url(images/documentType5.jpg)"}}>
										<div className="docDate">25.02.2020</div>
									</div>
									<a href="/documents/RTM46.PDF" target="_blank">
										<div className="docTitle">"UMUMIY O`RTA TA'LIM MUASSASALARIDA KASB-HUNARGA
											YO`NALTIRISH ISHLARINI TASHKIL ETISH TO`G`RISIDA" RESPUBLIKA TASHXIS MARKAZI
											DIREKTORINING 2020 YIL 25 SENTYABRDAGI 46-SONLI BUYRUG`I
										</div>
									</a>
								</div>
							</div>
						</div>
						}
					</div>
				</div>
				}
			</div>
		)
	}
}
export default withRouter(OfficialDocumentsView);