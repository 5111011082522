import LocalizedStrings from "react-localization";

// export const apiURL = 'http://localhost:8080';
export const apiURL = 'http://profnavigator.uz';

export const strings = new LocalizedStrings({
	uz: {
		back: "Ortga qaytish",
		homePage: "Bosh sahifa",
		aboutProgram: "Dastur haqida",
		tests: "Testlar",
		tests2: "Test topshiriqlari",
		test9: "9-sinf so`rovnomasi",
		test11: "11-sinf so`rovnomasi",
		iqTest: "IQ test",
		professionWorld: "Kasblar olami",
		professionWorld2: "Kasbni egallash",
		effective: "Muvaffaqiyatli insonlar",
		effective2: "Muvaffaqiyatli insonlar tajribasi",
		effective3: "Muvaffaqiyatga erishgan o`quvchilar",
		documents: "Me'yoriy hujjatlar",
		psychCounseling: "Psixologik maslahat",
		contact: "Aloqa",
		sendMessage: "Murojaat qilish",
		fullName: "Ism sharifingiz",
		subject: "Mavzu",
		messageContent: "Qisqacha mazmuni",
		contactDetails: "Aloqa uchun",
		workingHours: "Ish vaqti",
		address: "Manzil",
		phoneNumber: "Telefon raqam",
		help: "Yordam",
		logout: "Chiqish",
		login: "Kirish",
		pupilName: "O`quvchining F.I.Sh",
		teacherName: "O`qituvchining F.I.Sh",
		testMethodName: "Metodika nomi",
		dateOfTest: "O`tkazilgan sana",
		testResult: "Test natijasi",
        ofText: "dan",
		pageText: "Sahifa",
		nextText: "Keyingi",
		previousText: "Oldingi",
		noDataText: "Ma'lumotlar yo`q",
		rowsText: ",qator",
		pupil: "O`quvchi",
		method: "Metodika",
		finish: "Yakunlash",
		save: "Saqlash",
		question: "Savol",
		peopleInitial: "F.I.Sh",
		region: "Hudud",
		districtName: "Tumani",
		schoolName: "Maktabi",
		dateOfBirth: "Tug`ilgan vaqti",
		teacher: "O`qituvchi",
		testDate: "Sana va vaqt",
		lastName: "Familiyasi",
		firstName: "Ismi",
		middleName: "Sharifi",
		action: "Amal",
		edit: "Tahrirlash",
		delete: "O`chirish",
		language: "Ta'lim tili",
		birthDate: "Tug`ilgan sanasi",
		survey: "So`rovnoma",
		klass: "Sinfi",
		uznay: "haqida ma'lumotlarga ega bo'ling ! ",
		profession: "Kasb tiplari",
		fullDescription: "Batafsil ma'lumot",
		ourHelp1: "KASB TANLASHDA SIZLARGA YORDAM BERAMIZ",
		ourHelp2: "QULAY, OSON, SAMARALI",
		ourHelp2_1: " USULLAR ORQALI",
		ourHelp3: "Kim bo'lsam ekan ?",
		ourHelp4: "qaysi kasb yaxshi ?",
		ourHelp5: "Savollardan charchadingizmi ?",
		rtm1: "O‘quvchilarni kasb-hunarga yo‘naltirish va psixologik-pedagogik",
		rtm2: "respublika tashxis markazi sizlarga testlar orqali yordam beradi",
		aboutUs: "Biz haqimizda",
		rtm3: "O‘quvchilarni kasb-hunarga yo‘naltirish va psixologik-pedagogik respublika tashxis markazi",
		effectiveMens: "Muvaffaqiyatli insonlar tajribasi haqida",
		effectiveMens2: "Muvaffaqiyatli insonlar",
		effectivePupils: "MUVAFFAQIYATGA ERISHGAN O`QUVCHILAR HAQIDA",
		profType1: "Odam-odam",
		profType2: "Odam-tabiat",
		profType3: "Odam-texnika",
		profType4: "Odam-belgilar tizimi",
		profType5: "Odam-badiiy obraz",
		profType6: "Zamonaviy kasblar",
		usefulResources: "Foydali Resurslar",
		govSites: "Davlat tashkilotlarining rasmiy web saytlari",
		registration: "Ro'yxatdan o'tish",
		videos: "Videolar",
		profTypes: "Kasb turlari",
		allResources: "Barcha resurslar",
		allResourcesStat: "Tizimda mavjud resurslarning umumiy statistikasi",
		testCount: "Testlar soni",
		testCountAboutProf: "Kasb-hunarga doir testlarning umumiy soni",
		participants: "Qatnashuvchilar",
		testedParticipants: "Tizimda o'zini sinab ko'rgan foydalanuvchilar soni",
		playVideo: "Videoni ko'rish",
		ourAddress: "Toshkent shahri Olmazor tumani Ziyo ko`chasi 6a uy",
		media: "MEDIA",
		datas: "Ma'lumotlar",
		sections: "Yo'nalish bo'yicha bo'limlar",
		workingContent: "ISH FAOLIYATINING MAZMUNI VA ISH SHARTI",
		professionPresentation: "Kasbning taqdimoti",
		professionType: "Kasb turi",
		professionContent: "Ish faoliyatining mazmuni",
		professionTerms: "Ish sharti",
		professionRequirements: "Mutaxassisning bilim va ko`nikmalariga qo`yiladigan talablar",
		individualRequirements: "Mutaxassisning individual xususiyatlariga qo`yiladigan talablar",
		medicalRestrictions: "Tibbiy moneliklar",
		waysToGetProfession: "Kasb egallash yo`llari",
		scopeOfTheProfession: "Kasbning qo`llanilish sohasi",
		relatedProfessions: "Turdosh kasblar",
		growthProspects: "Kasbiy o`sish istiqbollari",
		progName: "KASBIM-KELAJAGIM",
		welcome: "AZIZ O‘QUVCHI, “KASBIM-KELAJAGIM” ONLAYN PLATFORMASIGA XUSH KELIBSIZ !",
		titleHeadStr: "AGAR TANLANGAN KASB SEVIMLI BO‘LSA, INSON BIR UMR HAYOTIDAN ZAVQLANIB YASHAYDI",
		aboutProgDesc: "Kasblar to‘g‘risida yetarli ma’lumotga ega bo‘lmaslik, o‘z imkoniyatlarini to‘g‘ri baholay olmaslik va o‘z qobiliyatlarini amaliyotga " +
			"to‘liq tatbiq eta olmaslik Sizlarda kasb tanlashdagi anchagina qiyinchiliklarni tug‘diradi. O‘quvchilarni kasb-hunarga yo‘naltirish va psixologik pedagogik " +
			"respublika tashxis markazi tomonidan umumiy o‘rta ta’lim muassasalarida ta’lim olayotgan o‘quvchilarning tug‘ma kasbiy qiziqishi, moyilligi va " +
			"layoqatini aniqlash maqsadida “Kasbim-kelajagim” deb nomlangan yagona onlayn platforma yaratildi. " +
			"Ushbu platforma orqali o‘quvchi o‘z qiziqishlari va qobiliyatlarini aniqlay oladi. O‘z imkoniyatlarini anglagan holda ongli ravishda kelajakdagi kasb " +
			"haqida tasavvurga ega bo‘ladi. Maktabda ta’lim olish jarayonida turli xil to‘garaklarni, sport va musiqa maktablarini tanlaydi. Maktabni tugatgach kasb tanlashi oson kechadi.",
		advantages: "ONLAYN PLATFORMA AFZALLIKLARI",
		advantage1: "Aziz o‘quvchi! ONLAYN PLATFORMA bilan bog‘lanish uchun siz biron manzilga borishingiz shart emas, internet tarmog`iga ulangan kompyuter bo‘lsa kifoya.",
		advantage2: "ONLAYN PLATFORMADA sizning qaysi kasbga qiziqishingiz, moyilligingiz va layoqatingiz borligini aniqlanadi. Bunda Siz uchun maxsus metodikalar orqali onlayn test sinovlari tashkil etiladi.",
		advantage3: "Test sinovlari umumiy o‘rta ta’lim maktabining 7-8-9-10-11 –sinf o‘quvchilari uchun mo‘ljallangan bo‘lib, bu test natijalari yordamida har bir o‘quvchining yoshi, bilim darajasi, qiziqishlari, qobiliyati, moyilliklari va imkoniyatlariga muvofiq kasb-hunarga yo‘naltiriladi:",
		advantage4: "Test sinovlarini o‘zingizga qulay bo‘lgan vaqtda olishingiz mumkin.",
		advantage5: "Test natijalari sinovdan so‘ng darhol qabul qilinadi.",
		advantage6: "Respublikamizda mavjud kasblar haqida ma’lumot beruvchi infografika ko‘rinishida elektron “Professiogramma”lar orqali o‘quvchilarga respublikamizda mavjud kasblar to‘g‘risida batafsil ma’lumotlar berib boriladi.",
		advantage7: "“Kasb egallash” bo‘limi orqali Respublikamizda mavjud oliy ta’lim muassasalari, akademik litseylar, kasb-hunar maktablari, kollejlar va texnikumlar to‘g‘risidagi ma’lumotlar (dislokatsiya) bilan tanishishingiz mumkin.",
		advantage8: "Siz qiziqqan ta’lim muassasalari haqidagi barcha ma’lumotlarni bilib olasiz va kerak bo‘lsa psixologlar sizga foydali maslahatlarni beradilar.",
		advantage9: "“Muvaffaqiyatga erishgan insonlar” rukni orqali esa bugungi kunda o‘z qobiliyati bilan muvaffaqiyatga erishgan o‘quvchi-yoshlar va faxriy insonlarning kasbiy tajribalari bilan tanishishlaringiz mumkin.",
		advantage10: "Dasturning “Psixologik maslahat” rukni orqali psixologlar tomonidan maslahatlar berib boriladi.",
		advantage11: "Mutaxassis bilan telegramm yoki elektron pochta orqali sizni qiziqtirgan barcha savollarni muhokama qilishingiz va to‘liq javob, tavsiyalar olishingiz mumkin. Bundan tashqari barcha oila a’zolaringiz ham o‘zlarini qiziqtirgan savollariga javob olishlari mumkin.",
		advantage12: "Biz sizlarning orzu-maqsadlaringizni qadrlaymiz, qiziqishlaringiz va qobiliyatlaringizni hurmat qilamiz. Har doim sizlarga har qanday yordam berishga harakat qilamiz, maslahatlar beramiz.",
		advantage13: "SIZGA KELAJAKA SEVIMLI KASBINGIZNI EGALLASHINGIZDA OMAD TILAYMIZ",
		advantage14: "Bu platformada o‘quvchilar qaysi kasbga qiziqishi, moyilligi va layoqati borligni aniqlash bo‘yicha \"Differentsial-diagnostik so'rovnoma\" , G.Gardnerning \"Intelektual qobilyatni aniqlash\" metodikasi va psixolog A.YE Golomshtok tomonidan yaratilgan \"Qiziqishlar xaritasi\" (psixologiya f.n Y.M. Asadov tomonidan modifikatsiya qilingan) metodikalar orqali onlayn test sinovlari tashkil etiladi.",
		psychHelp: "Psixologik yordam",
		downloadResult: "NATIJANI YUKLAB OLISH",
		yourResult: "Sizning natijangiz:",
		pleaseSelectAnswer: "Iltimos, javoblardan birini tanlang !",
        pupilList: "O`quvchilar ro`yxati",
        testLanguage: "Qaysi tilda",
		klass5: "5-sinf",
		klass6: "6-sinf",
		klass7: "7-sinf",
		klass8: "8-sinf",
		klass9: "9-sinf",
		klass10: "10-sinf",
		klass11: "11-sinf",
		previous: "Avvalgi",
		next: "Keyingi",
		startTest: "Testni boshlash",
		method1: "1-METODIKA: ",
		method2: "2-METODIKA: ",
		method3: "3-METODIKA: ",
		method4: "4-METODIKA: ",
		purpose: "Maqsadi",
		participationInTheTest: "Test sinovida qatnashish",
		occupationMonth: "Kasblar oyligi",
        entrepreneurship: "Tadbirkorlik",
        businessPlan: "Biznes reja",
		uzedu: "Xalq Ta'limi Vazirligi",
		edu: "Oliy va o`rta maxsus ta'lim vazirligi",
		taqvim: "Respublika Bolalar Kutubxonasi",
		constitution: "O`zbekiston Respublika Konstitutsiyasi",
		home: "Asosiy",
		website: "Web sayt",
		otm: "Oliy ta’lim muassasasi",
		texnikum: "Texnikumlar",
		khk: "Kollejlar",
		khm: "Kasb hunar maktabi",
		litsey: "Akademik litsey",
		getProfession: "Kasb egallash",
		profession1: "Kasb ",
		profession2: "egallash",
		download: "Yuklab olish",
		documentsType1: "Ta'lim to`g`risidagi qonunlar",
		documentsType2: "Prezidentning farmonlari, farmoyishlari va qarorlari",
		documentsType3: "Vazirlar Mahkamasi qarorlari",
		documentsType4: "Xalq Ta'limi Vazirligi me'yoriy hujjatlari",
		documentsType5: "Respublika Tashxis Markazi me'yoriy hujjatlari",
		kasbim: "KASBIM",
		kelajagim: "KELAJAGIM",
		pleaseSelect: "Tanlang",
		testResults: "Test natijalari",
		psychTestResults: "Psixologik test natijalari",
        selectDate: "Sanani tanlang",
		exportToExcel: "Excelga eksport qilish",
		exportToPDF: "PDFga eksport qilish",
        surveyReports: "So`rovnoma hisoboti",
	},
	ru: {
		back: "Назад",
		homePage: "Главная",
		aboutProgram: "О программе",
		tests: "Тесты",
		professionWorld: "Мир профессий",
		professionWorld2: "Овладение профессией",
		effective: "Успешные люди",
		effective2: "Опыт успешных людей",
		effective3: "Успешные ученики",
		documents: "Нормативные документы",
		psychCounseling: "Психологическое консультирование",
		contact: "Обратная связь",
		sendMessage: "Отправить сообщения",
		fullName: "ФИО отправителя",
		subject: "Тема",
		messageContent: "Сообщения",
		contactDetails: "Контактные данные",
		workingHours: "Рабочие часы",
		address: "Адрес",
		phoneNumber: "Номер телефона",
		help: "Помощь",
		logout: "Выйти",
		login: "Войти",
		pupilName: "Ф.И.О ученика",
		teacherName: "Ф.И.О учителя",
		testMethodName: "Названия метода",
		dateOfTest: "Дата проведения",
		testResult: "Результат теста",
        ofText: "от",
		pageText: "Страница",
		nextText: "Следующая",
		previousText: "Предыдущая",
		noDataText: "Нет данных",
		rowsText: "строки",
		pupil: "Ученик",
		method: "Методика",
		finish: "Заканчивать",
		question: "Вопрос",
		save: "Сохранить",
		peopleInitial: "Ф.И.О",
		region: "Область",
		districtName: "Район",
		schoolName: "Школа",
		dateOfBirth: "Дата рождения",
		teacher: "Учитель",
		testDate: "Дата и время",
		lastName: "Фамилия",
		firstName: "Имя",
		middleName: "Отчество",
		action: "Действия",
		edit: "Редактировать",
		delete: "Удалить",
		language: "Язык обучения",
		birthDate: "День рождения",
		survey: "Опросник",
		klass: "Класс",
		fullDescription: "Подробно",
		ourHelp1: "МЫ ПОМОЖЕМ ВАМ В ВЫБОРЕ КАРЬЕРЫ",
		ourHelp2: "УДОБНЫЕ, ЛЕГКИЕ,",
		ourHelp2_1: " ЭФФЕКТИВНЫЕ МЕТОДЫ",
		ourHelp3: "Кем стать?",
		ourHelp4: "Какая профессия лучше?",
		ourHelp5: "Устали от вопросов?",
		rtm1: "Республиканский центр профессиональной ориентации и психолого-педагогической ",
		rtm2: "диагностики учащихся будет оказывать вам помощь используя методы тестирования",
		aboutUs: "О нас",
		rtm3: "Республиканский центр профессиональной ориентации и психолого-педагогической диагностики учащихся",
		uznay: "Узнать о ",
		profession: "Профессии",
		effectiveMens: "Об опыте успешных людей",
		effectiveMens2: "Успешные люди",
		effectivePupils: "ОБ УСПЕШНЫХ УЧАЩИХСЯ",
		usefulResources: "Полезные Ресурсы",
		govSites: "Официальные сайты государственных органов",
		profType1: "Человек-Человек",
		profType2: "Человек-Природа",
		profType3: "Человек-Техника",
		profType4: "Человек-Знаковая система",
		profType5: "Человек-Художественный образ",
		profType6: "Современные профессии",
		registration: "Регистрация",
		videos: "Видео",
		profTypes: "Типы профессии",
		allResources: "Все ресурсы",
		allResourcesStat: "Общая статистика ресурсов, доступных в системе",
		testCount: "Количество тестов",
		testCountAboutProf: "Общее количество тестов о профессии",
		participants: "Участников",
		testedParticipants: "Количество пользователей, которые протестировали себя в системе",
		playVideo: "Смотреть видео",
		ourAddress: "г. Ташкент Алмазарский район ул. Зиё д. 6a",
		media: "МЕДИА",
		datas: "Данные",
		sections: "Разделы по направлениям",
		workingContent: "СОДЕРЖАНИЕ И УСЛОВИЯ РАБОТЫ",
		professionPresentation: "Презентация профессии",
		professionType: "Тип профессии",
		professionContent: "Содержание деловой активности",
		professionTerms: "Условия труда",
		professionRequirements: "Требования к знаниям и умениям специалиста",
		individualRequirements: "Требования к индивидуальным характеристикам специалиста",
		medicalRestrictions: "Медицинские противопоказания",
		waysToGetProfession: "Способы устроиться на работу",
		scopeOfTheProfession: "Сфера профессии",
		relatedProfessions: "Родственные профессии",
		growthProspects: "Перспективы профессионального роста",
		progName: "МОЯ ПРОФЕССИЯ-МОЕ БУДУЩЕЕ",
		welcome: "ДОРОГОЙ УЧЕНИК, ДОБРО ПОЖАЛОВАТЬ НА ОНЛАЙН-ПЛАТФОРМУ \"МОЯ ПРОФЕССИЯ-МОЕ БУДУЩЕЕ\"",
		titleHeadStr: "ЕСЛИ ВЫБРАННАЯ ПРОФЕССИЯ ЛЮБИМАЯ, ТО ЧЕЛОВЕК БУДЕТ НАСЛАЖДАТЬСЯ ЕЮ ДО КОНЦА ЖИЗНИ.",
		aboutProgDesc: "Отсутствие достаточной информации о профессиях, невозможность правильно оценить свои возможности и  реализовать в полной мере свои способности на практике создадут для вас немало трудностей в выборе профессии. " +
			"Республиканский центр профессиональной ориентации и психолого-педагогической диагностики учащихся создал единую онлайн-платформу под названием “Моя профессия-мое будущее\" для выявления врожденных профессиональных интересов, предрасположенности и способностей учащихся, обучающихся в общеобразовательных учреждениях. " +
			"Через данную  платформу учащийся сможет определить свои интересы и способности.  Осознавая свои возможности он сознательно сможет составить представление о своей будущей профессии. В процессе обучения в школе он сможет выбрать различные кружки, такие как спортивные и музыкальные. " +
			"После окончания школы ему будет легко выбрать себе профессию. " +
			"Онлайн-платформа поможет составить план действий выпускника школы при выборе профессии и применить свои возможности на практике.",
		advantages: "ПРЕИМУЩЕСТВА ОНЛАЙН-ПЛАТФОРМЫ",
		advantage1: "Дорогой ученик! Чтобы соединиться с онлайн-платформой вам не нужно добираться до кого-то адреса, достаточно, того, что у вас есть компьютер, подключенный к интернету.",
		advantage2: "Дорогой ученик! Чтобы соединиться с онлайн-платформой вам не нужно добираться до кого-то адреса, достаточно, того, что у вас есть компьютер, подключенный к интернету.",
		advantage3: "Онлайн-платформа определит наличие вашего интереса и склонности к какой либо профессии. Для этого вам предложат онлайн-тестирование по специальным методикам. Тестовые вопросы предназначены для учащихся 7-8-9-10-11 классов средних общеобразовательных школ, с помощью которых каждый ученик сможет определить свой интерес на профессию в соответствии с его возрастом, уровнем знаний, способностями, склонностями и возможностями:",
		advantage4: "Вы можете пройти тестирование в удобное для вас время.",
		advantage5: "Результаты теста выдаются сразу после тестирования.",
		advantage6: "Учащимся будут предоставлена подробная информация о существующих профессиях в Республике через электронные «Профессиограммы» в виде инфографики, которые предоставляют информацию о профессиях в нашей стране.",
		advantage7: "С информацией (дислокацией) о высших образовательных учреждениях, академических лицеях, профессиональных школах, колледжах и техникумах Республики вы можете ознакомиться в разделе \"Овладение профессией\".",
		advantage8: "Вы получите всю информацию об интересующих вас образовательных учреждениях, а при необходимости психологи дадут вам полезные советы.",
		advantage9: "А в разделе \"Люди, достигшие успеха\" вы сможете ознакомиться с профессиональным опытом молодежи и людей - ветеранов труда, добившихся успеха благодаря своим способностям.",
		advantage10: "В разделе программы “Психологическая консультация” предлагаются советы психологов.",
		advantage11: "Вы можете обсудить со специалистом все интересующие вас вопросы в Telegram или по электронной почте и получить исчерпывающие ответы, рекомендации. Кроме того, все члены вашей семьи также могут получить ответы на интересующие их вопросы.",
		advantage12: "Мы ценим ваши мечты, уважаем ваши интересы и способности. Мы всегда стараемся помочь вам, даем необходимые советы.",
		advantage13: "ЖЕЛАЕМ УДАЧИ В ВЫБОРЕ ЛЮБИМОЙ ПРОФЕССИИ В БУДУЩЕМ",
		advantage14: "На этой платформе есть «Дифференциально-диагностический опросник» для определения того, какой профессией интересуются ученики, их склонности и способности, метод Г. Гарднера «Определение интеллектуальных способностей» и «Карта интересов», созданная психологом А.Е. Голомштоком (В редакции к.н психологии Асадова Ю.М.) онлайн-тестовые запуски организованы с использованием методик.",
		psychHelp: "Психологическая помощь",
		downloadResult: "СКАЧАТЬ РЕЗУЛЬТАТ",
		yourResult: "Ваш результат:",
		pleaseSelectAnswer: "Пожалуйста, выберите один из ответов!",
        pupilList: "Список учеников",
		testLanguage: "Выберите язык",
		klass5: "5-класс",
		klass6: "6-класс",
		klass7: "7-класс",
		klass8: "8-класс",
		klass9: "9-класс",
		klass10: "10-класс",
		klass11: "11-класс",
		previous: "Предыдущий",
		next: "Следующий",
		startTest: "Начать тест",
		tests2: "Тестовые задания",
        test9: "Опросник 9-го класса",
        test11: "Опросник 11-го класса",
        iqTest: "IQ тест",
		method1: "1-МЕТОДИКА: ",
		method2: "2-МЕТОДИКА: ",
		method3: "3-МЕТОДИКА: ",
		method4: "4-МЕТОДИКА: ",
		purpose: "Цель",
		participationInTheTest: "Участие в тесте",
		occupationMonth: "Месячник профессий",
        entrepreneurship: "Предпринимательство",
        businessPlan: "Бизнес план",
		uzedu: "Министерство народного образования",
		edu: "Министерство высшего и среднего специального образования",
		taqvim: "Республиканская детская библотека",
		constitution: "Конституция Республики Узбекистан",
		home: "Главная",
		website: "Web сайт",
		otm: "Высшие учебные заведения",
		texnikum: "Техникумы",
		khk: "Колледжи",
		khm: "Профессиональная школа",
		litsey: "Академический лицей",
		getProfession: "Овладение профессией",
		profession1: "Овладение ",
		profession2: "профессией",
		download: "Загрузить",
		documentsType1: "Законы об образовании",
		documentsType2: "Указы, постановления и решения Президента",
		documentsType3: "Решения Кабинета Министров",
		documentsType4: "Нормативные документы Министерство Народного Образования",
		documentsType5: "Нормативные документы Центра психолого-педагогической диагностики",
		kasbim: "МОЯ ПРОФЕССИЯ",
		kelajagim: "МОЕ БУДУЩЕЕ",
		pleaseSelect: "Выберите",
        testResults: "Результаты теста",
        psychTestResults: "Результаты психологического теста",
        selectDate: "Выберите дату",
    	exportToExcel: "Экспорт в Excel",
    	exportToPDF: "Экспорт в PDF",
    	surveyReports: "Отчеты опросника",
	}});

export function getRegions(language) {
	return language === 'uz' ? [<option key="r0" value="0">Tanlang</option>,<option key="r1" value="1">Qoraqalpog`iston Respublikasi</option>,<option key="r2" value="2">Andijon viloyati</option>,<option key="r3" value="3">Buxoro viloyati</option>,
		<option key="r4" value="4">Qashqadaryo viloyati</option>,<option value="5" key="r5">Jizzax viloyati</option>,<option value="6" key="r6">Navoiy viloyati</option>,<option value="7" key="r7">Namangan viloyati</option>,
		<option value="8" key="r8">Samarqand viloyati</option>,<option value="9" key="r9">Sirdaryo viloyati</option>,<option value="10" key="r10">Surxondaryo viloyati</option>,<option value="11" key="r11">Toshkent viloyati</option>,
		<option value="12" key="r12">Farg`ona viloyati</option>,<option value="13" key="r13">Xorazm viloyati</option>,<option value="14" key="r14">Toshkent shahri</option>] :
		[<option value="0" key="r0">Выберите</option>,<option value="1" key="r1">Республика Каракалпакстан</option>,<option value="2" key="r2">Андижанская область</option>,<option value="3" key="r3">Бухарская область</option>,
		<option value="4" key="r4">Кашкадарьинская область</option>,<option value="5" key="r5">Джизакская область</option>,<option value="6" key="r6">Навоийская область</option>,<option value="7" key="r7">Наманганская область</option>,
		<option value="8" key="r8">Самаркандская область</option>,<option value="9" key="r9">Сырдарьинская область</option>,<option value="10" key="r10">Сурхандарьинская область</option>,<option value="11" key="r11">Ташкентская область</option>,
		<option value="12" key="r12">Ферганская область</option>,<option value="13" key="r13">Хорезмская область</option>,<option value="14" key="r14">город Ташкент</option>];
}

export function formatReportDate() {
	let currentDate = new Date();
	let month = '';
	switch (currentDate.getMonth()) {
		case 0: month = 'yanvar'; break;
		case 1: month = 'fevral'; break;
		case 2: month = 'mart'; break;
		case 3: month = 'aprel'; break;
		case 4: month = 'may'; break;
		case 5: month = 'iyun'; break;
		case 6: month = 'iyul'; break;
		case 7: month = 'avgust'; break;
		case 8: month = 'sentabr'; break;
		case 9: month = 'oktabr'; break;
		case 10: month = 'noyabr'; break;
		case 11: month = 'dekabr'; break;
		default: month = ''; break;
	}
	return currentDate.getFullYear() + '-yil ' + currentDate.getDate() + ' ' + month + ' holatiga';
}

export function checkValid(value) {
    return value !== undefined && value !== null && value !== '';
}

export function checkNumberValid(fieldValue) {
    return fieldValue !== undefined && fieldValue !== null && fieldValue !== '' && Number(fieldValue) > 0;
}

export function checkDateValid(fieldValue) {
    return fieldValue !== undefined && fieldValue !== null && fieldValue !== '' && fieldValue > 0;
}

export function validateField(fieldId, fieldValue, errors) {
    if (!checkValid(fieldValue)) {
        document.getElementById(fieldId).style.boxShadow = "0px 0px 5px 5px red";
        errors++;
    }
    return errors;
}

export function inputValueChanged(e) {
    let valid = checkValid(e.target.value);
    if (valid) {
        e.target.style.boxShadow = "none";
    } else {
        e.target.style.boxShadow = "0px 0px 5px 5px red";
    }
}

export function validateSelect(fieldId, errors) {
    let fieldVal = document.getElementById(fieldId).value;
    if (fieldVal === undefined || fieldVal === null || fieldVal === '' || Number(fieldVal) === 0) {
        document.getElementById(fieldId).style.boxShadow = "0px 0px 5px 5px red";
        errors++;
    }
    return errors;
}

export function selectValueChanged(e) {
    if (checkNumberValid(e.target.value)) {
        e.target.style.boxShadow = "none";
    } else {
        e.target.style.boxShadow = "0px 0px 5px 5px red";
    }
}

export function redirectToLogin() {
    window.open('/', '_self');
}

export function logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('sf');
    window.open('/', '_self');
}

export function getKey() {
    return Math.random() * 100000000000000000;
}