import React, {Component} from 'react';
import {strings} from './Utils';
import {NavLink, withRouter} from "react-router-dom";

class EntrepreneurshipView extends Component {

    render() {
        return(
            <div>
                <div className="form-row mt-5 mb-5 ml-2 mr-2">
                    <div className="col-md-12">
                        <fieldset style={{border: "1px solid black"}}>
                            <legend style={{width: "auto", marginLeft: "20px"}}>{strings.businessPlan}</legend>
                            <div className="form-row">
                                <div className="col-md-4 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/1.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/1.pdf" target="blank" className="ml-2 mr-2 link-style">{this.props.language === 'uz' ? 'Biznes reja tuzish' : 'Создание бизнес-плана'}</a>
                                </div>
                                <div className="col-md-4 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/2.png) no-repeat center"}}/>
                                    <a href="entrepreneurship/2.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Biznes reja tuzish' : 'Создание бизнес-плана'}</a>
                                </div>
                                <div className="col-md-4 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/3.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/3.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Biznes reja tuzish bo`yicha misollar №1' : 'Примеры бизнес-планирования №1'}</a>
                                </div>
                            </div>
                            <div className="form-row mt-2">
                                <div className="col-md-4 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/3.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/Sam.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Biznes reja tuzish bo`yicha misollar №2' : 'Примеры бизнес-планирования №2'}</a>
                                </div>
                                <div className="col-md-4 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/3.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/TMZ.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Biznes reja tuzish bo`yicha misollar №3' : 'Примеры бизнес-планирования №3'}</a>
                                </div>
                                <div className="col-md-4 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/3.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/UzExp.pdf" target="blank" className="ml-2 mr-2 link-style">{this.props.language === 'uz' ? 'Biznes reja tuzish bo`yicha misollar №1' : 'Примеры бизнес-планирования №4'}</a>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="form-row mb-5 ml-2 mr-2">
                    <div className="col-md-12">
                        <fieldset style={{border: "1px solid black"}}>
                            <legend style={{width: "auto", marginLeft: "20px"}}>{this.props.language === 'uz' ? 'Tadbirkorlik sohalari uchun biznes rejalarga misollar' : 'Примеры бизнес-планов для секторов бизнеса'}</legend>
                            <div className="form-row">
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/4.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/alyumin_eshik_rom.pdf" target="blank" className="ml-2 mr-2 link-style">{this.props.language === 'uz' ? 'Alyuminiy eshik-rom yasash' : 'Изготовление алюминиевой двери и рамки'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/5.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/asalarichilik.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Asalarichilik' : 'Пчеловодство'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/6.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/audio_video_tex_tamirlash.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Audio, video texnikalarini ta\'mirlash' : 'Ремонт аудио и видео техники'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/7.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/baliqchilik.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Baliqchilik' : 'Рыбная ловля'}</a>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/8.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/bogdorchilik.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Bog`dorchilik' : 'Садоводство'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/9.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/chorvachilik.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Chorvachilik' : 'Животноводство'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/10.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/guzallik_saloni.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Go`zallik saloni' : 'Салон красоты'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/11.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/issiqxona.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Issiqxona' : 'Теплица'}</a>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/12.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/kino_mus.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Kichik kinoteatr xizmati ko`rsatish' : 'Малый кинотеатр'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/13.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/kosibchilik.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Kosibchilik' : 'Сапожник'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/14.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/kutubxona_tashkil_qilish.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Kutubxona tashkil qilish' : 'Создание библиотеки'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/15.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/mebel_bp.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Mebel tsexini tashkil qilish' : 'Создание мебельный цех'}</a>
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/16.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/metal_tunadan_buyum_tayyorlash.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? '"GANCH O`YMAKORLIGI" yo`nalishi bo`yicha metaldan turli ko`rinishdagi buyumlar ishlab chiqarishni tashkil qilish' : 'Организация производства различных видов металлоизделий по направлению "GANCH O`YMAKORLIGI"'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/17.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/non_maxsulotlari_ishlab_chiqarish.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Non mahsulotlari ishlab chiqarish' : 'Производство хлебобулочных изделий'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/18.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/parrandachilik.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Parrandachilik' : 'Домашняя птица'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/19.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/qandolat_maxsulotlari.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Qandolat mahsulotlarini ishlab chiqarish' : 'Производство кондитерских изделий'}</a>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/20.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/quyonchilik.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Quyonchilik' : 'Кролик'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/21.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/sport_maydoncha_tashkil_qilish.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Sport maydonchasi tashkil qilish' : 'Создание спортивной площадки'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/22.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/tikuvchilik.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Tikuvchilik' : 'Шитье'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/23.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/un_ishlab_chiqarish.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Un ishlab chiqarish' : 'Производство муки'}</a>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/24.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/uquv_markaz.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'O`quv markaz' : 'Учебный центр'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/25.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/veterenariya.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Veterinariya xizmatini tashkil qilish' : 'Организация ветеринарных услуг'}</a>
                                </div>
                                <div className="col-md-3 text-center">
                                    <div className="bp-box" style={{background: "url(images/bp/26.jpg) no-repeat center"}}/>
                                    <a href="entrepreneurship/examples/xammom_faoliyati.pdf" target="blank" className="mr-2 link-style">{this.props.language === 'uz' ? 'Hammom faoliyatini tashkil qilish' : 'Hammom faoliyatini tashkil qilish'}</a>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset style={{border: "1px solid black"}}>
                            <legend style={{width: "auto", marginLeft: "20px"}}>Videolar</legend>
                            <div className="form-row">
                                <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                                    <div className="card card-margin">
                                        <img className="card-img-top" src="images/e27.jpg" alt="" />
                                        <div className="card-body text-center">
                                            <NavLink to="/media?src=e27" className="btn">{strings.playVideo}</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                                    <div className="card card-margin">
                                        <img className="card-img-top" src="images/e28.jpg" alt="" />
                                        <div className="card-body text-center">
                                            <NavLink to="/media?src=e28" className="btn">{strings.playVideo}</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                                    <div className="card card-margin">
                                        <img className="card-img-top" src="images/e29.jpg" alt="" />
                                        <div className="card-body text-center">
                                            <NavLink to="/media?src=e29" className="btn">{strings.playVideo}</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                                    <div className="card card-margin">
                                        <img className="card-img-top" src="images/e30.jpg" alt="" />
                                        <div className="card-body text-center">
                                            <NavLink to="/media?src=e30" className="btn">{strings.playVideo}</NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                                    <div className="card card-margin">
                                        <img className="card-img-top" src="images/e31.jpg" alt="" />
                                        <div className="card-body text-center">
                                            <NavLink to="/media?src=e31" className="btn">{strings.playVideo}</NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(EntrepreneurshipView);