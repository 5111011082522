import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import {apiURL} from "./Utils";
import 'react-responsive-modal/styles.css';
import Modal from "react-responsive-modal";
import AddEffectivePeopleView from "./AddEffectivePeopleView";

export default class EffectivePeopleListView extends Component {

    constructor() {
        super();
        this.state = {
            tableRows: [],
            modalOpen: false
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        axios.get(apiURL + '/videos/effectivePeople/list').then(result => {
            if (result.data.statusCode === 200) {
                let options = [];
                if (result.data.data !== undefined && result.data.data !== null) {
                    for (let i = 0; i < result.data.data.length; i++) {
                        let row = result.data.data[i];
                        options.push(<tr key={row.id}>
                            <td align="center">{row.id}</td>
                            <td>{row.name}</td>
                            <td align="center">
                                <button className="btn btn-danger" onClick={this.deleteEffectivePeople.bind(this, row.id)}>
                                    O`chirish
                                </button>
                            </td>
                        </tr>);
                    }
                }
                this.setState({
                    tableRows: options
                })
            } else {
                this.setState({
                    tableRows: []
                })
            }
        })
    }

    deleteEffectivePeople(id) {
        axios.post(apiURL + '/videos/effectivePeople/delete?id=' + id).then(result => {
            if (result.data.statusCode === 200) {
                this.loadData();
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    }

    openModal() {
        this.setState({
            modalOpen: true
        })
    }

    closeModal() {
        this.setState({
            modalOpen: false
        })
    }

    render() {
        return (
            <div className="card card-body my-3 shadow-sm">
                <div className="form-row">
                    <div className="col-md-12 mb-2">
                        <button className="btn btn-info" onClick={this.openModal.bind(this)}>
                            <i className="fa fa-plus-square"/> Qo`shish</button>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12">
                        <table className="table table-striped table-bordered shadow">
                            <thead>
                            <tr>
                                <th scope="col">№</th>
                                <th scope="col">Nomi</th>
                                <th scope="col">Amal</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.tableRows}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Modal open={this.state.modalOpen} onClose={this.closeModal.bind(this)} center>
                    <AddEffectivePeopleView/>
                </Modal>
            </div>
        )
    }
}