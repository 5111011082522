import React, {Component} from 'react';
import {strings, apiURL, getRegions, getKey} from "./Utils";
import axios from 'axios';
import Swal from 'sweetalert2';

export default class IQTestView extends Component {

    constructor() {
        super();
        this.state = {
            klass: localStorage.getItem('klass'),
            pupilId: '',
            districts: [],
            schools: [],
            pupils: [],
            questions: [],
            question: '',
            questionIndex: 0,
            answers: [],
            pupilAnswers: [],
            language: 'uz',
            isStartView: true,
            isTestView: false,
            showFinishButton: false,
            header1: '',
            header2: '',

            header1_5_uz: ['1-Topshiriq: Tushuncha', '2-Topshiriq: Idrok qilish'],
            header1_5_ru: ['Задание-1: Понятие', 'Задание-2: Восприятие'],

            header1_6_uz: ['1-Topshiriq: Taqqoslash va umumlashtirish', '2-Topshiriq: O‘xshashlik', '3-Topshiriq: Idrok qilish'],
            header1_6_ru: ['Задание-1: Сравнение и обобщение', 'Задание-2: Сходства', 'Задание-3: Восприятие'],

            header1_7_uz: ['1-Topshiriq: Tushuncha', '2-Topshiriq: Mantiqiy fikrlash. So‘roq belgisi o‘rniga to‘g‘ri javobni qo‘ying', '3-Topshiriq: Idrok'],
            header1_7_ru: ['Задание-1: Обобщение', 'Задание-2: Логическое мышление', 'Задание-3: Восприятие'],

            header2_5_uz: [
                'Har bir qatordagi so‘zlarni o‘qing, o‘ylab ko‘rib, berilgan beshta so‘zdan ma’nosi to‘g‘ri kelmaydigan ortiqcha so‘zni toping.',
                'Har bir qatordagi so‘zlarni o‘qing, o‘ylab ko‘rib, berilgan beshta so‘zdan ma’nosi to‘g‘ri kelmaydigan ortiqcha so‘zni toping.'],
            header2_5_ru: [
                'Прочтите слова в каждой строке. Найдите и отметьте слово, которое  не соответствует по смыслу другим словам.',
                'Прочтите слова в каждой строке. Найдите и отметьте слово, которое  не соответствует по смыслу другим словам.'],

            header2_6_uz: [
                'Har bir qatordagi so‘zlarni o‘qing. O‘ylab ko‘rib, berilgan beshta so‘zdan ma’nosi to‘g‘ri kelmaydigan ortiqcha so‘zni toping.',
                'Birinchi qatordagi so‘zlarni  ko‘rib chiqib, birinchi juft so‘z orasida qanday mantiqiy aloqa borligini o‘ylab ko‘rib, uchinchi so‘zga mantiqan to‘g‘ri keladigan so‘zni belgilang.',
                'Matematik masalalarni diqqat bilan o‘qing va javobini toping.'],
            header2_6_ru: [
                'Прочтите слова в каждой строке. Подумайте  и найдите лишнее слово в данных пяти словах.',
                'Посмотрите на слова в первой строке, подумайте о логической связи между первой парой слов и отметьте слово, подходящее для третьего слова.',
                'Внимательно прочтите математические вопросы и найдите ответы.'],

            header2_7_uz: [
                'Beshta so‘zdan qaysi biri birinchi qatordagi 3-so‘zga mos?',
                'So‘roq belgisi o‘rniga to‘g‘ri javobni qo‘ying.', ''],
            header2_7_ru: [
                'Подумайте какое слово подходит к третьему слову?',
                'Замените вопросительный знак правильным ответом.', ''],
        }
    }

    selectAnswer(answer) {
        let questionIndex = this.state.questionIndex;
        let showFinishButton = this.state.showFinishButton;
        if (questionIndex < this.state.questions.length-1) {
            questionIndex += 1;
        } else {
            showFinishButton = true;
            questionIndex = 0;
        }
        let pupilAnswers = this.state.pupilAnswers;
        pupilAnswers[this.state.questionIndex] = answer.id;
        this.setState({
            questionIndex: questionIndex,
            pupilAnswers: pupilAnswers,
            showFinishButton: showFinishButton
        });
        this.loadNextQuestion(this.state.questions, questionIndex);
    }

    loadNextQuestion(questions, index) {
        let answers = questions[index].answers.map(answer => {
            if (answer.answer.startsWith('http')) {
                return <img key={getKey()} src={answer.answer} alt="" className="cursor-pointer" onClick={this.selectAnswer.bind(this, answer)}/>
            } else {
                return <div key={getKey()}>
                    <input type="radio" name="ans" id={"a_" + answer.id} onClick={this.selectAnswer.bind(this, answer)}/>
                    <label className="form-check-label ml-2" htmlFor={"a_" + answer.id}>{answer.answer}</label>
                </div>
            }
        });
        let split = questions[index].question.split('|');
        let header1 = '', header2 = '';
        if (this.state.language === 'uz') {
            switch (Number(this.state.klass)) {
                case 5:
                    header1 = this.state.header1_5_uz[Number(split[0])];
                    header2 = this.state.header2_5_uz[Number(split[0])];
                    break;
                case 6:
                    header1 = this.state.header1_6_uz[Number(split[0])];
                    header2 = this.state.header2_6_uz[Number(split[0])];
                    break;
                case 7:
                    header1 = this.state.header1_7_uz[Number(split[0])];
                    header2 = this.state.header2_7_uz[Number(split[0])];
                    break;
                default:
                    header1 = '';
                    header2 = '';
                    break;
            }
        } else {
            switch (Number(this.state.klass)) {
                case 5:
                    header1 = this.state.header1_5_ru[Number(split[0])];
                    header2 = this.state.header2_5_ru[Number(split[0])];
                    break;
                case 6:
                    header1 = this.state.header1_6_ru[Number(split[0])];
                    header2 = this.state.header2_6_ru[Number(split[0])];
                    break;
                case 7:
                    header1 = this.state.header1_7_ru[Number(split[0])];
                    header2 = this.state.header2_7_ru[Number(split[0])];
                    break;
                default:
                    header1 = '';
                    header2 = '';
                    break;
            }
        }

        let question = '';
        if (split[1].startsWith('<span>')) {
            question = <div dangerouslySetInnerHTML={{__html: split[1]}}/>
        } else {
            question = split[1]
        }
        this.setState({
            header1: header1,
            header2: header2,
            question: question,
            answers: answers
        });
    }

    loadDistricts() {
        let regions = document.getElementById('regions');
        axios.get(apiURL + "/pupil/districts?regionId=" + regions.value + '&language=' + (this.state.language === 'uz' ? 1 : 2)).then(result => {
            if (result.data.statusCode === 200) {
                let districts = result.data.data.map(data => {
                    return <option key={'d' + data.id} value={data.id}>{data.name}</option>;
                });
                districts.unshift(<option key={'d0'} value="0">{strings.pleaseSelect}</option>);
                this.setState({
                    districts: districts
                })
            }
        });
    }

    loadSchools() {
        let districts = document.getElementById('districts');
        axios.get(apiURL + "/pupil/schools?districtId=" + districts.value + '&language=' + (this.state.language === 'uz' ? 1 : 2)).then(result => {
            if (result.data.statusCode === 200) {
                let schools = result.data.data.map(data => {
                    return <option key={'sc' + data.id} value={data.id}>{data.name}</option>;
                });
                schools.unshift(<option key={'sc0'} value="0">{strings.pleaseSelect}</option>);
                this.setState({
                    schools: schools
                })
            }
        });
    }

    loadPupils(e) {
        let klass = this.state.klass;
        if (e.target.id === 'klassName') {
            klass = Number(e.target.value);
            this.setState({
                klass: Number(e.target.value)
            })
        }
        let schools = document.getElementById('schools');
        axios.get(apiURL + "/pupil/list?schoolId=" + schools.value + '&klass=' + klass).then(result => {
            if (result.data.statusCode === 200) {
                let pupils = result.data.data.map(data => {
                    return <option key={'p' + data.id} value={data.id}>{data.name}</option>;
                });
                pupils.unshift(<option key={'p0'} value="0">{strings.pleaseSelect}</option>);
                this.setState({
                    pupils: pupils
                })
            }
        });
    }

    startTest() {
        const schools = document.getElementById('schools');
        const klassName = document.getElementById('klassName').value;
        const lastName = document.getElementById('lastName');
        const firstName = document.getElementById('firstName');
        let language = document.getElementById('language').value;

        axios.post(apiURL + "/pupil/add2?schoolId=" + schools.value + "&klass=" + klassName + "&lastName=" + lastName.value + "&firstName=" + firstName.value)
            .then(result=>{
                if (result.status === 200) {
                    window.localStorage.setItem('pupilId', result.data.id);
                    this.setState({
                        pupilId: result.data.id,
                        isStartView: false,
                        isTestView: true
                    });

                    axios.get(apiURL + '/test/getTest2?methodId=' + (Number(klassName) === 6 ? 44 : Number(klassName) === 5 ? 45 : 46) + "&language=" + language).then(result => {
                        if (result.data.statusCode === 200) {
                            this.loadNextQuestion(result.data.data, this.state.questionIndex);
                            this.setState({
                                language: language,
                                questions: result.data.data,
                            });
                        }
                    });
                }
            });
    }

    finishTest() {
        let answer = this.state.pupilAnswers.join(',');
        axios.get(apiURL + '/test/checkResult2?testMethodId=' + (Number(this.state.klass) === 6 ? 44 : Number(this.state.klass) === 5 ? 45 : 46) + '&pupilId=' +
            this.state.pupilId + '&language=' + (this.state.language === 'uz' ? 1 : 2) + '&testResult=' + answer).then(result=>{
            if (result.data.statusCode === 200) {
                Swal.fire({
                    title: "Test natijasi",
                    text: result.data.data.result,
                    type: 'info',
                    showCloseButton: true,
                }).then((result) => {
                    if (result.value) {
                        window.open('/quiz', '_self');
                    }
                });
            } else {
                Swal.fire({
                    title: "Test natijasi",
                    text: result.data.message,
                    type: 'error',
                    showCloseButton: true
                });
            }
        })
    }

    render() {
        let klass = localStorage.getItem('klass');
        let regions = getRegions(this.state.language);
        return (
            <div>
                {this.state.isStartView &&
                <div className="container-sm card card-profile shadow ml-box mt-3 p-3">
                    <div className="row mb-1">
                        <div className="col s5">
                            <label htmlFor="regions">{strings.region}</label>
                        </div>
                        <div className="col s7">
                            <select id="regions" className="form-control" onChange={this.loadDistricts.bind(this)}>
                                {regions}
                            </select>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col s5">
                            <label htmlFor="districts">{strings.districtName}</label>
                        </div>
                        <div className="col s7">
                            <select id="districts" className="form-control" onChange={this.loadSchools.bind(this)}>
                                {this.state.districts}
                            </select>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col s5">
                            <label htmlFor="schools">{strings.schoolName}</label>
                        </div>
                        <div className="col s7">
                            <select id="schools" className="form-control" onChange={this.loadPupils.bind(this)}>
                                {this.state.schools}
                            </select>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col s5">
                            <label htmlFor="klassName">{strings.klass}</label>
                        </div>
                        <div className="col s7">
                            <select id="klassName" className="form-control" defaultValue={klass} onChange={this.loadPupils.bind(this)}>
                                <option value="5">{strings.klass5}</option>
                                <option value="6">{strings.klass6}</option>
                                <option value="7">{strings.klass7}</option>
                                <option value="8">{strings.klass8}</option>
                                <option value="9">{strings.klass9}</option>
                                <option value="10">{strings.klass10}</option>
                                <option value="11">{strings.klass11}</option>
                            </select>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col s5">
                            <label htmlFor="lastName">{strings.lastName}</label>
                        </div>
                        <div className="col s7">
                            <input id="lastName" className="form-control"/>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col s5">
                            <label htmlFor="firstName">{strings.firstName}</label>
                        </div>
                        <div className="col s7">
                            <input id="firstName" className="form-control"/>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col s5">
                            <label htmlFor="language">{strings.testLanguage}</label>
                        </div>
                        <div className="col s7">
                            <select id="language" className="form-control" onChange={(e)=>{this.setState({language: e.target.value})}}>
                                <option value="1">O`zbekcha</option>
                                <option value="2">Русский</option>
                            </select>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12 text-center">
                            <button className="btn btn-success"
                                    onClick={this.startTest.bind(this)}>{strings.startTest}</button>
                        </div>
                    </div>

                </div>
                }
                {this.state.isTestView &&
                <div className="container-fluid mt-3 mb-3">
                    <p><b>{this.state.header1}</b></p>
                    <p><b>{this.state.header2}</b></p>
                    <div>{this.state.question}</div>
                    <div>{this.state.answers}</div>

                    {this.state.showFinishButton &&
                        <div className="form-row">
                            <div className="col-md-12 text-center">
                                <button className="btn btn-success" onClick={this.finishTest.bind(this)}>{strings.finish}</button>
                            </div>
                        </div>
                    }
                </div>
                }
            </div>
        )
    }
}