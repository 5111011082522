import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {apiURL, strings} from "./Utils";
import axios from 'axios';

class OccupationMonthVideoView extends Component {

	constructor() {
		super();
		this.state = {
			videos: []
		}
	}
	componentDidMount() {
		console.log('OccupationMonthVideoView');
		let regionId = window.localStorage.getItem('regionId');
		axios.get(apiURL + '/videos/list?regionId=' + regionId)
			.then(result=>{
				if (result.data.statusCode === 200) {
					if (result.data.data.length > 0) {
						let videos = result.data.data.map(v=>{
							return <video width="100%" height="auto" controls className="mb-3">
								<source src={apiURL + '/video/occmonth/' + regionId + '/' + v} type="video/mp4"/>
							</video>
						});
						this.setState({
							videos: videos
						})
					}
				}
			});
	}

	goBack() {
		this.props.history.goBack();
	}

	render() {
		return(
			<div>
				<section className="page-banner">
					<div className="image-layer" style={{backgroundImage: "url(images/bread-bg.jpg)"}}/>
					<div className="auto-container">
						<div className="breadcrumb-box"/>
						<h1>{strings.media}</h1>
					</div>
				</section>
				<section className="about-area inner-content-wrapper">
					<div className="container">
						<div className="media-show ml-box card p-3">
							{this.state.videos}
						</div>
						<div className="mediaBot">
							<button className="btn pull-right">
								<i className="fa fa-download"/>
								Yuklab olish
							</button>
							<button className="btn bg-info pull-right mr-2">
								<i className="fa fa-arrow-left"/>
								<span className="text-white" onClick={this.goBack.bind(this)}>{strings.back}</span>
							</button>
						</div>
					</div>
				</section>
			</div>
		)
	}
}

export default withRouter(OccupationMonthVideoView);