import React from "react";
import axios from 'axios';
import swal from 'sweetalert';
import {apiURL} from "./Utils";

export default class AddInfographicView extends React.Component {

    save() {
        let infoName = document.getElementById('infoName').value;
        let profType = document.getElementById('profType').value;
        let language = document.getElementById('language').value;
        let imageFiles = document.getElementById('imageInput').files;
        let pdfFiles = document.getElementById('pdfInput').files;
        let data = new FormData();
        data.append('name', infoName);
        data.append('type', profType);
        data.append('language', language);
        data.append('imageFile', imageFiles[0]);
        data.append('pdfFile', pdfFiles[0]);
        axios.post(apiURL + '/profession/infographic/add', data).then(result=>{
            if (result.data.statusCode === 200) {
                swal(result.data.message, '', 'success');
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    }

    render() {
        return (
            <div>
                <div className="form-row">
                    <div className="col-md-12">
                        <label htmlFor="infoName">Kasb nomi</label>
                        <input id="infoName" className="form-control"/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-6">
                        <label htmlFor="profType">Turi</label>
                        <select id="profType" className="form-control">
                            <option value={1}>Odam-odam</option>
                            <option value={2}>Odam-tabiat</option>
                            <option value={3}>Odam-texnika</option>
                            <option value={4}>Odam-Belgilar tizimi</option>
                            <option value={5}>Odam-Badiiy obraz</option>
                            <option value={6}>Zamonaviy kasblar</option>
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="language">Til</label>
                        <select id="language" className="form-control">
                            <option value={1}>O`zbek</option>
                            <option value={2}>Rus</option>
                        </select>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12">
                        <label htmlFor="imageInput">Rasm fayli</label>
                        <input id="imageInput" type="file" className="form-control-file"/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12">
                        <label htmlFor="pdfInput">PDF fayli</label>
                        <input id="pdfInput" type="file" className="form-control-file"/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 text-center">
                        <button className="btn btn-info" onClick={this.save.bind(this)}>Saqlash</button>
                    </div>
                </div>
            </div>
        )
    }
}