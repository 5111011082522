import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import {apiURL} from "./Utils";

export default class AddEffectivePupilView extends Component {

    save() {
        let infoName = document.getElementById('ep_pupilName').value;
        let imageFiles = document.getElementById('ep_imageInput').files;
        let videoFiles = document.getElementById('ep_videoInput').files;
        let data = new FormData();
        data.append('name', infoName);
        data.append('imageFile', imageFiles[0]);
        data.append('videoFile', videoFiles[0]);
        axios.post(apiURL + '/videos/effectivePupil/add', data).then(result=>{
            if (result.data.statusCode === 200) {
                swal(result.data.message, '', 'success');
                window.open(apiURL + '/reports');
            } else {
                swal(result.data.message, '', 'error');
            }
        })
    }

    render() {
        return(
            <div className="card card-body my-4 shadow-sm">
                <div className="form-row">
                    <div className="col-md-12 text-center">
                        <h4>Muvaffaqqiyatga erishgan bitiruvchini qo`shish</h4>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12">
                        <label htmlFor="ep_pupilName">Nomi</label>
                        <input id="ep_pupilName" className="form-control"/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mt-3">
                        <label htmlFor="ep_imageInput">Rasm fayli</label>
                        <input id="ep_imageInput" type="file" className="form-control-file"/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mt-3">
                        <label htmlFor="ep_videoInput">Video fayli</label>
                        <input id="ep_videoInput" type="file" className="form-control-file"/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 mt-3 text-center">
                        <button className="btn btn-info" onClick={this.save.bind(this)}>Saqlash</button>
                    </div>
                </div>
            </div>
        )
    }
}