import React, {Component} from 'react';
import WorldOfProfessionsView from "./WorldOfProfessionsView";
import {strings} from "./Utils";
import {Link, withRouter} from "react-router-dom";

class HomePage extends Component {

	render() {
		return (
			<div>
				<section className="slider-one">
					<div className="slider-one__carousel owl-carousel owl-theme">
						<div className="item slider-one__slider-1">
							<div className="container">
								<h2 className="slider-one__title">
									{strings.ourHelp3}
									<br/>{strings.ourHelp4}
									<span className="">{strings.ourHelp5}</span>
								</h2>
								<p className="slider-one__text">
									{strings.rtm1}
									<br/>
									{strings.rtm2}
								</p>
								<Link to="/contact" className="theme-btn btn-style-one">
									<div className="btn-title">
										<span className="btn-txt">{strings.fullDescription}</span>
										<span className="btn-icon">
						                    <span className="icon fa fa-long-arrow-right" aria-hidden="true"/>
						                </span>
									</div>
								</Link>
							</div>
						</div>

						<div className="item slider-one__slider-3" style={{backgroundImage: "url(images/showcase-bg1.png)"}}>
							<div className="container">
								<h2 className="slider-one__title">
									{strings.ourHelp2}
									<br/>
									{strings.ourHelp2_1}
								</h2>
								<p className="slider-one__text">
									{strings.ourHelp1}
								</p>
								<Link to="/contact" className="theme-btn btn-style-one">
									<div className="btn-title">
										<span className="btn-txt">{strings.fullDescription}</span>
										<span className="btn-icon">
											<span className="icon fa fa-long-arrow-right" aria-hidden="true"/>
						                </span>
									</div>
								</Link>
							</div>
						</div>
					</div>
				</section>
				
				<section className="services-sec">
					<div className="container">
						<WorldOfProfessionsView language={this.props.language}/>
					</div>
				</section>

				<section className="about-area">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 col-md-12">
								<div className="full-sec-content">
									<div className="sec-title style-two">
										<h2>{strings.aboutUs}</h2>
										<span className="decor"><span className="inner"/></span>
									</div>
									<div style={{height: "200px"}}>
										<img src="images/tashxisLogo.png" alt="" style={{float: "left", width: "150px"}} title={strings.rtm3}/>
										<h3 className="col-md-8 pt-5">{strings.rtm3}</h3>
									</div>
									<img src="images/aboutUs.png" alt="" style={{float: "right", maxWidth: "400px"}} title=""/>
									{this.props.language === 'uz' &&
									<div style={{textAlign: "justify"}}>
										<p>
											&emsp;&emsp;&emsp;<b>Markaz</b> O‘zbekiston Respublikasi Vazirlar Mahkamasining 1998 yil 13 maydagi
                                            203-sonli qarori asosida Xalq ta’limi vazirligining 1999 yil 23 iyundagi 108-sonli buyrug‘i bilan Respublika iste'dod markazi negizida tashkil etilgan.</p>

										<p style={{textAlign: "justify"}}>&emsp;&emsp;&emsp;Xalq ta’limi Vazirligining
											"O‘quvchilarni kasb-hunarga yo‘naltirish va psixologik-pedagogik respublika tashxis markazi faoliyatini takomillashtirish va
											rivojlantirish to‘g‘risida" 2001-yil 23-aprelidagi 64-sonli buyrug`iga asosan Markaz: O‘quvchilarni kasb-hunarga yo‘naltirish va psixologik-pedagogik respublika tashxis markazi" deb qayta nomlandi va uning
											tuzilmasi tasdiqlandi. O‘zbekiston Respublikasi Xalq ta’limi vazirligining o‘quvchilarni kasb-hunarga yo‘naltirish va psixologik-pedagogik Respublika Tashxis Markazi
											O‘zbekiston Respublikasi Xalq ta’limi vazirligi tizimidagi o‘quvchi-yoshlarning kasb-hunarga yo‘naltirish xizmatiga rahbarlik qiluvchi zveno hisoblanadi va O‘zbekiston Respublikasi
											Konstitusiyasi va qonunlarida ko‘zda tutilgan jamiyat ehtiyoji va mehnat bozori talablari asosida ularning o‘z qiziqishlari, qobiliyatlari va sog‘liqlariga mos bo‘lgan kasb-hunarlarni
											tanlashlariga ko‘maklashadi. Markaz o‘quvchi yoshlarni kasb-hunarga yo‘naltirish va psixologik-pedagogik tashxis sohasidagi yagona davlat siyosatini ishlab chiqish va o‘rnatilgan tartibda
											amalga oshirish, O‘zbekiston Respublikasi Xalq ta’limi vazirligining kasb-hunarga yo‘naltirish respublika tizimini tashkiliy, ilmiy-metodik, axborot-ma'lumot va dasturiy ta'minlash
											maqsadida tashkil etilgan. Markaz yuridik shaxs hisoblanib, O‘zbekiston Respublikasi davlat gerbining tasviri tushirilgan, tashkilotning to‘liq nomi yozilgan dumaloq muhriga, shtampiga va
											peshlavhasiga ega. Markaz o‘z mustaqil balansiga, O‘zbekiston Respublikasi bank muassasalarida hisob-kitob raqamlariga ega, o‘z faoliyatini davlat byudjeti, grantlardan, xo‘jalik shartnomalaridan
											va boshqa manbalardan tushgan mablag‘lar hisobiga amalga oshiradi. Markaz tuzilmasi va shtatlar jadvali o‘rnatilgan tartibda O‘zbekiston Respublikasi Xalq ta’limi vazirligi
											tomonidan belgilanadi va tasdiqlanadi.</p>

										<div style={{textAlign: "justify"}}>&emsp;&emsp;&emsp;<b>Markazning asosiy vazifalari:</b>
											<ul id="cfunctions">
												<li>O‘zbekiston Respublikasi Xalq ta’limi vazirligining tashkilot, muassasa va ta’lim muassasalarida kasb-hunarga yo‘naltirish va
													psixologik-pedagogik tashxis sohasiga oid yagona davlat siyosatini ishlab chiqish va amalga oshirish;
												</li>
												<li>Barcha bosqichdagi o‘quvchilarni kasb-hunarga yo‘naltirish va psixologik-pedagogik hududiy tashxis markazlari va ta’lim
													muassasalaridagi kasb-hunarga yo‘naltirish va psixologik-pedagogik tashxis ishlarini muvofiqlashtirish;
												</li>
												<li>Xalq ta’limi vazirligining barcha bosqichidagi hududiy o‘quvchilarni kasb-hunarga yo‘naltirish va psixologik-pedagogik tashxis markazlari, ta’lim muassasalari uchun kasb-hunarga yo‘naltirish va
													psixologik-pedagogik tashxis xizmati faoliyatini tashkiliy,	ilmiy-metodik, axborot ta'minotini amalga oshirish;
												</li>
												<li>Kasb-hunarga yo‘naltirish va psixologik-pedagogik tashxis ishlari	holatini nazorat qilish, uni takomillashtirish choralarini ko‘rish,	tegishli dasturlar, me'yoriy hujjatlar va tavsiyalar ishlab chiqish;
												</li>
												<li>Mamlakatimizdagi va xorijiy davlatlarning ilg‘or tajribalarini umumlashtirish, ommalashtirish va hayotga tatbiq	qilish. Barcha bosqichdagi o‘quvchilarni kasb-hunarga yo‘naltirish
													va psixologik-pedagogik hududiy tashxis markazlari va ta’lim	muassasalaridagi kasb-hunarga yo‘naltirish vazifasi yuklatilgan mutaxassislarning malakasini
													oshirish bo‘yicha chora-tadbirlar ishlab chiqish;
												</li>
												<li>Umumiy o‘rta ta’lim maktablari o‘quvchilarining hayotiy va kasbiy o‘zligini anglash borasidagi ustuvor dasturlar va muammolar bilan ishlash.
												</li>
											</ul>
										</div>
									</div>
									}
								{this.props.language === 'ru' &&
									<div>
										<p>&emsp;&emsp;&emsp;<b>РЕСПУБЛИКАНСКИЙ ЦЕНТР ПРОФЕССИОНАЛЬНОЙ ОРИЕНТАЦИИ И ПСИХОЛОГО-ПЕДАГОГИЧЕСКОЙ ДИАГНОСТИКИ УЧАЩИХСЯ</b></p>
										<p style={{textAlign: "justify"}}>&emsp;&emsp;&emsp;Центр профессиональной ориентации и психолого-педагогической диагностики учащихся при Министерстве народного образования Республики Узбекистан (в дальнейшем именуется Центр) создан на основании постановления Кабинета Министров № 203 от 13 мая 1998 года “Об организации общего среднего образования в Республике Узбекистан”</p>
										<p style={{textAlign: "center"}}><b>ЦЕЛЬ СОЗДАНИЯ ЦЕНТРА</b></p>
										<p style={{textAlign: "justify"}}>&emsp;&emsp;&emsp;Основная целью деятельности Центра является профессиональная ориентация учащихся, их психолого-педагогическая диагностика и организационное, научное, методическое, информационное, программное обеспечение процессов выявления одаренности, интересов и склонностей учащихся.</p>
										<div style={{textAlign: "center"}}><b>ГЛАВНЫЕ ЗАДАЧИ ЦЕНТРА</b>
											<ol id="cfunctions">
												<li>Выработка научных и организационно - методических основ профессиональной ориентации выпускников 9-х классов общеобразовательных школ и молодежи.</li>
												<li>Координация деятельности Центров профессиональной ориентации учащихся и психолого-педагогической диагностики при областных, городских и районных отделах народного образования, организация научно-методической и консультативной помощи, выработка методических и нормативно-правовых документов и методических рекомендаций, определяющих деятельность ведомственных Центров диагностики.</li>
												<li>Разработка и внедрение психодиагностических методик, используемых при диагностике интересов, склонностей и способностей выпускников общеобразовательных школ.</li>
												<li>Организация профконсультаций и обеспечение профинформацией о видах современного производства, о профессиях, о состоянии рынка труда, о потребности в квалифицированных кадрах в социально-экономической сфере, содержании и перспективе рынка профессий, о формах и видах их освоения и предъявляемых к ним требованиям, о росте профессиональной квалификации в процессе трудовой деятельности воспитанникам учреждений дошкольного образования и учащихся общеобразовательных школ.</li>
												<li>Организация выдачи рекомендаций в учреждения среднего специального профессионального образования (академические лицеи или профессиональные колледжи) учащимся общеобразовательных школ, исходя из их психофизиологических особенностей, личностных возможностей и потребностей общества.</li>
												<li>Определение состояния здоровья и степени подготовленности 6-леток к школе, разработка и претворение в жизнь необходимых нормативных документов, инструкций, методических пособий, обеспечивающих оздоровление дошкольников совместно с Министерством здравоохранения.</li>
												<li>Психолого-педагогическое диагностирование умственного и физического развития учащихся, определение их способностей и склонностей, организация выдачи рекомендаций для приема в школу с 6-ти лет детям, признанным готовыми к школе по результатам педагогико-психологической диагностики.</li>
												<li>Организация и контроль ежегодного медицинского осмотра учащихся. Учет профпригодности (медицинских противопоказаний) при даче рекомендаций.</li>
												<li>Консультация и психолого-педагогическая помощь учащимся и их родителям по вопросам (проблемам) образования, воспитания и процесса социальной адаптации.</li>
												<li>Создание банка данных о существующих направлениях подготовки в академических лицеях и профессиональных колледжах, действующих в Республике Узбекистан.</li>
												<li>Разработка концепций и программ в рамках компетенции Центра с привлечением заинтересованных организаций.</li>
												<li>В рамках деятельности Центра изучение прогрессивного зарубежного опыта и новых методов, адаптация их к местной национальной среде, выработка мероприятий по внедрению их в практику.</li>
												<li>Внесение предложений в соответствующие министерства и ведомства по вопросам профессионального образования, профессиональной ориентации, по выбору профессий учащимися, а также их социальной защиты.</li>
												<li>Изучение и распространение передового опыта по работе с одаренными учащимися, а также с учащимися, имеющими отклонения в умственном и физическом развитии, создание банка данных об одаренных детях и молодежи, выработка методических, нормативно-правовых документов и инструкций в целях организации для вышеназванных детей консультаций по правовым, педагогическим, психологическим, духовным и другим направлениям.</li>
												<li>Создание банка данных об одаренных учащихся, разработка нормативно-правовых документов и рекомендаций по оказанию им консультаций в области выбора профессий.</li>
												<li>Создание справочно-информационных источников по направлениям Центра.</li>
												<li>Внесение предложений в Министерство народного образования о поощрении и представлении на государственные награды работников, достигших высоких показателей в рамках деятельности Центра.</li>
												<li>Организация повышения квалификации работников системы.</li>
												<li>Оказание (разного рода) консультативной, диагностической и образовательной помощи учащимся, молодежи и общественности.</li>
												<li>Являясь ведущим научно-методическим учреждением системы профессиональной ориентации и психолого-педагогической диагностики учащихся Министерства народного образования в Республике Узбекистан, Центр определяет стратегию развития научно-методических и социальных исследований в отрасли, имея право обращаться с инициативой (в том числе законодательной) в вышестоящие органы по вопросам совершенствования системы профессиональной ориентации, нормативной базы и образовательного процесса, повышения качества подготовки кадров.</li>
											</ol>
										</div>
									</div>
									}
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="about-area inner-content-wrapper">
					<div className="container">
						<div className="col-lg-7 col-md-12">
							<div className="full-sec-content">
								<div className="sec-title style-two">
									<div className="ml-edu">
										<h4>{strings.effectiveMens}</h4>
									</div>
									<span className="decor"><span className="inner"/></span>
								</div>
							</div>
						</div>

						<div className="container">
							<div className="row">
								<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
									<div className="card">
										<img className="card-img-top" src="images/jekma.jpg" alt=""/>
										<div className="card-body">
											<h4 className="card-title">Jek Ma</h4>
											{this.props.language === 'uz' &&
											<p><b>Tadbirkor: "Alibaba Group" asoschisi</b></p>
											}
											{this.props.language === 'ru' &&
											<p><b>Предприниматель: Основатель "Alibaba Group"</b></p>
											}
											<Link to="/media?src=jekma" className="btn">{strings.playVideo}</Link>
										</div>
									</div>
								</div>
								<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
									<div className="card">
										<img className="card-img-top" src="images/murodnazarov.jpg" alt=""/>
										<div className="card-body">
											<h4 className="card-title">Murod Nazarov</h4>
											{this.props.language === 'uz' &&
											<p><b>Tadbirkor: "Murad Building" asoschisi</b></p>
											}
											{this.props.language === 'ru' &&
											<p><b>Предприниматель: Основатель "Murad Building"</b></p>
											}
											<Link to="/media?src=murodnazarov" className="btn">{strings.playVideo}</Link>
										</div>
									</div>
								</div>
								<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
									<div className="card">
										<img className="card-img-top" src="images/jekichan.jpg" alt=""/>
										<div className="card-body">
											<h4 className="card-title">Jeki Chan</h4>
											{this.props.language === 'uz' &&
											<p><b>Aktyor</b></p>
											}
											{this.props.language === 'ru' &&
											<p><b>Актёр</b></p>
											}
											<Link to="/media?src=jekichan" className="btn">{strings.playVideo}</Link>
										</div>
									</div>
								</div>
								<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
									<div className="card">
										<img className="card-img-top" src="images/ronaldo.jpg" alt=""/>
										<div className="card-body">
											<h4 className="card-title">Krishtiano Ronaldo</h4>
											{this.props.language === 'uz' &&
											<p><b>Sportchi: Futbolchi</b></p>
											}
											{this.props.language === 'ru' &&
											<p><b>Спортсмен: футболист</b></p>
											}
											<Link to="/media?src=ronaldo" className="btn">{strings.playVideo}</Link>
										</div>
									</div>
								</div>
								<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
									<div className="card">
										<img className="card-img-top" src="images/zafarhoshimov.jpg" alt=""/>
										<div className="card-body">
											<h4 className="card-title">Zafar Hoshimov</h4>
											{this.props.language === 'uz' &&
											<p><b>Tadbirkor: "Korzinka.uz" asoschisi</b></p>
											}
											{this.props.language === 'ru' &&
											<p><b>Предприниматель: Основатель "Korzinka.uz"</b></p>
											}
											<Link to="/media?src=zafarhoshimov" className="btn">{strings.playVideo}</Link>
										</div>
									</div>
								</div>
								<div className="col-12 col-sm-8 col-md-6 col-lg-4 text-center">
									<div className="card">
										<img className="card-img-top" src="images/stivjobs.jpg" alt=""/>
										<div className="card-body">
											<h4 className="card-title">Stiv Jobs</h4>
											{this.props.language === 'uz' &&
											<p><b>Tadbirkor: "Apple" asoschisi</b></p>
											}
											{this.props.language === 'ru' &&
											<p><b>Предприниматель: Основатель "Apple"</b></p>
											}
											<Link to="/media?src=stivjobs" className="btn">{strings.playVideo}</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

			</div>
		)
	}
}

export default withRouter(HomePage);