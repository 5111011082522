import React, {Component} from 'react';
import axios from 'axios';
import {apiURL, strings} from "./Utils";
import {Redirect, withRouter} from 'react-router-dom';

class QuizView extends Component {

	constructor() {
		super();
		this.state = {
			openTestPage: false
		}
	}
	loadDistricts() {
		let regions = document.getElementById('regions');
		let districts = document.getElementById('districts');
		axios.get(apiURL + "/pupil/districts?regionId=" + regions.value)
			.then(result=> {
			if (result.status === 200) {
				let length = districts.options.length;
				for (let i = length - 1; i >= 0; i--) {
					districts.options[i] = null;
				}
				let parse = JSON.parse(result.data);
				for (let key in parse) {
					let option = document.createElement("option");
					option.setAttribute("value", parse[key].id);
					option.innerText = parse[key].name;
					districts.appendChild(option);
				}
			}
		});
	}

	openTestPage(klass, testId, minutes, testName) {
		localStorage.setItem('klass', klass);
		localStorage.setItem('testId', testId);
		localStorage.setItem('testName', testName);
		localStorage.setItem('minutes', minutes);
		this.setState({
			openTestPage: true
		})
	}

	renderRedirect = () => {
		if (this.state.openTestPage) {
            let testId = localStorage.getItem('testId');
            if (Number(testId) === 44 || Number(testId) === 45 || Number(testId) === 46) {
                return <Redirect to='/iqTest'/>
            } else {
                return <Redirect to='/quizStart'/>
            }
		}
	};

	render() {
		return (
			<div>
				{this.renderRedirect()}
				<section className="about-area inner-content-wrapper">
					<div className="container">
						<div className="sec-title text-center mt-0 mb-0">
							<h2>
								{strings.tests2}
							</h2>
							<span className="decor"><span className="inner"/></span>
						</div>
						<div className="row">
							<div className="col-md-2">
								<div className="full-sec-content">
									<div className="card card-profile mt--300 shadow ml-box">
										<div className="px-4">
											<div className="mt-2 py-3 border-top text-center">
												<div className="row justify-content-center">
													<div className="col-lg-12">
														<div className="nav flex-column nav-pills" id="v-pills-tab"
														     role="tablist" aria-orientation="vertical">
															<a className="nav-link active text-uppercase" id="v-pills-5-tab" data-toggle="pill" href="#v-pills-5" role="tab"
															   aria-controls="v-pills-5" aria-selected="true">
																<i className="fa fa-school fa-2x"/>
																<br/>
																{strings.klass5}
															</a>
															<hr/>
															<a className="nav-link text-uppercase" id="v-pills-6-tab" data-toggle="pill" href="#v-pills-6" role="tab"
															   aria-controls="v-pills-6" aria-selected="false">
																<i className="fa fa-school fa-2x"/>
																<br/>
																{strings.klass6}
															</a>
															<hr/>
															<a className="nav-link text-uppercase" id="v-pills-7-tab" data-toggle="pill" href="#v-pills-7" role="tab"
															   aria-controls="v-pills-7" aria-selected="false">
																<i className="fa fa-school fa-2x"/>
																<br/>
																{strings.klass7}
															</a>
															<hr/>
															<a className="nav-link text-uppercase" id="v-pills-8-tab" data-toggle="pill" href="#v-pills-8" role="tab"
															   aria-controls="v-pills-8" aria-selected="false">
																<i className="fa fa-school fa-2x"/>
																<br/>
																{strings.klass8}
															</a>
															<hr/>
															<a className="nav-link text-uppercase" id="v-pills-9-tab" data-toggle="pill" href="#v-pills-9" role="tab"
															   aria-controls="v-pills-9" aria-selected="false">
																<i className="fa fa-school fa-2x"/>
																<br/>
																{strings.klass9}
															</a>
															<hr/>
															<a className="nav-link text-uppercase" id="v-pills-10-tab" data-toggle="pill" href="#v-pills-10" role="tab"
															   aria-controls="v-pills-10" aria-selected="false">
																<i className="fa fa-school fa-2x"/>
																<br/>
																	{strings.klass10}
															</a>
															<hr/>
															<a className="nav-link text-uppercase" id="v-pills-11-tab" data-toggle="pill" href="#v-pills-11" role="tab"
															   aria-controls="v-pills-11" aria-selected="false">
																<i className="fa fa-school fa-2x"/>
																<br/>
																	{strings.klass11}
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-10">
								<div className="full-sec-content">
									<div className="card card-profile mt--300 shadow ml-box">
										<div className="px-4">
											<div className="mt-2 py-3 border-top text-center">
												<div className="row justify-content-center">
													<div className="col-lg-12">
														<div className="tab-content" id="v-pills-tabContent">

															<div className="tab-pane fade show active" id="v-pills-5" role="tabpanel" aria-labelledby="v-pills-5-tab">
																<div id="accordion">
																	<div className="card border-0  mb-2">
																		<div className="card-header" id="headingOne5">
																			<h5 className="mb-0 d-flex">
																				<button className="btn btn-link btnsze"
																				        data-toggle="collapse"
																				        data-target="#collapseOne5"
																				        aria-expanded="true"
																				        aria-controls="collapseOne5">
																					{strings.method1} {this.props.language === 'uz' ? 'IQ TEST' : 'IQ ТЕСТ'}
																				</button>
																			</h5>
																		</div>

																		<div id="collapseOne5" className="collapse " aria-labelledby="headingOne5" data-parent="#accordion">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b>O`quvchilarning IQ darajasini aniqlash.</p>
																						<p><b className="green-title">Testlar soni:</b> 29ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 30 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Savollarni diqqat bilan o‘qing. O‘zingizga ma’qul bo‘lgan javoblardan faqat bittasini belgilang.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение уровня IQ учеников.</p>
																						<p><b className="green-title">Количество тестов:</b> 29 шт.</p>
																						<p><b className="green-title">Время:</b> 30 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Внимательно прочитайте вопросы. Отметьте ответ, который вам нравится знаком «+».</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 5, 45, 30, this.props.language === 'uz' ? 'IQ TEST' : 'IQ ТЕСТ')}
																							data-toggle="modal"
																							data-target="#modal6_1"
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2 "/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div className="card border-0  mb-2">
																		<div className="card-header" id="headingTwo5">
																			<h5 className="mb-0 d-flex">
																				<button className="btn btn-link btnsze"
																						data-toggle="collapse"
																						data-target="#collapseTwo5"
																						aria-expanded="true"
																						aria-controls="collapseTwo5">
																					{strings.method2} {this.props.language === 'uz' ? '"MAQSADLARGA INTILISH" TESTI' : 'Стремление к цели'}
																				</button>
																			</h5>
																		</div>

																		<div id="collapseTwo5" className="collapse " aria-labelledby="headingTwo5" data-parent="#accordion">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> maqsadlarga intilish darajasini aniqlash.</p>
																						<p><b className="green-title">Testlar soni:</b> 20ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 20 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Savollarni diqqat bilan o‘qing. O‘zingizga ma’qul bo‘lgan javoblardan faqat bittasini belgilang.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение уровня стремления к цели.</p>
																						<p><b className="green-title">Количество тестов:</b> 20 шт.</p>
																						<p><b className="green-title">Время:</b> 20 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Внимательно прочитайте вопросы. Отметьте ответ, который вам нравится знаком «+».</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 5, 26, 20, this.props.language === 'uz' ? '"MAQSADLARGA INTILISH" TESTI' : 'Стремление к цели')}
																							data-toggle="modal"
																							data-target="#modal5_2"
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2 "/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="tab-pane fade" id="v-pills-6" role="tabpanel" aria-labelledby="v-pills-6-tab">
																<div id="accordion">
																	<div className="card border-0  mb-2">
																		<div className="card-header" id="headingOne6">
																			<h5 className="mb-0 d-flex">
																				<button className="btn btn-link btnsze"
																				        data-toggle="collapse"
																				        data-target="#collapseOne6"
																				        aria-expanded="true"
																				        aria-controls="collapseOne6">
																					{strings.method1} {this.props.language === 'uz' ? 'IQ TEST' : 'IQ ТЕСТ'}
																				</button>
																			</h5>
																		</div>

																		<div id="collapseOne6" className="collapse " aria-labelledby="headingOne6" data-parent="#accordion">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b>O`quvchilarning IQ darajasini aniqlash.</p>
																						<p><b className="green-title">Testlar soni:</b> 24ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 25 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Savollarni diqqat bilan o‘qing. O‘zingizga ma’qul bo‘lgan javoblardan faqat bittasini belgilang.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение уровня IQ учеников.</p>
																						<p><b className="green-title">Количество тестов:</b> 24 шт.</p>
																						<p><b className="green-title">Время:</b> 25 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Внимательно прочитайте вопросы. Отметьте ответ, который вам нравится знаком «+».</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 6, 44, 25, this.props.language === 'uz' ? 'IQ TEST' : 'IQ ТЕСТ')}
																							data-toggle="modal"
																							data-target="#modal6_1"
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2 "/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div className="card border-0 mb-2">
																		<div className="card-header" id="headingTwo6">
																			<h5 className="mb-0 d-flex">
																				<button
																					className="btn btn-link btnsze collapsed"
																					data-toggle="collapse"
																					data-target="#collapseTwo6"
																					aria-expanded="false"
																					aria-controls="collapseTwo6">
                                                                                    {strings.method2} {this.props.language === 'uz' ? '"O`QUVCHILARNING QIZIQISHLARI, QOBILIYATLARI VA INTILISHLARINI ANIQLASH" METODIKASI'
                                                                                    : 'Методика «Определение интересов, способностей и стремлений учащихся».'}
																				</button>
																			</h5>
																		</div>
																		<div id="collapseTwo6"
																			 className="collapse"
																			 aria-labelledby="headingTwo6"
																			 data-parent="#accordion8">

																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex text-justify ">
                                                                                    {this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> O`quvchilarning qiziqishlari, qobiliyatlari va xohish-istaklarini o`rganish.</p>
																						<p><b className="green-title">Testlar soni:</b> 45ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 45 minut.</p>
																					</h5>
                                                                                    }
                                                                                    {this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Изучение интересов, способностей и желаний учащихся.</p>
																						<p><b className="green-title">Количество тестов:</b> 45 шт.</p>
																						<p><b className="green-title">Время:</b> 45 минут.</p>
																					</h5>
                                                                                    }
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 6, 31, 45, this.props.language === 'uz' ? '"O`QUVCHILARNING QIZIQISHLARI, QOBILIYATLARI VA INTILISHLARINI ANIQLASH" METODIKASI'
                                                                                                : 'Методика «Определение интересов, способностей и стремлений учащихся».')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2"/>
                                                                                            {strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="tab-pane fade" id="v-pills-7" role="tabpanel" aria-labelledby="v-pills-7-tab">
																<div id="accordion">
																	<div className="card border-0  mb-2">
																		<div className="card-header" id="headingOne7">
																			<h5 className="mb-0 d-flex">
																				<button className="btn btn-link btnsze"
																						data-toggle="collapse"
																						data-target="#collapseOne7"
																						aria-expanded="true"
																						aria-controls="collapseOne7">
																					{strings.method1} {this.props.language === 'uz' ? 'IQ TEST' : 'IQ ТЕСТ'}
																				</button>
																			</h5>
																		</div>

																		<div id="collapseOne7" className="collapse " aria-labelledby="headingOne7" data-parent="#accordion">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b>O`quvchilarning IQ darajasini aniqlash.</p>
																						<p><b className="green-title">Testlar soni:</b> 30ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 30 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Savollarni diqqat bilan o‘qing. O‘zingizga ma’qul bo‘lgan javoblardan faqat bittasini belgilang.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение уровня IQ учеников.</p>
																						<p><b className="green-title">Количество тестов:</b> 30 шт.</p>
																						<p><b className="green-title">Время:</b> 30 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Внимательно прочитайте вопросы. Отметьте ответ, который вам нравится знаком «+».</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 7, 46, 30, this.props.language === 'uz' ? 'IQ TEST' : 'IQ ТЕСТ')}
																							data-toggle="modal"
																							data-target="#modal7_1"
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2 "/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div className="card border-0  mb-2">
																		<div className="card-header" id="headingTwo7">
																			<h5 className="mb-0 d-flex">
																				<button className="btn btn-link btnsze"
																				        data-toggle="collapse"
																				        data-target="#collapseTwo7"
																				        aria-expanded="true"
																				        aria-controls="collapseTwo7">
																					{strings.method2} {this.props.language === 'uz' ? '"MAQSADLARGA INTILISH" TESTI' : 'Стремление к цели'}
																				</button>
																			</h5>
																		</div>

																		<div id="collapseTwo7" className="collapse " aria-labelledby="headingTwo7" data-parent="#accordion">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> maqsadlarga intilish darajasini aniqlash.</p>
																						<p><b className="green-title">Testlar soni:</b> 20ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 20 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Savollarni diqqat bilan o‘qing. O‘zingizga ma’qul bo‘lgan javoblardan faqat bittasini belgilang.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение уровня стремления к цели.</p>
																						<p><b className="green-title">Количество тестов:</b> 20 шт.</p>
																						<p><b className="green-title">Время:</b> 20 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Внимательно прочитайте вопросы. Отметьте ответ, который вам нравится знаком «+».</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 7, 26, 20, this.props.language === 'uz' ? '"MAQSADLARGA INTILISH" TESTI' : 'Стремление к цели')}
																							data-toggle="modal"
																							data-target="#modal7_1"
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2 "/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="card border-0  mb-2">
																		<div className="card-header" id="headingThree7">
																			<h5 className="mb-0 d-flex">
																				<button className="btn btn-link btnsze"
																				        data-toggle="collapse"
																				        data-target="#collapseThree7"
																				        aria-expanded="true"
																				        aria-controls="collapseThree7">
																					{strings.method3} {this.props.language === 'uz' ? '"TASHKILOTCHILIK QOBILIYATINI ANIQLASH" TESTI' : 'Тест «Определение организаторских способностей»'}
																				</button>
																			</h5>
																		</div>

																		<div id="collapseThree7" className="collapse "
																		     aria-labelledby="headingThree7"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> Tashkilotchilik qobiliyatining rivojlanganlik darajasini aniqlash.</p>
																						<p><b className="green-title">Testlar soni:</b> 20ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 20 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Savollarni diqqat bilan o‘qing, uzoq o‘ylamasdan o‘zingizga mos bo‘lgan javobni tanlang va belgilang.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение уровня развития организаторских способностей.</p>
																						<p><b className="green-title">Количество тестов:</b> 20 шт.</p>
																						<p><b className="green-title">Время:</b> 20 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Внимательно прочитайте вопросы, выберите тот, который подходит вам больше всего, и отметьте его.</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 7, 27, 20, this.props.language === 'uz' ? '"TASHKILOTCHILIK QOBILIYATINI ANIQLASH" TESTI' : 'Тест «Определение организаторских способностей»')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2"/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="card border-0 mb-2">
																		<div className="card-header" id="headingFour7">
																			<h5 className="mb-0 d-flex">
																				<button
																					className="btn btn-link btnsze collapsed"
																					data-toggle="collapse"
																					data-target="#collapseFour7"
																					aria-expanded="false"
																					aria-controls="collapseFour7">
																					{strings.method4} {this.props.language === 'uz' ? '"KASB TIPLARINI ANIQLASH" METODIKASI' : 'Методика «Определение типов профессий».'}
																				</button>
																			</h5>
																		</div>
																		<div id="collapseFour7"
																		     className="collapse"
																		     aria-labelledby="headingFour7"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex text-justify ">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> Kasb tiplariga qiziqishni aniqlash.</p>
																						<p><b className="green-title">Testlar soni:</b> 25ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 25 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Savolnomadagi ta’kid-hukmlarga munosabatingizni bildiring. Agar “ha” deb javob bermoqchi bo‘lsangiz savolning to‘g‘risidagi “ha” ustunga “+”, “yo‘q” ustuniga “-” belgisini qo‘ying. Savollarga imkon qadar haqqoniy javob berishga harakat qiling.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение интересов к типам профессий.</p>
																						<p><b className="green-title">Количество тестов:</b> 25 шт.</p>
																						<p><b className="green-title">Время:</b> 25 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Данные вопросы касаются вашего отношения к различным направлениям деятельности. Нравится ли вам делать то, о чем говорится в опроснике? Если нравится, то в бланке ответов в графе «да» поставьте плюс, если не нравится в графе «нет» - минус.</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 7, 28, 25, this.props.language === 'uz' ? '"KASB TIPLARINI ANIQLASH" METODIKASI' : 'Методика «Определение типов профессий».')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2"/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="tab-pane fade" id="v-pills-8" role="tabpanel" aria-labelledby="v-pills-8-tab">
																<div id="accordion8">
																	<div className="card border-0  mb-2">
																		<div className="card-header" id="headingOne8">
																			<h5 className="mb-0 d-flex">
																				<button className="btn btn-link btnsze"
																				        data-toggle="collapse"
																				        data-target="#collapseOne8"
																				        aria-expanded="true"
																				        aria-controls="collapseOne8">
																					{strings.method1} {this.props.language === 'uz' ? '"MULOQОTCHANLIKNING DARAJASINI ANIQLASH" TESTI' : 'Оценка уровня общительности'}
																				</button>
																			</h5>
																		</div>

																		<div id="collapseOne8"
																		     className="collapse "
																		     aria-labelledby="headingOne8"
																		     data-parent="#accordion8">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> Muloqot darajasini aniqlash .</p>
																						<p><b className="green-title">Testlar soni:</b> 16ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 16 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Test savollariga uchta javob variantidan foydalanib javob berish kerak - "ha", "ba’zan", "yo‘q". Savollarga tez va aniq javob bering.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение уровеня коммуникабельности. </p>
																						<p><b className="green-title">Количество тестов:</b> 16 шт.</p>
																						<p><b className="green-title">Время:</b> 16 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Отвечать на вопросы следует, используя три варианта ответов - «да», «иногда», «нет».</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 8, 29, 16, this.props.language === 'uz' ? '"MULOQОTCHANLIKNING DARAJASINI ANIQLASH" TESTI' : 'Оценка уровня общительности')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2"/>
																							{strings.startTest}
																						</button>
																					</div>

																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="card border-0  mb-2">
																		<div className="card-header" id="headingTwo8">
																			<h5 className="mb-0 d-flex">
																				<button className="btn btn-link btnsze"
																				        data-toggle="collapse"
																				        data-target="#collapseTwo8"
																				        aria-expanded="true"
																				        aria-controls="collapseOne8">
																					{strings.method2} {this.props.language === 'uz' ? '"O`QUVCHILARNING QIZIQISHLARINI ANIQLASH" SO`ROVNOMASI' : 'Опросник определения интересов учащихся'}
																				</button>
																			</h5>
																		</div>

																		<div
																			id="collapseTwo8"
																			className="collapse "
																			aria-labelledby="headingTwo8"
																			data-parent="#accordion">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> Kasbiy qiziqishlar haqida ma`lumotlarga ega bo‘lishi, natijalar asosida kelgusi kasbga yo‘naltirish tadbirlarini belgilash.</p>
																						<p><b className="green-title">Testlar soni:</b> 35ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 35 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Savolnomadagi ta’kid-hukmlarga munosabatingizni bildiring. Agar “ha” deb javob bermoqchi bo‘lsangiz savolning to‘g‘risidagi “ha” ustunga “+”, “yo‘q” ustuniga “-” belgisini qo‘ying. Savollarga imkon qadar haqqoniy javob berishga harakat qiling.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение профессиональных интересов и на основе результатов определить меры по будущей профориентации.</p>
																						<p><b className="green-title">Количество тестов:</b> 35 шт.</p>
																						<p><b className="green-title">Время:</b> 35 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Данные вопросы касаются вашего отношения к различным направлениям деятельности. Нравится ли вам делать то, о чем говорится в опроснике? Если нравится, то в бланке ответов в графе «да» поставьте плюс, если не нравится в графе «нет» - минус.</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 8, 30, 35, this.props.language === 'uz' ? '"O`QUVCHILARNING QIZIQISHLARINI ANIQLASH" SO`ROVNOMASI' : 'Опросник определения интересов учащихся')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2 "/>
																							{strings.startTest}
																						</button>
																					</div>

																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="card border-0 mb-2">
																		<div className="card-header" id="headingThree8">
																			<h5 className="mb-0 d-flex">
																				<button
																					className="btn btn-link btnsze collapsed"
																					data-toggle="collapse"
																					data-target="#collapseThree8"
																					aria-expanded="false"
																					aria-controls="collapseThree8">
																					{strings.method3} {this.props.language === 'uz' ? '"O`QUVCHILARNING QIZIQISHLARI, QOBILIYATLARI VA INTILISHLARINI ANIQLASH" METODIKASI'
																					: 'Методика «Определение интересов, способностей и стремлений учащихся».'}
																				</button>
																			</h5>
																		</div>
																		<div id="collapseThree8"
																		     className="collapse"
																		     aria-labelledby="headingThree8"
																		     data-parent="#accordion8">

																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex text-justify ">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> O`quvchilarning qiziqishlari, qobiliyatlari va xohish-istaklarini o`rganish.</p>
																						<p><b className="green-title">Testlar soni:</b> 45ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 45 minut.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Изучение интересов, способностей и желаний учащихся.</p>
																						<p><b className="green-title">Количество тестов:</b> 45 шт.</p>
																						<p><b className="green-title">Время:</b> 45 минут.</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 8, 31, 45, this.props.language === 'uz' ? '"O`QUVCHILARNING QIZIQISHLARI, QOBILIYATLARI VA INTILISHLARINI ANIQLASH" METODIKASI'
																								: 'Методика «Определение интересов, способностей и стремлений учащихся».')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2"/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="tab-pane fade" id="v-pills-9" role="tabpanel" aria-labelledby="v-pills-9-tab">
																<div id="accordion9">
																	<div className="card border-0  mb-2">
																		<div className="card-header" id="headingOne9">
																			<h5 className="mb-0 d-flex">
																				<button className="btn btn-link btnsze"
																				        data-toggle="collapse"
																				        data-target="#collapseOne9"
																				        aria-expanded="true"
																				        aria-controls="collapseOne9">
																					{strings.method1} {this.props.language === 'uz' ? '"SHAXS YO`NALGANLIGINI ANIQLASH" METODIKASI'
																					: 'Определение направленности личности'}
																				</button>
																			</h5>
																		</div>

																		<div id="collapseOne9"
																		     className="collapse "
																		     aria-labelledby="headingOne9"
																		     data-parent="#accordion9">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> O`quvchilarda shaxs yo`nalganligini aniqlash.</p>
																						<p><b className="green-title">Testlar soni:</b> 27ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 27 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Savolni diqqat bilan o’qing. Javoblar orasida Sizning ichki dunyoingizga eng yaqin bo’lgan javobni tanlang.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение направленности личности.</p>
																						<p><b className="green-title">Количество тестов:</b> 27 шт.</p>
																						<p><b className="green-title">Время:</b> 27 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Анкета состоит из 27 пунктов-суждений, по каждому из которых возможны три варианта ответов, соответствующие трем видам направленности личности. Респондент должен выбрать один ответ, который в наибольшей степени выражает его мнение или соответствует реальности, и еще один, который, наоборот, наиболее далек от его мнения или же наименее соответствует реальности. Ответ "наиболее" получает 2 балла, "наименее" - О, оставшийся невыбранным - 1 балл. Баллы, набранные по всем 27 пунктам, суммируются для каждого вида направленности отдельно.</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 9, 32, 27, this.props.language === 'uz' ? '"SHAXS YO`NALGANLIGINI ANIQLASH" METODIKASI'
																								: 'Определение направленности личности')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2 "/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="card border-0  mb-2">
																		<div className="card-header" id="headingTwo9">
																			<h5 className="mb-0 d-flex">
																				<button className="btn btn-link btnsze"
																				        data-toggle="collapse"
																				        data-target="#collapseTwo9"
																				        aria-expanded="true"
																				        aria-controls="collapseOne9">
																					{strings.method2} {this.props.language === 'uz' ? '"O`QUVCHILARNING FANLARGA BO`LGAN QIZIQISHLARINI ANIQLASH" TESTI'
																					: 'Тест по выявлению интересов учащихся к предметам'}
																				</button>
																			</h5>
																		</div>

																		<div id="collapseTwo9"
																		     className="collapse "
																		     aria-labelledby="headingTwo9"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> O`quvchilarning fanlarga bo`lgan qiziqishlarini aniqlash orqali kasbga yo`naltirish.</p>
																						<p><b className="green-title">Testlar soni:</b> 90ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 90 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Quyida keltirilgan anketa savollarining barchasiga to’liq javob bering. Agar sizga o’qigan savolingiz juda yoqsa ikkita “++” belgisi, shunchaki yoqsa “+”, bilmasangiz “0”, agar yoqmasa “-” belgisini qo’yasiz. Belgilar shu savolning to’g’risidagi ustunlar bo’yicha qo’yiladi.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение интересов учащихся к предметам и выявление профессиональной направленности.</p>
																						<p><b className="green-title">Количество тестов:</b> 90 шт.</p>
																						<p><b className="green-title">Время:</b> 90 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Необходимо ответить на все вопросы прилагаемой далее анкеты, не пропуская ни одного. Если Вам очень нравится заниматься тем, о чем говорится в вопросе, то в клеточке на листе ответов, обозначенной тем же номером, что и вопрос анкеты, нужно поставить, например, два плюса; если просто нравится - один плюс; равнодушны, не знаете - нуль; если не нравится - один минус.</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 9, 37, 90, this.props.language === 'uz' ? '"O`QUVCHILARNING FANLARGA BO`LGAN QIZIQISHLARINI ANIQLASH" TESTI'
																								: 'Тест по выявлению интересов учащихся к предметам')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2"/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="card border-0 mb-2">
																		<div className="card-header" id="headingThree9">
																			<h5 className="mb-0 d-flex">
																				<button
																					className="btn btn-link btnsze collapsed"
																					data-toggle="collapse"
																					data-target="#collapseThree9"
																					aria-expanded="false"
																					aria-controls="collapseThree9">
																					{strings.method3} {this.props.language === 'uz' ? '"KASB TANLASH MOTIVINI ANIQLASH" METODIKASI'
																					: 'Анкета мотивов выбора профессии.'}
																				</button>
																			</h5>
																		</div>
																		<div id="collapseThree9"
																		     className="collapse"
																		     aria-labelledby="headingThree9"
																		     data-parent="#accordion9">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex text-justify ">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> U yoki bu kasbga yorqin ifodalangan moyillikni va kasb tanlash motivini aniqlash. Kasbiy motivatsiya - kasb tanlashga sabab bo’ladigan aniq rag’batlantiruvchi harakatdir.</p>
																						<p><b className="green-title">Testlar soni:</b> 24ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 24 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Quyida keltirilgan so’rovnoma savollarini diqqat bilan o’qib, kasb tanlashda o’zingiz uchun muhim bo’lgan motiv (sabab)ni aniqlab oling.
																							Agar ushbu motiv Siz uchun ahamiyatli bo’lsa, u holda javob varaqasida savol yoniga (+) belgisini, ahamiyatsiz bo’lsa (-) belgisini qo’ying.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение мотивов выбора профессии и выраженной склонности к той или иной профессии.</p>
																						<p><b className="green-title">Количество тестов:</b> 24 шт.</p>
																						<p><b className="green-title">Время:</b> 24 минут.</p>
																						<p><b className="green-title">Инструкция:</b> необходимо выбрать те, которые больше других отвечают Вашей личной точке зрения; на листе ответов поставьте «+», если мотив значим, и «–», если не имеет значения.</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 9, 34, 24, this.props.language === 'uz' ? '"KASB TANLASH MOTIVINI ANIQLASH" METODIKASI'
																								: 'Анкета мотивов выбора профессии.')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2 "/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>

																	<div className="card border-0 mb-2">
																		<div className="card-header" id="headingFour9">
																			<h5 className="mb-0 d-flex">
																				<button
																					className="btn btn-link btnsze collapsed"
																					data-toggle="collapse"
																					data-target="#collapseFour9"
																					aria-expanded="false"
																					aria-controls="collapseFour9">
																					{strings.method4} {this.props.language === 'uz' ? '"QIZIQISHLAR XARITASI" TESTI'
																					: 'Методика карты интересов'}
																				</button>
																			</h5>
																		</div>
																		<div
																			id="collapseFour9"
																			className="collapse"
																			aria-labelledby="headingThree9"
																			data-parent="#accordion9">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex text-justify ">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> kasbiy qiziqishlar haqidagi ma’lumotlarga ega bo’lish.</p>
																						<p><b className="green-title">Testlar soni:</b> 50ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 50 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Savolnomadagi ta’kid-hukmlarga munosabatingizni bildiring. Agar “ha” deb javob bermoqchi bo’lsangiz savolning to’g’risidagi “ha” ustunga “+”, “yo’q” ustuniga “-” belgisini qo’ying. Savollarga imkon qadar haqqoniy javob berishga harakat qiling.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение профессиональных интересов.</p>
																						<p><b className="green-title">Количество тестов:</b> 50 шт.</p>
																						<p><b className="green-title">Время:</b> 50 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Данные вопросы касаются вашего отношения к различным направлениям деятельности. Нравится ли вам делать то, о чем говорится в опроснике? Если нравится, то в бланке ответов в графе «да» поставьте плюс, если не нравится в графе «нет» - минус.</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 9, 35, 50, this.props.language === 'uz' ? '"QIZIQISHLAR XARITASI" TESTI' : 'Методика карты интересов')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2"/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="tab-pane fade" id="v-pills-10" role="tabpanel" aria-labelledby="v-pills-10-tab">
																<div id="accordion10">
																	<div className="card border-0  mb-2">
																		<div className="card-header" id="headingOne10">
																			<h5 className="mb-0 d-flex">
																				<button className="btn btn-link btnsze"
																				        data-toggle="collapse"
																				        data-target="#collapseOne10"
																				        aria-expanded="true"
																				        aria-controls="collapseOne10">
																					{strings.method1} {this.props.language === 'uz' ? '"O`QUV MOTIVATSIYASINING YO`NALGANLIGINI DIAGNOSTIKA QILISH" METODIKASI'
																					: 'Методика диагностики направленности учебной мотивации'}
																				</button>
																			</h5>
																		</div>

																		<div id="collapseOne10"
																		     className="collapse "
																		     aria-labelledby="headingOne10"
																		     data-parent="#accordion10">

																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> Muayyan fanlarni o’rganishga qaratilgan o’quv faoliyati motivatsiyasining yo’nalganligini va ta’lim oluvchi shaxs ichki motivatsiyasining darajasini o’lchash.</p>
																						<p><b className="green-title">Testlar soni:</b> 20ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 20 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Savollarga munosabatni quyidagi tartibda belgilang: «to’g’ri» - (+ +); «to’g’ri shekilli» - (+); «noto’ri shekilli» - (- ); «noto’g’ri» - (- -).</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Выявление направленности и уровня развития внутренней мотивации учебной деятельности учащихся при изучении ими конкретных предметов.</p>
																						<p><b className="green-title">Количество тестов:</b> 20 шт.</p>
																						<p><b className="green-title">Время:</b> 20 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Вам предлагается принять участие в исследовании, направленном на повышение эффективности обучения. Прочитайте каждое высказывание и выразите свое отношение к изучаемому предмету, проставив напротив номера высказывания свой ответ, используя для этого следующие обозначения: верно – (+ +), пожалуй, верно – (+), пожалуй, неверно – (–), неверно – (– –).</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 10, 36, 20, this.props.language === 'uz' ? '"O`QUV MOTIVATSIYASINING YO`NALGANLIGINI DIAGNOSTIKA QILISH" METODIKASI'
																								: 'Методика диагностики направленности учебной мотивации')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2"/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="card border-0  mb-2">
																		<div className="card-header" id="headingTwo10">
																			<h5 className="mb-0 d-flex">
																				<button className="btn btn-link btnsze"
																				        data-toggle="collapse"
																				        data-target="#collapseTwo10"
																				        aria-expanded="true"
																				        aria-controls="collapseOne10">
																					{strings.method2} {this.props.language === 'uz' ? '"O`QUVCHILARNING FANLARGA BO`LGAN QIZIQISHLARINI ANIQLASH" TESTI'
																					: 'Тест по выявлению интересов учащихся к предметам'}
																				</button>
																			</h5>
																		</div>

																		<div id="collapseTwo10" className="collapse"
																		     aria-labelledby="headingTwo10"
																		     data-parent="#accordion">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> O`quvchilarning fanlarga bo`lgan qiziqishlarini aniqlash orqali kasbga yo`naltirish.</p>
																						<p><b className="green-title">Testlar soni:</b> 90ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 90 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Quyida keltirilgan anketa savollarining barchasiga to’liq javob bering. Agar sizga o’qigan savolingiz juda yoqsa ikkita “++” belgisi, shunchaki yoqsa “+”, bilmasangiz “0”, agar yoqmasa “-” belgisini qo’yasiz. Belgilar shu savolning to’g’risidagi ustunlar bo’yicha qo’yiladi.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение интересов учащихся к предметам и выявление профессиональной направленности.</p>
																						<p><b className="green-title">Количество тестов:</b> 90 шт.</p>
																						<p><b className="green-title">Время:</b> 90 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Необходимо ответить на все вопросы прилагаемой далее анкеты, не пропуская ни одного. Если Вам очень нравится заниматься тем, о чем говорится в вопросе, то в клеточке на листе ответов, обозначенной тем же номером, что и вопрос анкеты, нужно поставить, например, два плюса; если просто нравится - один плюс; равнодушны, не знаете - нуль; если не нравится - один минус.</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 10, 37, 90, this.props.language === 'uz' ? '"O`QUVCHILARNING FANLARGA BO`LGAN QIZIQISHLARINI ANIQLASH" TESTI'
																								: 'Тест по выявлению интересов учащихся к предметам')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2 "/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="card border-0 mb-2">
																		<div className="card-header"
																		     id="headingThree10">
																			<h5 className="mb-0 d-flex">
																				<button
																					className="btn btn-link btnsze collapsed"
																					data-toggle="collapse"
																					data-target="#collapseThree10"
																					aria-expanded="false"
																					aria-controls="collapseThree10">
																					{strings.method3} {this.props.language === 'uz' ? '"KASBIY MOYILLIK" SO`ROVNOMASI'
																					: 'Опросник профессиональной склонности'}
																				</button>
																			</h5>
																		</div>
																		<div
																			id="collapseThree10"
																			className="collapse"
																			aria-labelledby="headingThree10"
																			data-parent="#accordion10">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex text-justify ">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> Kasbiy moyilliklarni aniqlash.</p>
																						<p><b className="green-title">Testlar soni:</b> 24 ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 24 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> O’zingizni qaysi kasbga moyilligingizni aniqlash uchun uch variantdan birini “a”, “b” yoki “c” ni tanlang va javob varaqasida belgilang.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Диагностика профессиональных склонностей .</p>
																						<p><b className="green-title">Количество тестов:</b> 24 шт.</p>
																						<p><b className="green-title">Время:</b> 24 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Для того, чтобы определить свои профессиональные склонности, выберите один из трех вариантов – «а», «b» или «c» – и отметьте его в бланке.</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 10, 38, 24, this.props.language === 'uz' ? '"KASBIY MOYILLIK" SO`ROVNOMASI'
																								: 'Опросник профессиональной склонности')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2"/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="card border-0 mb-2">
																		<div className="card-header" id="headingFour10">
																			<h5 className="mb-0 d-flex">
																				<button className="btn btn-link btnsze collapsed"
																					data-toggle="collapse" data-target="#collapseFour10"
																					aria-expanded="false" aria-controls="collapseFour10">
																					{strings.method4} {this.props.language === 'uz' ? '"QIZIQISHLAR XARITASI" TESTI'
																					: 'Методика карты интересов'}
																				</button>
																			</h5>
																		</div>
																		<div id="collapseFour10"
																		     className="collapse"
																		     aria-labelledby="headingFour10"
																		     data-parent="#accordion10">

																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex text-justify ">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> kasbiy qiziqishlar haqidagi ma’lumotlarga ega bo’lish.</p>
																						<p><b className="green-title">Testlar soni:</b> 50 ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 50 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Savolnomadagi ta’kid-hukmlarga munosabatingizni bildiring. Agar “ha” deb javob bermoqchi bo’lsangiz savolning to’g’risidagi “ha” ustunga “+”, “yo’q” ustuniga “-” belgisini qo’ying. Savollarga imkon qadar haqqoniy javob berishga harakat qiling.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение профессиональных интересов.</p>
																						<p><b className="green-title">Количество тестов:</b> 50 шт.</p>
																						<p><b className="green-title">Время:</b> 50 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Данные вопросы касаются вашего отношения к различным направлениям деятельности. Нравится ли вам делать то, о чем говорится в опроснике? Если нравится, то в бланке ответов в графе «да» поставьте плюс, если не нравится в графе «нет» - минус.</p>
																					</h5>
																					}

																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 10, 35, 50, this.props.language === 'uz' ? '"QIZIQISHLAR XARITASI"' : 'Методика карты интересов')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2"/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="tab-pane fade" id="v-pills-11" role="tabpanel" aria-labelledby="v-pills-11-tab">
																<div id="accordion11">
																	<div className="card border-0  mb-2">
																		<div className="card-header" id="headingTwo11">
																			<h5 className="mb-0 d-flex">
																				<button className="btn btn-link btnsze"
																				        data-toggle="collapse"
																				        data-target="#collapseTwo11"
																				        aria-expanded="true"
																				        aria-controls="collapseOne11">
																					{strings.method1} {this.props.language === 'uz' ? '"O`QUVCHILARNING FANLARGA BO`LGAN QIZIQISHLARINI ANIQLASH" TESTI'
																					: 'Тест по выявлению интересов учащихся к предметам'}
																				</button>
																			</h5>
																		</div>

																		<div id="collapseTwo11" className="collapse " aria-labelledby="headingTwo11" data-parent="#accordion">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> O`quvchilarning fanlarga bo`lgan qiziqishlarini aniqlash orqali kasbga yo`naltirish.</p>
																						<p><b className="green-title">Testlar soni:</b> 90 ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 90 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Quyida keltirilgan anketa savollarining barchasiga to’liq javob bering. Agar sizga o’qigan savolingiz juda yoqsa ikkita “++” belgisi, shunchaki yoqsa “+”, bilmasangiz “0”, agar yoqmasa “-” belgisini qo’yasiz. Belgilar shu savolning to’g’risidagi ustunlar bo’yicha qo’yiladi.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение интересов учащихся к предметам и выявление профессиональной направленности.</p>
																						<p><b className="green-title">Количество тестов:</b> 90 шт.</p>
																						<p><b className="green-title">Время:</b> 90 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Необходимо ответить на все вопросы прилагаемой далее анкеты, не пропуская ни одного. Если Вам очень нравится заниматься тем, о чем говорится в вопросе, то в клеточке на листе ответов, обозначенной тем же номером, что и вопрос анкеты, нужно поставить, например, два плюса; если просто нравится - один плюс; равнодушны, не знаете - нуль; если не нравится - один минус.</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 11, 37, 90, this.props.language === 'uz' ? '"O`QUVCHILARNING FANLARGA BO`LGAN QIZIQISHLARINI ANIQLASH" TESTI'
																								: 'Тест по выявлению интересов учащихся к предметам')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2 "/>
																							{strings.startTest}
																						</button>
																					</div>

																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="card border-0 mb-2">
																		<div className="card-header"
																		     id="headingThree11">
																			<h5 className="mb-0 d-flex">
																				<button
																					className="btn btn-link btnsze collapsed"
																					data-toggle="collapse"
																					data-target="#collapseThree11"
																					aria-expanded="false"
																					aria-controls="collapseThree11">
																					{strings.method2} {this.props.language === 'uz' ? '"KASB TIPLARINI ANIQLASH" METODIKASI' : 'Определение типа будущей профессии'}
																				</button>
																			</h5>
																		</div>
																		<div id="collapseThree11" className="collapse"
																		     aria-labelledby="headingThree11"
																		     data-parent="#accordion11">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className=" d-flex text-justify ">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> Kasb tiplariga qiziqishni aniqlash.</p>
																						<p><b className="green-title">Testlar soni:</b> 25 ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 25 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Savolnomadagi ta’kid-hukmlarga munosabatingizni bildiring. Agar “ha” deb javob bermoqchi bo‘lsangiz savolning to‘g‘risidagi “ha” ustunga “+”, “yo‘q” ustuniga “-” belgisini qo‘ying. Savollarga imkon qadar haqqoniy javob berishga harakat qiling.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение интересов к типам профессий.</p>
																						<p><b className="green-title">Количество тестов:</b> 25 шт.</p>
																						<p><b className="green-title">Время:</b> 25 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Данные вопросы касаются вашего отношения к различным направлениям деятельности. Нравится ли вам делать то, о чем говорится в опроснике? Если нравится, то в бланке ответов в графе «да» поставьте плюс, если не нравится в графе «нет» - минус.</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 11, 28, 25, this.props.language === 'uz' ? '"KASB TIPLARINI ANIQLASH" METODIKASI' : 'Определение типа будущей профессии')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2 "/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="card border-0 mb-2">
																		<div className="card-header" id="headingFour11">
																			<h5 className="mb-0 d-flex">
																				<button
																					className="btn btn-link btnsze collapsed"
																					data-toggle="collapse"
																					data-target="#collapseFour11"
																					aria-expanded="false"
																					aria-controls="collapseFour11">
																					{strings.method3} {this.props.language === 'uz' ? '"QIZIQISHLAR XARITASI" TESTI' : 'Методика карты интересов'}
																				</button>
																			</h5>
																		</div>
																		<div id="collapseFour11"
																		     className="collapse"
																		     aria-labelledby="headingThree11"
																		     data-parent="#accordion11">
																			<div className="card-body">
																				<div className="ml-edu d-flex">
																					<h4>{strings.purpose}</h4>
																				</div>
																				<div className="d-flex text-justify">
																					{this.props.language === 'uz' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Maqsad:</b> Kasbiy qiziqishlar haqidagi ma’lumotlarga ega bo’lish.</p>
																						<p><b className="green-title">Testlar soni:</b> 50 ta.</p>
																						<p><b className="green-title">Ajratilgan vaqt:</b> 50 minut.</p>
																						<p><b className="green-title">Ko‘rsatma:</b> Savolnomadagi ta’kid-hukmlarga munosabatingizni bildiring. Agar “ha” deb javob bermoqchi bo’lsangiz savolning to’g’risidagi “ha” ustunga “+”, “yo’q” ustuniga “-” belgisini qo’ying. Savollarga imkon qadar haqqoniy javob berishga harakat qiling.</p>
																					</h5>
																					}
																					{this.props.language === 'ru' &&
																					<h5 className="ml-4 text-left">
																						<i className="fa fa-list mr-2 p-2"/>
																						<p><b className="green-title">Цель:</b> Определение профессиональных интересов.</p>
																						<p><b className="green-title">Количество тестов:</b> 50 шт.</p>
																						<p><b className="green-title">Время:</b> 50 минут.</p>
																						<p><b className="green-title">Инструкция:</b> Данные вопросы касаются вашего отношения к различным направлениям деятельности. Нравится ли вам делать то, о чем говорится в опроснике? Если нравится, то в бланке ответов в графе «да» поставьте плюс, если не нравится в графе «нет» - минус.</p>
																					</h5>
																					}
																				</div>
																				<div className="ml-edu d-flex mt-2">
																					<h4>{strings.participationInTheTest}</h4>
																				</div>
																				<div className=" d-flex">
																					<div className="ml-4">
																						<button
																							onClick={this.openTestPage.bind(this, 11, 35, 50, this.props.language === 'uz' ? '"QIZIQISHLAR XARITASI" TESTI' : 'Методика карты интересов')}
																							className="btn bg-primary ">
																							<i className="fa fa-arrow-right mr-2 "/>
																							{strings.startTest}
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>

														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	}
}

export default withRouter(QuizView);