import React, {Component} from 'react';
import {apiURL} from "./Utils";
import {Link, withRouter} from "react-router-dom";
import swal from 'sweetalert';
import axios from 'axios';

class PsychHelpListView extends Component {

	constructor() {
		super();
		this.state = {
			tableRows: [],
			language: 1
		}
	}

	onChangeLanguage() {
		let language = document.getElementById('helpLanguage').value;
		this.setState({
			language: language
		});
		this.loadData(language);
	}

	componentDidMount() {
		this.loadData(this.state.language);
	}

	loadData(language) {
		axios.get(apiURL + '/profession/help/list?language=' + language)
			.then(result => {
				if (result.status === 200) {
					let options = [];
					if (result.data !== undefined && result.data !== null) {
						for (let i = 0; i < result.data.length; i++) {
							let row = result.data[i];
							options.push(<tr key={row.id}>
								<th>{row.id}</th>
								<td><Link to={'/addHelp?id=' + row.id}>{row.name}</Link></td>
								<td>{row.description.substring(0, 100)}</td>
								<td>
									<button className="btn btn-danger" onClick={this.deleteHelp.bind(this, row.id)}>O`chirish</button>
								</td>
							</tr>);
						}
					}
					this.setState({
						tableRows: options
					})
				} else {
					this.setState({
						tableRows: []
					})
				}
			})
	}

	deleteHelp(id) {
		swal({
			title: "Siz tanlangan psixologik yordamni o`chirmoqchimisiz?",
			text: "",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					axios.post(apiURL + '/profession/help/delete?id=' + id)
						.then(result => {
							if (result.data.statusCode === 200) {
								this.loadData(this.state.language);
								swal(result.data.message, '', 'success');
							} else {
								swal(result.data.message, '', 'error');
							}
						})
				}
			});
	}

	render() {
		return (
			<div className="card card-body my-3 shadow-sm">
				<div className="row">
					<div className="col-md-2">
						<select id="helpLanguage" className="form-control" onChange={this.onChangeLanguage.bind(this)}>
							<option value="1">O`zbek</option>
							<option value="2">Rus</option>
						</select>
					</div>
					<div className="col-md-8 text-md-center">
						<h3>Psixologik maslahatlar</h3>
					</div>
					<div className="col-md-2 text-md-end">
						<Link to="/addHelp" className="btn btn-info">
							<i className="fa fa-plus-square"/> Qo`shish</Link>
					</div>
				</div>
				<div className="row mt-3">
					<div className="col-md-12 order-md-1 pt-2">
						<table className="table table-striped table-bordered shadow">
							<thead>
							<tr>
								<th scope="col">№</th>
								<th scope="col">Nomi</th>
								<th scope="col">Mazmuni</th>
								<th scope="col">Amal</th>
							</tr>
							</thead>
							<tbody>
							{this.state.tableRows}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(PsychHelpListView);