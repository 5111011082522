import React, {Component} from 'react';
import {apiURL, strings} from "./Utils";
import axios from "axios";
import {withRouter} from "react-router-dom";

class PsychoogicalAdviceView extends Component {

	constructor() {
		super();
		this.state = {
			helpList: []
		}
	}

	componentDidMount() {
		let lang = this.props.language === 'ru' ? 2 : 1;
		axios.get(apiURL + '/profession/help/list?language=' + lang)
			.then(result=>{
				if (result.status === 200) {
					let helps = result.data.map(h=> {
						return <div className="col-lg-12 col-md-12 mt-2">
							<div className="full-sec-content">
								<div className="card card-profile mt--300 shadow ml-box">
									<div className="px-4">
										<div className="text-center mt-3 mb-3 pt-100">
											<h4>{h.name}</h4>
										</div>
										<div className="mt-2 py-3 border-top text-center">
											<div className="row justify-content-center" dangerouslySetInnerHTML={{ __html:h.description}}/>
										</div>
									</div>
								</div>
							</div>
						</div>;
					});
					this.setState({
						helpList: helps
					})
				}
			})
	}

	render() {
		return (
			<div>
				<section className="page-banner">
					<div className="image-layer" style={{backgroundImage: "url(images/bread-bg.jpg)"}}/>
					<div className="auto-container">
						<div className="breadcrumb-box"/>
						<h1>{strings.psychCounseling}</h1>
					</div>
				</section>

				<section className="about-area inner-content-wrapper">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 col-md-12">
								<div className="full-sec-content">
									<div className="sec-title style-two">
										<div className="ml-edu">
											<h4>{strings.psychHelp}</h4>
										</div>
										<span className="decor"><span className="inner"/></span>
									</div>
								</div>
							</div>
							{this.state.helpList}
						</div>
					</div>
				</section>
			</div>
	)
	}
}

export default withRouter(PsychoogicalAdviceView);