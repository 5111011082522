import React, {Component} from 'react';
import axios from 'axios';
import {Alert} from "reactstrap";
import Footer from "./Footer";
import {apiURL} from "./Utils";
import {Redirect} from "react-router-dom";

class LoginPage extends Component {

	constructor() {
		super();
		this.state = {
			showAlert: false,
			page: ''
		}
	}

	login() {
		let userName = document.getElementById('userName').value;
		let password = document.getElementById('password').value;
		axios.post(apiURL + '/user/login?userName=' + userName + '&password=' + password)
			.then(result => {
				if (result.data.statusCode === 200) {
                    let page = "";
                    localStorage.setItem('user', JSON.stringify(result.data.data));
                    if (result.data.data.role === 'BULIM') {
                        page = "/section";
					} else if (result.data.data.role === 'SCHOOL') {
                        page = "/school";
					} else if (result.data.data.role === 'DISTRICT') {
                        page = "/district";
					} else if (result.data.data.role === 'STATE') {
                        page = "/region";
					} else if (result.data.data.role === 'ADMIN') {
                        page = "/reports";
					} else if (result.data.data.role === 'MINISTRY') {
                        page = "/ministry";
					}
					if (!(page==='/school' || page==='/district' || page==='/region' || page==='/reports')) {
						localStorage.setItem('sf', 1);
					} else {
						localStorage.setItem('sf', 0);
					}
                    this.setState({
                        page: page,
                        openPage: true
                    })
				} else if (result.data.statusCode === 500) {
					this.setState({
						showAlert: true
					})
				}
			})
	}

	closeAlert() {
		this.setState({
			showAlert: false
		})
	}

	render() {
		if (this.state.openPage) {
			return <Redirect to={this.state.page}/>
		}
		return (
			<div>
				<div className="text-center mt-5">
					<form className="form-signin" method="post" style={{width: "400px", marginLeft: "auto", marginRight: "auto"}}>
						<h1 className="h3 mb-3 font-weight-normal">Tizimga kirish</h1>
						<div className="row">
							<div className="col-md-12 mb-1">
								<label htmlFor="userName" className="sr-only">Login</label>
								<input id="userName" name="userName" className="form-control" placeholder="Login" autoFocus=""/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 mb-1">
								<label htmlFor="password" className="sr-only">Parol</label>
								<input type="password" id="password" name="password" className="form-control" placeholder="Parol"/>
							</div>
						</div>
						<button className="btn btn-lg btn-primary btn-block" type="button"
						        onClick={this.login.bind(this)}>Kirish
						</button>
					</form>
				</div>
				<div className="container text-center" style={{width: "600px"}}>
					<Alert isOpen={this.state.showAlert} color="danger" transition={{in: true, timeout: 150}}
					       toggle={this.closeAlert.bind(this)}>
						Login yoki parol xato kiritilgan.
					</Alert>
				</div>
				<Footer/>
			</div>
		)
	}
}

export default LoginPage;