import React, {Component} from 'react';
import {strings} from "./Utils";
import {withRouter} from "react-router-dom";

class AboutProgram extends Component {

	render() {
		return(
			<section className="about-area inner-content-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 col-md-12" style={{display: "flex"}}>
							<div className="full-sec-content">
								<div className="sec-title style-two">
									<div className="ml-edu">
										<h4>{strings.aboutProgram}</h4>
									</div>
									<span className="decor"><span className="inner"/></span>
								</div>
								<div className="card card-profile mt--300 shadow ml-box">
									<div className="px-4">
										<div className="text-center mt-3 mb-3 pt-100">
											<h4>{strings.progName}</h4>
										</div>
										<div className="mt-2 py-3 border-top text-center">
											<div className="row justify-content-center">
												<div className="col-lg-11">
													<p className="text-justify redFileP" style={{fontWeight: "bold"}}>
														<p className="text-center">{strings.welcome}</p>
														<p style={{color: "blue"}}>{strings.titleHeadStr}</p>
														<br/>
														<p style={{textAlign: "justify"}}>{strings.advantage14}</p>
													</p>
													<table style={{marginLeft: "-30px"}}>
														<tr>
															<td><img src="images/1.png" alt="" className="roundedImg"/></td>
															<td><img src="images/2.jpg" alt="" className="roundedImg"/></td>
														</tr>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-5 col-md-12" style={{paddingTop: "80px"}}>
							<div className="about-img">
								<img
									src="images/infosystem.jpg"
									alt=""
									data-popupalt-original-title="null"
									title=""
								/>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-5 col-md-12 mt-5 pt-5 align-items-center">
							<div className="about-img">
								<img
									src="images/aboutInfotwo.jpg"
									alt=""
									data-popupalt-original-title="null"
									title=""
								/>
							</div>
						</div>

						<div className="col-lg-7 col-md-12 mt-5">
							<div className="full-sec-content">
								<div className="sec-title style-two">
									<span className="decor"><span className="inner"/></span>
								</div>
								<div className="card card-profile mt--300 shadow ml-box">
									<div className="px-4">
										<div className="text-center mt-3 mb-3 pt-100">
											<h4>{strings.progName}</h4>
										</div>
										<div className="mt-2 py-3 border-top text-center">
											<div className="row justify-content-center">
												<div className="col-lg-11">
													<p className="text-justify redFileP">
														{strings.aboutProgDesc}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>


					<div className="row">
						<div className="col-lg-12 col-md-12" style={{display: "flex"}}>
							<div className="full-sec-content">
								<div className="card card-profile mt--300 shadow ml-box">
									<div className="px-4">
										<div className="mt-2 py-3 border-top text-center">
											<div className="row justify-content-center">
												<div className="col-lg-11">
													<b>{strings.advantages}</b>
													<ol style={{textAlign: "justify"}}>
														<li>{strings.advantage1}</li>

														<div className="row justify-content-center">{strings.advantage3}
															<p className="text-justify redFileP" style={{fontWeight: "bold"}}>
																<img src="images/5.jpg" alt="" className="roundedImg"/>
															</p>
														</div>
														<li>{strings.advantage4}</li>
														<li>{strings.advantage5}</li>
														<li>{strings.advantage6}</li>
														<li>{strings.advantage7}</li>
														<li>{strings.advantage8}</li>
														<li>{strings.advantage9}</li>
														<p className="text-center redFileP" style={{fontWeight: "bold"}}>
															<img src="images/7.png" alt="" className="roundedImg"/>
														</p>

														<li>{strings.advantage10}</li>
														<li>{strings.advantage11}</li>
														<li>{strings.advantage12}</li>
														<p className="text-center redFileP" style={{fontWeight: "bold"}}>
															<img src="images/8.png" alt="" className="roundedImg"/>
														</p>
														<br/>
														<br/>
														<br/>
														<p className="text-center redFileP" style={{fontWeight: "bold", fontSize: "30px"}}>
															{strings.advantage13}
														</p>
													</ol>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

export default withRouter(AboutProgram);