import React from 'react';
import ReactDOM from 'react-dom';
import './css/animate.min.css';
import './css/bootstrap.min.css';
import './css/bootstrap-reboot.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<App/>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

reportWebVitals();
