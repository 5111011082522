import React, {Component} from 'react';
import {Link} from "react-router-dom";
import logoHeader from './images/logo.png';
import {strings} from "./Utils";

export default class Header extends Component {

	setLanguage(lang) {
		this.props.changeLanguage(lang);
	}

	mobileAndTabletCheck() {
		const toMatch = [
			/Android/i,
			/webOS/i,
			/iPhone/i,
			/iPad/i,
			/iPod/i,
			/BlackBerry/i,
			/Windows Phone/i
		];

		return  toMatch.some((toMatchItem) => {
			return navigator.userAgent.match(toMatchItem);
		});
	};

	render() {
		let isMobileBrowser = this.mobileAndTabletCheck();
		return(
			<div>
			<header className="top-header">
				<div className="row align-items-center mr-2 ml-2">
					<div className="col-lg-2 col-md-2">
						<ul className="top-header-left">
							<li>
								<a href="https://facebook.com/tashxismarkazi/" target="_blank" rel="noreferrer">
									<i className="fab fa-facebook-f"/>
								</a>
							</li>
							<li>
								<a href="https://t.me/tashxismarkazi" target="_blank" rel="noreferrer">
									<i className="fab fa-telegram"/>
								</a>
							</li>
						</ul>
					</div>
					<div className="col-lg-10 col-md-10">
						<ul className="top-header-right">
							<li>
								<i className="fa fa-map"/>
								{strings.ourAddress}
							</li>
							<li>
								<i className="fa fa-envelope"/>
								<a href="mailto:tashxis_markazi@xtv.uz">tashxis_markazi@xtv.uz</a>
							</li>
							<li>
								<i className="fa fa-phone"/>
								<a href="tel:+998712469804">+998 (71) 246-98-04</a>
							</li>
							<li>
								<i className="fa fa-user"/>
								<Link to="/login">{strings.login}</Link>
							</li>
							<li className="cursor-pointer" onClick={this.setLanguage.bind(this, 'uz')}>
								<span>
									<img className="lang-flag" src="/images/uz.jpg" alt=""/>
									O`zbekcha
								</span>
							</li>
							<li className="cursor-pointer" onClick={this.setLanguage.bind(this, 'ru')}>
								<span>
									<img className="lang-flag" src="/images/ru.jpg" alt=""/>
									Русский
								</span>
							</li>
						</ul>
					</div>
				</div>
			</header>
				<div className="navbar-area">
					{/*<div className="mobile-nav">
						<Link to="/" className="logo">
							<img src={logoHeader} alt="Logo"/>
						</Link>
					</div>*/}
					<div className="top-nav main-nav">
						<nav className="navbar navbar-expand-md navbar-light">
							<Link className="navbar-brand" to="/">
								<img src={logoHeader} alt="Logo"/>
							</Link>
							<div style={{width: "225px", textAlign: "center"}}>
								<h5 style={{fontWeight: "bold"}}>{strings.kasbim}</h5>
								<h5 style={{fontWeight: "bold", marginBottom: "0", color: "#4eae4a"}}>{strings.kelajagim}</h5>
							</div>
							<div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
								<ul className="navbar-nav m-auto">
									<li className="nav-item">
										<Link to="/">{strings.homePage}</Link>
									</li>
									<li className="nav-item">
										<Link to="/about">{strings.aboutProgram}</Link>
									</li>
									<li className="nav-item">
										{/*<Link to="/quiz">{strings.tests}</Link>*/}
										<span>
                                            {strings.tests}
											<i className="bx bx-chevron-down"/>
										</span>

										<ul className="dropdown-menu">
											<li>
												<Link to="/quiz" className="nav-link">
                                                    {strings.tests}
												</Link>
											</li>
											{/*<li>
												<Link to="/iqTest" className="nav-link">
                                                    {strings.iqTest}
												</Link>
											</li>*/}
										</ul>
									</li>

									<li className="nav-item">
										<span>
											{strings.professionWorld}
											<i className="bx bx-chevron-down"/>
										</span>

										<ul className="dropdown-menu">
											<li>
												<Link to="/professions" className="nav-link">
													{strings.professionWorld}
												</Link>
											</li>
											<li>
												<Link to="/occupation" className="nav-link">
													{strings.professionWorld2}
												</Link>
											</li>
										</ul>
									</li>
									<li className="nav-item">
										{/*<Link to="/oсcMonth">{strings.occupationMonth}</Link>*/}
										<Link to="/ent" className="nav-link">{strings.entrepreneurship}</Link>
									</li>
									<li className="nav-item">
										<Link to="/psychologicalAdvice">{strings.psychCounseling}</Link>
									</li>
									<li className="nav-item">
										<span>
											{strings.effective}
											<i className="bx bx-chevron-down"/>
										</span>

										<ul className="dropdown-menu">
											<li>
												<Link to="/effective" className="nav-link">
													{strings.effective2}
												</Link>
											</li>
											<li>
												<Link to="/effective2" className="nav-link">
													{strings.effective3}
												</Link>
											</li>
										</ul>
									</li>
									<li className="nav-item">
										<span>
											{strings.documents}
											<i className="bx bx-chevron-down"/>
										</span>

										<ul className="dropdown-menu">
											<li>
												<Link to={"/documentsView?type=1&lang=" + this.props.language} className="nav-link">{strings.documentsType1}</Link>
											</li>
											<li>
												<Link to={"/documentsView?type=2&lang=" + this.props.language} className="nav-link">{strings.documentsType2}</Link>
											</li>
											<li>
												<Link to={"/documentsView?type=3&lang=" + this.props.language} className="nav-link">{strings.documentsType3}</Link>
											</li>
											<li>
												<Link to={"/documentsView?type=4&lang=" + this.props.language} className="nav-link">{strings.documentsType4}</Link>
											</li>
											<li>
												<Link to={"/documentsView?type=5&lang=" + this.props.language} className="nav-link">{strings.documentsType5}</Link>
											</li>
										</ul>
									</li>

									<li className="nav-item">
										<Link to="/contact">{strings.contact}</Link>
									</li>

									{isMobileBrowser &&
									<li className="cursor-pointer nav-item" onClick={this.setLanguage.bind(this, 'uz')}>
										<span onClick={this.setLanguage.bind(this, 'uz')}>
											<img className="lang-flag" src="/images/uz.jpg" alt=""/>
											O`zbekcha
										</span>
									</li>
									}
									{isMobileBrowser &&
									<li className="cursor-pointer nav-item" onClick={this.setLanguage.bind(this, 'ru')}>
										<span onClick={this.setLanguage.bind(this, 'ru')}>
											<img className="lang-flag" src="/images/ru.jpg" alt=""/>
											Русский
										</span>
									</li>
									}
								</ul>
							</div>
						</nav>
					</div>
				</div>
			</div>
		)
	}
}